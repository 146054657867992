import { Formik, Form } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import { AppActionContext, AppStateContext } from '../app-store-provider'
import { PotentialRecipient } from '../models/potential-recipient'
import { IMailBlastRecipientDocument, IPotentialRecipientDocument } from '../open-api'
import { sortListBySorts, filterGridListItems } from '../services/helpers'
import { useHTTPRequestUiWrapper, usePotentialRecipientsDefaultColumns } from '../services/hooks'
import { defaultGridState, useGrid } from '../stores/grid-actions'
import { GridDataFetch, IGridState } from '../stores/grid-definitions'
import { gridReducer } from '../stores/grid-reducer'
import { getContactTitleOptions } from './contact-form'
import { MinistryContactType } from '../constants'
import { CheckboxField, CommunitySelectField, SelectField } from './forms'
import { Grid } from './grid'
import { ExpandableSection } from './expandable-section'


interface ISelectRecipientsByChapters {
	recipientsSelected: (recipients: IMailBlastRecipientDocument[]) => void
	mailTypeIds?: number[]
}

export const SelectRecipientsByChapters = (props: ISelectRecipientsByChapters) => {
	const { recipientsSelected, mailTypeIds } = props

	const appActions = useContext(AppActionContext)!
	const appState = useContext(AppStateContext)!
	const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

	const [potentialRecipients, setPotentialRecipients] = useState<IPotentialRecipientDocument[]>()

	const dataSource: GridDataFetch<IPotentialRecipientDocument[]> = async (queryState, _potentialRecipients) => {
		if (!_potentialRecipients) return { rows: [], count: 0 }

		if (queryState.sorts) sortListBySorts(_potentialRecipients, queryState.sorts)

		let rows = _potentialRecipients.map(PotentialRecipient.toGridListItem)

		if (queryState.filters) rows = filterGridListItems(rows, queryState.filters)

		return { rows, count: rows.length, }
	}

	const initialGridState: IGridState = {
		...defaultGridState,
		usingLocalData: true,
		rowSelectEnabled: true,
		columns: usePotentialRecipientsDefaultColumns(),
		disabledPagination: true,
		hideGridHeader: true,
		dataSource,
	}

	const [gridState, gridActions] = useGrid(gridReducer, initialGridState, potentialRecipients)

	useEffect(() => {
		recipientsSelected(gridState.rows.filter(r => r.selected).map(r => ({ name: `${r.values.fullName}`, email: `${r.values.email}`, ministryContactId: r.values.ministryContactID, prospectId: r.values.prospectId })) as IMailBlastRecipientDocument[])
		//eslint-disable-next-line
	}, [gridState.rows])

	useEffect(() => {
		gridActions.doFetch()
		//eslint-disable-next-line
	}, [potentialRecipients])

	useEffect(() => {
		if (!appState.activeTrainingAreas) appActions.fetchTrainingAreas()
	}, [])

	const initialValues: {
		branchIds: number[],
		trainingAreaIds: number[],
		contactTypeIds: number[],
		contactSubTypeIds: number[],
		archiveStatus: number,
		includeSecondaryChapterContacts: boolean,
		withoutTypeOrTitle: boolean,
		onlyPrimary: boolean,
	} = {
		branchIds: [],
		trainingAreaIds: [],
		contactTypeIds: [],
		contactSubTypeIds: [],
		archiveStatus: 1,
		includeSecondaryChapterContacts: false,
		withoutTypeOrTitle: false,
		onlyPrimary: false,
	}

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={async (values) => {
				const contactQuery = await makeHttpRequestWithUi({
					request: appActions.MailBlastApi.apiMailBlastPotentialRecipientsGetByCommunityGet(values.branchIds, values.trainingAreaIds, values.contactTypeIds, values.contactSubTypeIds, values.archiveStatus, values.includeSecondaryChapterContacts, values.withoutTypeOrTitle, values.onlyPrimary, mailTypeIds),
					toastSuccessMessage: 'Successfully retrieved contacts.',
					toastErrorMessage: 'Encountered an error retrieving contacts.'
				})

				setPotentialRecipients(contactQuery.data.filter(o => !!o.email))
			}}
		>

			{formikProps => {
				return (
					<Form style={{ height: '100%' }}>

						<ExpandableSection>
							<div>
								<div className='mb-2'><b>Unsubscribed</b> emails are excluded from the results.</div>
								<div className='d-flex'>
									<div style={{ width: '33%' }} className='mr-2'>
										<CommunitySelectField multiple={true} fieldProps={{ name: 'branchIds', label: 'Communit(y/ies)' }} />
										<SelectField
											multiple={true}
											fieldProps={{ name: 'contactTypeIds', label: 'Contact Type(s)' }}
											// @ts-ignore
											options={Object.keys(MinistryContactType).filter(key => isNaN(key)).map(key => ({ value: MinistryContactType[key], label: key }))}
										/>
										<SelectField
											fieldProps={{ name: 'archiveStatus', label: 'Archive Status' }}
											options={[
												{label: 'Both active and archived', value: '1' }, 
												{label: 'Active only', value: '2' },
												{label: 'Archived only', value: '3' }
											]}
										/>
									</div>
									<div style={{ width: '33%' }} className='mr-2'>
										<SelectField multiple={true} disabled={!formikProps.values.branchIds?.length} fieldProps={{ name: 'trainingAreaIds', label: 'Training Area(s)' }} options={appState.activeTrainingAreas?.filter(o => formikProps.values.branchIds?.map(o => o.toString()).includes(o.branchId.toString())).map(o => ({ value: `${o.trainingAreaId}`, label: `${o.trainingAreaAbbr}` }))} />
										<SelectField
											multiple={true}
											disabled={formikProps.values.contactTypeIds?.length === 0}
											fieldProps={{ name: 'contactSubTypeIds', label: 'Title(s)' }}
											options={getContactTitleOptions(formikProps.values.contactTypeIds)}
										/>
									</div>
									<div style={{ width: '33%' }} className='mt-4 ml-2'>
										{/* <CheckboxField fieldProps={{ name: 'onlyIncludeArchived', label: 'Only include Archived Contacts' }} /> */}
										<CheckboxField fieldProps={{ name: 'includeSecondaryChapterContacts', label: 'Include contacts where this Community is a Secondary Community' }} />
										<CheckboxField fieldProps={{ name: 'withoutTypeOrTitle', label: 'Include Contacts without Type/Title' }} />
										<CheckboxField fieldProps={{ name: 'onlyPrimary', label: 'Only Include Primary Contact' }} />
									</div>
								</div>
							</div>

							<button type='button' className='btn btn-primary mb-4' onClick={formikProps.submitForm}>Load Contacts</button>
						</ExpandableSection>

						{potentialRecipients ?
							<React.Fragment>
								<b>{potentialRecipients.length} contact{potentialRecipients.length === 1 ? '' : 's'}</b>
								<Grid state={gridState} actions={gridActions} style={{ height: 'auto', flex: '1' }} />
							</React.Fragment>
							:
							null
						}
					</Form>
				)
			}}
		</Formik>
	)
}