import React, { useContext } from "react"
import { AppStateContext } from "../../app-store-provider"
import { FormikSelectField, IFormikSelectFieldProps } from './formik-select-field'
import { BranchType } from "../../constants"

interface IFormikCommunitySelectFieldProps extends IFormikSelectFieldProps 
{ 
    branchTypes?:BranchType[] // Allows us to filter the branches by the branchTypeId
}
export const FormikCommunitySelectField = (props: IFormikCommunitySelectFieldProps) => {
    const appState = useContext(AppStateContext)!
    const { branchTypes } = props
    const branches = branchTypes ? appState.activeBranches.filter(x => branchTypes.includes(x.branchTypeId || 0)) : appState.activeBranches
    return <FormikSelectField
        {...props}
        options={branches.sort((a, b) => {
            if (a.branchName === null) return -1
            if (b.branchName === null) return 1
            if (a.branchName < b.branchName) return -1
            if (a.branchName > b.branchName) return 1
            return 0
        }).map(b => ({ value: b.branchId.toString(), label: `${b.branchAbbr?.trim() || ''} (${b.branchName})` }))}
    />
}