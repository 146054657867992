import { useState } from "react"
import { Popup } from "./popup"
import clsx from 'clsx'
import { ChevronDownIcon } from "../assets"
import { FormikProps } from "formik"
import { FormikTextField } from "./forms/formik-text-field"
import { formatCurrencyV2 } from "../services/helpers"

interface IMapIncomePopperProps {
    formikProps: FormikProps<any>
}

export const MapIncomePopper = (props: IMapIncomePopperProps) => {
    const { formikProps } = props
    const [open, setIsOpen] = useState(false)

    return (
      <Popup
        trigger={
          <button
            className={
              'btn btn-outline-secondary' +
              clsx(formikProps.values.incomeMin > 0 || formikProps.values.incomeMax > 0 ? ' active' : ' ')
            }
            type='button'
          >
            {(formikProps.values.incomeMin > 0 || formikProps.values.incomeMax > 0) ? `${formatCurrencyV2(formikProps.values.incomeMin)} - ${formatCurrencyV2(formikProps.values.incomeMax)}` : 'Income' } <ChevronDownIcon />
          </button>
        }
        onToggle={setIsOpen}
        popupOptions={{
          placement: 'bottom-start',
          modifiers: [
              {
                  name: 'offset',
                  options: {
                      offset: [0, 5]
                  }
              }
          ]
        }}
      >
        <div
            className="p-3"
          style={{
            backgroundColor: 'white',
            border: '1px solid gray',
            borderRadius: '.25rem',
            width: '400px',
            overflow: 'auto',
            //maxHeight: '300px',
            overflowX: 'hidden'
          }}
        >
          <div className='d-flex flex-row'>
            <div style={{ flex: 1 }}>
              <FormikTextField
                field={{
                  name: 'incomeMin',
                  label: 'Income',
                  placeholder: '$ No min',
                }}
              />
            </div>
            <div className="pl-2 pr-2"> <span style={{position: 'relative', top: '25px'}}>-</span> </div>
            <div style={{ flex: 1 }}>
            <FormikTextField
                field={{
                  name: 'incomeMax',
                  label: '',
                  placeholder: '$ No max',
                }}
              />
            </div>
          </div>
        </div>
      </Popup>
    )
}