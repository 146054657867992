import { Form, Formik } from 'formik'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import * as Yup from 'yup'
import { AppActionContext, AppStateContext } from '../app-store-provider'
import { IEventContentListItemDocument, IMailBlastPostRequestDocument, IMailBlastRecipientDocument, IMailBlastSummaryDocument, ISearchRequestFilterDocument } from '../open-api'
import { useHTTPRequestUiWrapper, useModal } from '../services/hooks'
import { DatePickerField, EmailField, SelectField, TextField, TinyMceField } from './forms'
import { Modal } from './modal'
import { CustomEmailBlastFormAddRecipients } from './custom-email-blast-form-add-recipients'
import { filter, uniqBy } from 'lodash'
import { CustomEmailBlastFormRecipients } from './custom-email-blast-form-recipients'
import { convertTimezone, sortListByProperty, TimeZone, uuidv4 } from '../services/helpers'
import { CustomEmailBlastFormSelectLayout } from './custom-email-blast-form-select-layout'
import moment from 'moment'
import { Loading } from './loading'
import dayjs from 'dayjs'
import { FormikEffect } from './formik-effect'
import { MAIL_TYPE, MINISTRY_PORTAL_ROOT_URL } from '../constants'
import { TooltipInfo } from './partials'
import { CopyToClipboardIcon } from './copy-to-clipboard-icon'
import { IFilter } from '../stores/api-actions'

export interface ICustomEmailBlastInitialValues {
	recipients: IMailBlastRecipientDocument[]
	eventContentId: string
	subject: string
}

interface ICustomEmailBlastForm {
	initialValues?: ICustomEmailBlastInitialValues
	mailBlast?: IMailBlastSummaryDocument
	afterSave?: (keepModalOpen?: boolean) => Promise<void>
}

export const CustomEmailBlastForm = (props: ICustomEmailBlastForm) => {
	const { mailBlast, afterSave, initialValues } = props

	const appAction = useContext(AppActionContext)!
	const appState = useContext(AppStateContext)!
	const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

	const LAYOUT_EMPTY = '11'
	const LAYOUT_GENERIC = '1'

	const layoutId = mailBlast?.layoutId?.toString() || LAYOUT_GENERIC

	const validationSchema = Yup.object({
		body: Yup.string().required('Required'),
		subject: Yup.string().required('Required'),
		fromUserId: Yup.string().required('Required'),
		fromName: Yup.string().max(50, 'Cannot be more than 50 characters.'),
		fromEmail: Yup.string().max(50, 'Cannot be more than 50 characters.'),
		previewRecipientEmail: Yup.string().test(
			'previewRecipientEmail',
			'Required',
			function (value) {
				if (this.parent.sendPreview) return !!value
				return true
			}
		),
		sendAt: Yup.string()
			.test(
				'sendAt',
				'Required',
				function (value) {
					if (this.parent.sendLater) {
						return !!value
					}
					return true
				}
			)
			.test(
				'sendAtTime',
				'Cannot schedule an email before the current time.',
				function (value) {
					if (this.parent.sendLater) {
						return dayjs(value).isSameOrAfter(dayjs())
					}
					return true
				}
			)
			.test(
				'tooFarAhead',
				'Cannot schedule an email for more than 72 hours from now.',
				function (value) {
					if (this.parent.sendLater) {
						return dayjs(value).isSameOrBefore(dayjs().startOf('hour').add(72, 'hours'))
					}
					return true
				}
			)
	})

	const [eventContentList, setEventContentList] = useState<IEventContentListItemDocument[]>()
	const eventContentOptions = useMemo(() => {
		return [{ label: 'None', value: '' }, ...eventContentList?.map(o => ({ label: `${o.title}`, value: `${o.eventContentId}` })) || []]
	}, [eventContentList])

	const [addRecipientsModal, showHideAddRecipientsModal] = useModal()
	const [recipients, setRecipients] = useState<IMailBlastRecipientDocument[]>(initialValues?.recipients || [])
	const addRecipients = (rec: IMailBlastRecipientDocument[]) => {
		const tempList = [...recipients, ...rec]
		setRecipients(uniqBy(tempList, 'email'))
	}
	const [addedRecipientsModal, showHideAddedRecipientsModal] = useModal()

	const [confirmSendImmediatelyModal, showHideConfirmSendImmediatelyModal] = useModal()

	const [previewModal, showHidePreviewModal] = useModal()
	const [preview, setPreview] = useState<string>()

	const [sendPreviewModal, showHideSendPreviewModal] = useModal()

	const [sendLaterModal, showHideSendLaterModal] = useModal()
	const [sendLaterConfirm, setSendLaterConfirm] = useState(false)

	const [mailBlastId, setMailBlastId] = useState<number>()

	const userOptions = useMemo(() => {
		if (appState.users) {
			return [{ value: 'custom', label: 'Custom' }, ...sortListByProperty(appState.users, 'firstName').map(user => ({ value: `${user.userId}`, label: `${user.fullName} <${user.email}>` }))]
		} else {
			return []
		}
	}, [appState.users])

	useEffect(() => {
		setMailBlastId(mailBlast?.mailBlastId)
		if (mailBlast) {
			const mailBlastIdFilter: IFilter = {
				id: 'mailBlastId',
				value: mailBlast?.mailBlastId,
				operator: 'eq',
				property: 'mailBlastId',
				enabled: true,
				hidden: true
			}
			const filters = JSON.stringify([mailBlastIdFilter])  as unknown as ISearchRequestFilterDocument[]
			const sorts = undefined
			const skip = 0
			const take = 999999
			makeHttpRequestWithUi({
				disableSuccessToast: true,
				toastErrorMessage: 'There was a problem retrieving the recipients for this custom blast.',
				request: appAction.MailBlastApi.apiMailBlastRecipientsGet(skip, take, sorts, filters)
			}).then(results => setRecipients((results.data.data || []).filter(o => !!o.email).map(o => ({ email: o.email || '', prospectId: o.prospectId, ministryContactId: o.ministryContactId, name: o.lastName || o.firstName ? `${o.firstName ? `${o.firstName} ` : ''}${o.lastName ? o.lastName : ''}` : null }))))
		}
	}, [mailBlast?.mailBlastId])

	useEffect(() => {
		makeHttpRequestWithUi({
			request: appAction.MailBlastApi.apiMailBlastGetEventContentListGet(),
			disableSuccessToast: true,
			toastErrorMessage: 'Encountered an error retrieving event content list.',
		}).then(results => setEventContentList(results.data))

		if (!appState.users) appAction.fetchMiUsers(true)
	}, [])

	if (!appState.users) return <Loading />

	const formInitialValues = {
		eventContentId: mailBlast?.eventContentId?.toString() || initialValues?.eventContentId || '',
		fromUserId: (mailBlast && appState.users?.find(o => o.email === mailBlast?.fromEmail)?.userId) || (mailBlast && 'custom') || appState.currentUser?.userId || '',
		fromName: mailBlast?.fromName || appState.currentUser?.fullName || '',
		fromEmail: mailBlast?.fromEmail || appState.currentUser?.email || '',
		body: mailBlast?.body || '',
		subject: mailBlast?.subject || initialValues?.subject || '',
		sendAt: '',
		sendLater: false,
		mailTypeId: mailBlast?.mailTypeId || -1, // Default to when creating a new record. We'll look for that later.
		layoutId,
		send: false,
		preview: false,
		sendPreview: false,
		previewRecipientEmail: '',
	}

	return (
		<Formik
			initialValues={formInitialValues}
			validationSchema={validationSchema}
			onSubmit={async (values, actions) => {
				let keepModalOpen = false

				console.log('values.sendAt', values.sendAt)

				const mailBlastDocument: IMailBlastPostRequestDocument = {
					...values,
					recipients,
					eventContentId: values.eventContentId ? parseInt(values.eventContentId) : null,
					layoutId: parseInt(values.layoutId),
					sendAt: values.sendAt && values.sendAt.length ? convertTimezone(values.sendAt, TimeZone["America/Los_Angeles"]).toLocaleString('en-US') : null,
				}

				if (values.fromUserId !== 'custom') {
					const selectedFromUser = appState.users?.find(o => o.userId == values.fromUserId)
					mailBlastDocument.fromEmail = selectedFromUser?.email || ''
					mailBlastDocument.fromName = selectedFromUser?.fullName || ''
				}

				let _mailBlastId: number | undefined = mailBlastId

				if (!!mailBlastId) {
					await makeHttpRequestWithUi({
						request: appAction.MailBlastApi.apiMailBlastIdPut(mailBlastId, { ...mailBlast || {}, mailBlastId, ...mailBlastDocument }),
						toastSuccessMessage: 'Successfully updated mail blast.',
						toastErrorMessage: 'Encountered an error while updating mail blast.',
					})
				} else {
					_mailBlastId = (await makeHttpRequestWithUi({
						request: appAction.MailBlastApi.apiMailBlastPost(mailBlastDocument),
						toastSuccessMessage: 'Successfully created mail blast.',
						toastErrorMessage: 'Encountered an error while creating mail blast.',
					})).data
				}

				if (values.send && _mailBlastId) {
					await makeHttpRequestWithUi({
						request: appAction.MailBlastApi.apiMailBlastIdSendPost(_mailBlastId),
						toastSuccessMessage: `Successfully ${values.sendLater ? 'scheduled' : 'sent'} mail blast.`,
						toastErrorMessage: `Encountered an error while ${values.sendLater ? 'scheduling' : 'sending'} mail blast.`,
					})
				}

				if (values.preview && _mailBlastId) {
					const previewQuery = await makeHttpRequestWithUi({
						request: appAction.MailBlastApi.apiMailBlastIdPreviewGet(_mailBlastId),
						toastErrorMessage: 'Encountered an error retrieving the custom blast preview.',
						disableSuccessToast: true,
					})

					setPreview(previewQuery.data)
					actions.setFieldValue('preview', false)
					showHidePreviewModal(true)

					keepModalOpen = true
				}

				if (values.sendPreview && _mailBlastId && values.previewRecipientEmail) {
					await makeHttpRequestWithUi({
						request: appAction.MailBlastApi.apiMailBlastIdSendPreviewPost(_mailBlastId, { toEmail: values.previewRecipientEmail }),
						toastSuccessMessage: `Successfully sent blast preview to ${values.previewRecipientEmail}.`,
						toastErrorMessage: `There was an error sending blast preview to ${values.previewRecipientEmail}.`
					})
					actions.setFieldValue('sendPreview', false)
					showHideSendPreviewModal(false)

					keepModalOpen = true
				}

				if (values.layoutId == LAYOUT_EMPTY && values.body.indexOf('unsubscribe')  === -1) {
					appAction.addAlert({
						id: uuidv4(),
						title: 'Missing Unsubscribe Link',
						body: 'You must include an unsubscribe link in the body of your email blast before it can be sent.',
					})
				}

				if (afterSave) afterSave(keepModalOpen)

				setMailBlastId(_mailBlastId)
			}}
		>

			{formikProps => {

				function checkForUnsubscribe() {
					const hasUnsubscribe = formikProps.values.body.includes('unsubscribe')

					if (!hasUnsubscribe && formikProps.values.layoutId == LAYOUT_EMPTY) {
						appAction.addAlert({
							id: uuidv4(),
							title: 'Missing Unsubscribe Link',
							body: 'You must include an unsubscribe link in the body of your email blast before it can be sent.',
						})

						return false
					} else {
						return true
					}
				}

				return (
					<React.Fragment>
						<FormikEffect
                            formikProps={formikProps}
                            onChange={async (prevValues, nextValues) => {
								if (prevValues.mailTypeId !== nextValues.mailTypeId) {
									if (nextValues.mailTypeId == MAIL_TYPE['All MI Email'] || nextValues.mailTypeId == MAIL_TYPE.General) {
										appAction.addAlert({
											id: uuidv4(),
											title: "MI Communication Channel Alert",
											body: "Email addresses that have chosen to unsubscribe from 'All MI Emails', will be excluded from this email blast at the time the blast is sent out.",
										})
									} else if (nextValues.mailTypeId == MAIL_TYPE['All MA Email']) {
										formikProps.values.layoutId = LAYOUT_EMPTY

										appAction.addAlert({
											id: uuidv4(),
											title: "MA Communication Channel Alert",
											body: "Email addresses that have chosen to unsubscribe from 'All MI Emails' or 'All MA Emails', will be excluded from this email blast at the time the blast is sent out. Also, the Unsubscribe Url is specific to 'Mission Accelerate'. It won't unsubscribe users from MI emails.",
										})
									} else if (nextValues.mailTypeId == MAIL_TYPE['All CE Email']) {
										formikProps.values.layoutId = LAYOUT_EMPTY

										appAction.addAlert({
											id: uuidv4(),
											title: "CE Communication Channel Alert",
											body: "Email addresses that have chosen to unsubscribe from 'All MI Emails' or 'All CE Emails', will be excluded from this email blast at the time the blast is sent out. Also, the Unsubscribe Url is specific to 'Church Engagement'. It won't unsubscribe users from MI emails.",
										})
									}
								}
                            }}
                        />
						<Form>
							<div className='mb-2'>
								<SelectField
									fieldProps={{
									name: 'mailTypeId',
									label: 'Communication Channel',
									labelTooltip:
										'This determines what unsubscribe filter will be used. In most cases Mission Increase should be selected.',
									}}
									options={[
									{ label: 'Mission Increase', value: MAIL_TYPE.General.toString() },
									{ label: 'Mission Accelerate', value: MAIL_TYPE['All MA Email'].toString() },
									{ label: 'Church Engagement', value: MAIL_TYPE['All CE Email'].toString() },
									]}
									disabled={formikProps.values.mailTypeId > -1}
								/>
							</div>
							{formikProps.values.mailTypeId < 0 ?
							null 
							:
							<>
								<div className='mb-2'>
									<CustomEmailBlastFormSelectLayout mailTypeId={formikProps.values.mailTypeId} />
								</div>
								<div className='mb-2'>
									<label>Recipients</label>
									<div className='d-flex'>
										<div className='mr-2'>
										{recipients.length} Recipient
										{recipients.length === 1 ? '' : 's'}
										</div>
										<button
										type='button'
										onClick={() => showHideAddRecipientsModal(true)}
										className='btn btn-sm btn-primary mr-2'
										disabled={!formikProps.values.mailTypeId}
										>
										Add Recipients
										</button>
										<button
										type='button'
										onClick={() => showHideAddedRecipientsModal(true)}
										className='btn btn-sm btn-secondary'
										>
										View Recipients
										</button>
									</div>
								</div>
								<div className='d-flex'>
									<div style={{ flex: 1 }} className='mr-3'>
									<SelectField fieldProps={{ name: 'eventContentId', label: 'Related Event', labelTooltip: 'Associates email with an event. Nothing more. Does NOT filter out recipients who are registered for this event.' }} options={eventContentOptions} />
									</div>
									{formikProps.values.layoutId == LAYOUT_EMPTY && <div style={{ flex: 1 }}>
										<label>Unsubscribe URL <TooltipInfo tooltipText='Add a reference to this unsubscribe link somewhere towards the bottom of your email to allow the recipient to unsubscribe from future emails. This is required for all mail blasts! The Unsubscribe URL is specific to the communication channel you choose.' /></label>
										<div className='mr-2'>
											{formikProps.values.mailTypeId == MAIL_TYPE['All MA Email'] || formikProps.values.mailTypeId == MAIL_TYPE['All CE Email'] ? 
											<><a href={`${MINISTRY_PORTAL_ROOT_URL}/unsubscribe?mtid=${formikProps.values.mailTypeId}`} className='mr-2' target='_blank'>{`${MINISTRY_PORTAL_ROOT_URL}/unsubscribe?mtid=${formikProps.values.mailTypeId}`}</a>
											<CopyToClipboardIcon value={`${MINISTRY_PORTAL_ROOT_URL}/unsubscribe?mtid=${formikProps.values.mailTypeId}`} /></>
											:
											<><a href={`${MINISTRY_PORTAL_ROOT_URL}/unsubscribe`} className='mr-2' target='_blank'>{`${MINISTRY_PORTAL_ROOT_URL}/unsubscribe`}</a>
											<CopyToClipboardIcon value={`${MINISTRY_PORTAL_ROOT_URL}/unsubscribe`} /></>
											}
										</div>
									</div>}
								</div>

								<div className='d-flex'>
									<div style={{ flex: 1 }} className='mr-3'>
										<SelectField fieldProps={{ name: 'fromUserId', label: 'From' }} options={userOptions} />
										{formikProps.values.fromUserId === 'custom' &&
											<div style={{ display: 'flex' }}>
												<div style={{ flex: 1 }} className='mr-2'>
													<TextField fieldProps={{ name: 'fromName', label: 'From Name' }} />
												</div>
												<div style={{ flex: 1 }}>
													<TextField fieldProps={{ name: 'fromEmail', label: 'From Address' }} />
												</div>
											</div>
										}
									</div>
									<div style={{ flex: 1 }}>
										<TextField fieldProps={{ name: 'subject', label: 'Subject' }} />
									</div>
								</div>

								{formikProps.values.layoutId.toString() == LAYOUT_EMPTY &&
									// Use fullPage with Empty Template (ID = 11)
									<TinyMceField fieldProps={{ name: 'body', label: 'Body' }} fullPage={true} />}
								{formikProps.values.layoutId.toString() != LAYOUT_EMPTY &&
									<TinyMceField fieldProps={{ name: 'body', label: 'Body' }} />}

								<div className='d-flex mb-2'>
									<button
										type='button'
										className='btn btn-secondary mr-2'
										onClick={async () => {
											formikProps.setFieldValue('preview', true)
											formikProps.submitForm()
										}}
									>
										Preview
									</button>
									<button
										type='button'
										className='btn btn-secondary'
										onClick={() => {
											formikProps.setFieldValue('sendPreview', true)
											showHideSendPreviewModal(true)
										}}
									>
										Send Preview
									</button>
								</div>

								<div className='d-flex'>
									<button type='button' onClick={formikProps.submitForm} className='btn btn-primary mr-2'>Save Draft</button>
									<button type='button' onClick={() => checkForUnsubscribe() && showHideConfirmSendImmediatelyModal(true)} className='btn btn-primary mr-2' disabled={!formikProps.isValid || recipients.length === 0}>Send Immediately</button>
									<button
										type='button'
										onClick={() => {
											if (checkForUnsubscribe())
											{
											formikProps.setFieldValue('sendLater', true)
											showHideSendLaterModal(true)
											}
										}}
										className='btn btn-primary mr-2'
										disabled={!formikProps.isValid || recipients.length === 0}
									>
										Send Later
									</button>
								</div>
							</>
							}

							<Modal
								{...sendPreviewModal}
								modalTitle='Send Preview'
								_onModalHidden={() => {
									formikProps.setFieldValue('sendPreview', false)
								}}
							>
								{sendPreviewModal.show ?
									<div>
										<EmailField fieldProps={{ name: 'previewRecipientEmail', label: 'Email' }} />

										<div>
											<button style={{ minWidth: 150 }} type='button' onClick={() => showHideSendPreviewModal(false)} className='btn btn-secondary mr-2'>Cancel</button>
											<button
												style={{ minWidth: 150 }}
												disabled={!formikProps.isValid}
												onClick={formikProps.submitForm}
												className='btn btn-secondary'
											>
												Send
											</button>
										</div>
									</div>
									:
									null
								}
							</Modal>

							<Modal
								{...sendLaterModal}
								modalTitle='Send Later'
								_onModalHidden={() => {
									formikProps.setValues({ ...formikProps.values, send: false, sendLater: false, sendAt: '' })
									setSendLaterConfirm(false)
								}}
								minHeightPercent={80}
								footer={
									<React.Fragment>
										<button type='button' className='btn btn-secondary' onClick={() => showHideSendLaterModal(false)}>Cancel</button>
										{sendLaterConfirm ?
											<button
												type='button'
												className='btn btn-primary'
												disabled={!formikProps.isValid}
												onClick={() => {
													//showHideSendLaterModal(false)
													formikProps.setFieldValue('send', true)
													formikProps.submitForm()
												}}
											>
												Schedule
											</button>
											:
											<button type='button' className='btn btn-warning' onClick={() => setSendLaterConfirm(true)}>Continue</button>
										}
									</React.Fragment>
								}
							>
								{sendLaterModal.show ?
									sendLaterConfirm ?
										<div>
											<p>Custom blasts can only be scheduled a maximum of 72 hours in advance.</p>
											<DatePickerField
												showTimeSelect={true}
												fieldProps={{ name: 'sendAt', label: `Schedule Date (${dayjs().format('zzz')})`, placeholder: 'Select a date and time' }}
												dateFilter={(date) => moment(date).isSameOrAfter(moment()) && moment(date).isBefore(moment().add(72, 'hours'))}
												disableDatepickerThrottle
											/>
										</div>
										:
										<div>
											<p className='p-2'>Are you sure you want to schedule this blast to be sent later?</p>
											<p className='bg-danger p-2 text-white border rounded border-white'>Once the blast is scheduled it <b>CAN be canceled</b> but <b>NOT edited</b>.</p>
										</div>
									:
									null
								}
							</Modal>
						</Form>

						<Modal
							{...addRecipientsModal}
							modalTitle='Add Recipients'
							size='fullscreen'
							minHeightPercent={90}
						>
							{addRecipientsModal.show ?
								<CustomEmailBlastFormAddRecipients
									addRecipients={(_recipients) => {
										addRecipients(_recipients)
										showHideAddRecipientsModal(false)
									}}
									mailTypeIds={[formikProps.values.mailTypeId]}
								/>
								:
								null
							}
						</Modal>

						<Modal
							{...addedRecipientsModal}
							modalTitle='Recipients'
							size='xl'
							minHeightPercent={90}
						>
							{addedRecipientsModal.show ? <CustomEmailBlastFormRecipients recipients={recipients} setRecipients={setRecipients} /> : null}
						</Modal>

						<Modal
							{...confirmSendImmediatelyModal}
							modalTitle='Confirm'
							footer={
								<React.Fragment>
									<button type='button' className='btn btn-secondary' onClick={() => showHideConfirmSendImmediatelyModal(false)}>Cancel</button>
									<button
										type='button'
										className='btn btn-success'
										onClick={() => {
											showHideConfirmSendImmediatelyModal(false)
											formikProps.setFieldValue('send', true)
											formikProps.submitForm()
										}}
									>
										Send
									</button>
								</React.Fragment>
							}
						>
							Are you sure you want to send this email blast now? This is the last confirmation.
						</Modal>

						<Modal
							{...previewModal}
							modalTitle='Preview Custom Blast'
							_onModalHidden={() => {
								setPreview(undefined)
							}}
							size='fullscreen'
						>
							<iframe style={{width: '100%', height: '100%'}} srcDoc={ preview || '' }></iframe>
						</Modal>
					</React.Fragment>
				)
			}}
		</Formik>
	)
}