import { useCallback, useContext, useEffect, useState } from "react"
import { AppActionContext } from "../app-store-provider"
import { IDefaultProps } from "./component-definitions"
import { MinistryInfoFyDataGeneralDataForm } from "./ministry-info-fy-data-general-data-form"
import { useHTTPRequestUiWrapper } from "../services/hooks"
import { IMinistryDataModelDocument } from "../open-api"
import Helmet from "react-helmet"
import { LoadingOverlay } from "./loading"
import { Link } from "@reach/router"

interface IMinistryInfoFyDataDetailProps extends IDefaultProps {
    ministryDataId?: string
}

export const MinistryInfoFyDataDetail = ({ministryDataId} : IMinistryInfoFyDataDetailProps) => {
    const { MinistryDataApi } = useContext(AppActionContext)!

    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    const [ministryDataToEdit, setMinistryDataToEdit] = useState<IMinistryDataModelDocument>()
    
    const fetchGrantRequest = useCallback(async (ministryDataId: string) => {
        const {data} = await makeHttpRequestWithUi({
            request: MinistryDataApi.apiMinistryDataIdGet(parseInt(ministryDataId)),
            disableSuccessToast: true,
            toastErrorMessage: 'There was an error retrieving the event to edit.'
        })

        setMinistryDataToEdit(data)
    }, [])

    useEffect(() => {
        if (ministryDataId) fetchGrantRequest(ministryDataId)
    }, [ministryDataId])

    if (!ministryDataToEdit) return <LoadingOverlay />

    return (
        <>
            <Helmet>
                <title>Edit FY Data: {ministryDataToEdit?.fiscalYear?.toString()}</title>
            </Helmet>
            
            <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to={`/ministry-info/${ministryDataToEdit?.ministryId}`}>Ministry</Link></li>
                <li className="breadcrumb-item"><Link to={`/ministry-info/${ministryDataToEdit?.ministryId}#ty-data`}>FY Data</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Edit FY Data</li>
            </ol>
            </nav>
            <div className='d-flex flex-column' style={{ height: '100vh' }}>
                <div className='m-2 d-flex align-items-center'>
                    <h3 className='ml-2'>Edit FY Data: {ministryDataToEdit?.fiscalYear}</h3>
                </div>
                <div className="mr-2">
                    <MinistryInfoFyDataGeneralDataForm ministryInfoFyDataGeneral={ministryDataToEdit} />
                </div>
            </div>
        </>
    )
}