import { useState } from "react"
import { Popup } from "./popup"
import clsx from 'clsx'
import { ChevronDownIcon } from "../assets"
import { FormikProps } from "formik"
import { FormikCommunitySelectField } from "./forms/formik-community-select-field"
import { BranchType } from "../constants"

interface IMapCommunityPopperProps {
    formikProps: FormikProps<any>
}

export const MapCommunityPopper = (props: IMapCommunityPopperProps) => {
    const { formikProps } = props
    const [open, setIsOpen] = useState(false)

    return (
      <Popup
        trigger={
          <button
            className={
              'btn btn-outline-secondary' +
              clsx(formikProps.values.branchIds.length > 0 ? ' active' : ' ')
            }
            type='button'
          >
            {formikProps.values.branchIds.length === 0 ? 'Communities' : `${formikProps.values.branchIds.length} Communities`} <ChevronDownIcon />
          </button>
        }
        onToggle={setIsOpen}
        popupOptions={{
          placement: 'bottom-start',
          modifiers: [
              {
                  name: 'offset',
                  options: {
                      offset: [0, 5]
                  }
              }
          ]
        }}
      >
        <div
            className="p-3"
          style={{
            backgroundColor: 'white',
            border: '1px solid gray',
            borderRadius: '.25rem',
            width: '400px',
            overflow: 'auto',
            overflowX: 'hidden'
          }}
        >
          <FormikCommunitySelectField field={{ name: 'branchIds', label: 'Community'}} branchTypes={[BranchType.Public]} multiple={true} />
        </div>
      </Popup>
    )
}