import React, { useContext, useState } from 'react'
import { IMailBlastRecipientDocument } from '../open-api'
import { SelectRecipientsByEvents } from './select-recipients-by-events'
import { SelectRecipientsByChapters } from './select-recipients-by-chapters'
import { SelectRecipientsByTags } from './select-recipients-by-tags'
import { SelectRecipientsByCsv } from './select-recipients-by-csv'
import { Radio } from './forms'
import { uniqBy } from 'lodash'
import { AppActionContext } from '../app-store-provider'
import { uuidv4 } from '../services/helpers'

interface ICustomEmailBlastFormAddRecipients {
	addRecipients: (recipients: IMailBlastRecipientDocument[]) => void
	mailTypeIds?: number[]
}

const emailRegex = new RegExp(/[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/gm)

export const CustomEmailBlastFormAddRecipients = (props: ICustomEmailBlastFormAddRecipients) => {
	const { addRecipients, mailTypeIds } = props

	const appActions = useContext(AppActionContext)!

	const [state, setState] = useState<{
		step: 1 | 2
		importMethod: 'event-registrations' | 'chapters' | 'tags' | 'csv'
		recipients: IMailBlastRecipientDocument[]
	}>({
		step: 1,
		importMethod: 'event-registrations',
		recipients: []
	})

	const recipientsSelected = (rec: IMailBlastRecipientDocument[]) => {
		// console.log('recipients selected', rec)
		let tempList = [...rec]
			.filter(o => new RegExp(/[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/gm)
			.test(o.email))
			.sort((a, b) => {
				if (a.email < b.email) return -1
				if (a.email > b.email) return 1
				return 0
			})
		// console.log('temp list', tempList)
		setState({ ...state, recipients: uniqBy(tempList, 'email') })
	}

	return (
		<div className='d-flex flex-column' style={{ minHeight: '100%' }}>
			<div style={{ flex: 1, height: '80%' }}>
				{state.step === 1 ?
					<div>
						<Radio
							value={state.importMethod}
							onChange={(change) => setState({ ...state, importMethod: change.currentTarget.value as 'event-registrations' | 'chapters' | 'tags' | 'csv' })}
							options={[
								{ value: 'event-registrations', label: 'I want to add recipients based on event registrations' },
								{ value: 'chapters', label: 'I want to add recipients based on communities/training areas' },
								{ value: 'tags', label: 'I want add recipients based on tags I have created' },
								{ value: 'csv', label: 'I want to upload a delimited list of recipients' },
							]}
						/>
						<p className='mt-4'><i>*The mailer will automatically de-duplicate your list. You may add more recipients at any time.</i></p>
					</div>
					:
					<React.Fragment>
						{state.importMethod === 'event-registrations' ? <SelectRecipientsByEvents recipientsSelected={recipientsSelected} mailTypeIds={mailTypeIds} /> : null}
						{state.importMethod === 'chapters' ? <SelectRecipientsByChapters recipientsSelected={recipientsSelected} mailTypeIds={mailTypeIds} /> : null}
						{state.importMethod === 'tags' ? <SelectRecipientsByTags recipientsSelected={recipientsSelected} mailTypeIds={mailTypeIds} /> : null}
						{state.importMethod === 'csv' ? <SelectRecipientsByCsv recipientsSelected={recipientsSelected} /> : null}
					</React.Fragment>
				}
			</div>
			<div className='d-flex my-2'>
				<button style={{ width: 100 }} onClick={() => setState({ ...state, step: 1, recipients: [] })} type='button' disabled={state.step === 1} className='btn btn-sm btn-secondary mr-2'>Previous</button>
				<button
					style={{ width: 100 }}
					onClick={() => {
						if (state.step === 1) {
							setState({ ...state, step: 2 })
						} else if (state.recipients.length === 0) {
							appActions.addAlert({ id: uuidv4(), body: 'Please choose at least 1 recipient to add to the blast.' })
						} else {
							addRecipients(state.recipients)
						}
					}}
					type='button'
					className='btn btn-sm btn-secondary'
				>
					{state.step === 2 ? 'Finish' : 'Next'}
				</button>
			</div>
		</div>
	)
}
