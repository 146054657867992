import { Form, Formik } from "formik"
import { useCallback, useContext, useEffect, useState } from "react"
import { AppActionContext, AppStateContext } from "../app-store-provider"
import { IEventRegistrantDocument, IGroupCoachingRegistrantDocument, IMinistrySearchResultModelDocument, ISearchRequestFilterDocument } from "../open-api"
import { useHTTPRequestUiWrapper } from "../services/hooks"
import { IFilter } from "../stores/api-actions"
import { FormikEffect } from "./formik-effect"
import { ISelectFieldOption, Radio, SelectField, SelectList, TextField, TextareaField } from "./forms"
import { Loading, LoadingOverlay, LoadingPropsSizeEnum } from "./loading"
import * as Yup from 'yup'
import { AccountLevel } from "../constants"
import { parse } from "papaparse"
import { isValidEmail } from "../services/helpers"
import { FormikCommunitySelectField } from "./forms/formik-community-select-field"

const filter: IFilter = {
    id: 'branchIdFilter',
    enabled: true,
    value: [],
    operator: 'in',
    property: 'branchAbbr'
}
	
interface Contact {
	contactId: number
	name?: string
	ministryName?: string
}

export interface IFormValues {
    branchId: string
    ministryContacts: Contact[]
	outsideContactEmails: string[]
	courseId: string
	inviteType: string
	groupName: string
	groupCode: string
	groupId?: number
}

export enum AddToCourseFormTab {
	'main' = 'main',
	'add-contacts' = 'add-contacts',
	'add-outside-contacts' = 'add-outside-contacts',
	'remove-contacts' = 'remove-contacts'
}

export enum AddToCourseModalTitle {
	'main' = 'Add to Course',
	'add-contacts' = 'Add Contact',
	'add-outside-contacts' = 'Add Outside Contact',
	'remove-contacts' = 'Remove Contact'
}

export interface IAddToCourseForm {
    afterSave: () => void
	initialBranchId?: string
	initialContacts?: Contact[]
	advanced?: boolean
    allowedAccountLevels?: AccountLevel[]
    onSave: (args: IFormValues) => Promise<IGroupCoachingRegistrantDocument[] | IEventRegistrantDocument[]>
    disabled?: boolean
	setShowAdvancedFormCheckbox: (value: boolean) => void,
	activeTab: AddToCourseFormTab,
	setActiveTab: (tab: AddToCourseFormTab) => void
}

export const AddToCourseForm = ({ afterSave, initialBranchId, initialContacts, advanced = false, onSave, setShowAdvancedFormCheckbox, activeTab, setActiveTab }: IAddToCourseForm) => {

    const appState = useContext(AppStateContext)!
	const appActions = useContext(AppActionContext)!
    const { MinistriesApi, MinistryContactsApi } = useContext(AppActionContext)!

    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()
	
	const [selectedContacts, setSelectedContacts] = useState<number[] | string[]>([])
	//const [communityOptions, setCommunityOptions] = useState<ISelectFieldOption[] | null>()
	const [courseOptions, setCourseOptions] = useState<ISelectFieldOption[] | null>()
	const [branches, setBranches] = useState<{ id: string, slug: string}[]>([])
	const [courses, setCourses] = useState<{ id: string, slug: string}[]>([])

    const [contactsLoading, setContactsLoading] = useState(false)
    const [contactOptions, setContactOptions] = useState<{ label: string, value: Contact }[]>([])

    const [ministriesLoading, setMinistriesLoading] = useState(false)
    const [ministries, setMinistries] = useState<IMinistrySearchResultModelDocument[] | null>()
    const [ministryOptions, setMinistryOptions] = useState<ISelectFieldOption[]>([])

    const [groupOptions, setGroupOptions] = useState<ISelectFieldOption[]>([])

    const [initialValues, setInitialValues] = useState({
        branchId: initialBranchId || '',
        ministryContacts: initialContacts || [],
		outsideContactEmails: [] as string[],
		courseId: '',
		inviteType: 'course',
		groupName: '',
		groupCode: '',
		groupId: undefined
    })

	useEffect(() => {
		if ( ! advanced ) {
			setInitialValues(initialValues => ({...initialValues, groupCode: '', inviteType: 'course', groupName: '', groupId: undefined}))
		}
	}, [advanced])

	const [branchId, setBranchId] = useState<string | null>(initialBranchId ?? null)

	const fetchGroups = async (community?: number) => {
		let groups;
		if ( ! community ) {
			groups = await appActions.LmsApi.apiLmsGroupNamesForCourseEnrollmentGet()
		} else {
			groups = await appActions.LmsApi.apiLmsGroupNamesForCourseEnrollmentGet(community)
		}
		if ( ! groups ) return

		setGroupOptions(groups.data.map(o => ({ label: o.groupTitle, value: o.groupId }) as ISelectFieldOption))
	}

	useEffect(() => {
		// const fetchCommunities = async () => {
		// 	const communities = await appActions.MiBranchesApi.apiMiBranchesGet()
		// 	if ( ! communities ) return

		// 	setCommunityOptions(communities.data.sort((a, b) => a.branchName?.localeCompare(b.branchName ?? '') ?? 0).map(o => ({ label: o.branchName, value: o.branchId.toString() }) as ISelectFieldOption))
		// 	setBranches(communities.data.map(o => ({ id: o.branchId.toString(), slug: o.branchAbbr ?? '' })))
		// }

		const fetchCourses = async () => {
			const courses = await appActions.LmsApi.apiLmsCourseNamesForCourseEnrollmentGet()
			if ( ! courses ) return

			setCourseOptions(courses.data.map(o => ({ label: o.courseTitle, value: o.courseId.toString() }) as ISelectFieldOption))
			setCourses(courses.data.map(o => ({ id: o.courseId.toString(), slug: o.courseSlug ?? '' })))
		}

		appActions.refreshBranchesCache()
		//fetchCommunities()
		fetchCourses()
		fetchGroups(parseInt(branchId ? branchId : '0'))
		//fetchMinistriesForBranchId(branchId || '')
	}, [])

	const fetchMinistriesForBranchId = async (branchId: string) => {
		const branch = appState.activeBranches.find(o => o.branchId.toString() === branchId)
		if (!branch?.branchAbbr) return

		setMinistriesLoading(true)
		filter.value = [branch.branchAbbr]
		const branchFilter = JSON.stringify([filter]) as unknown as ISearchRequestFilterDocument[]
		const ministryQuery = await makeHttpRequestWithUi({
			request: MinistriesApi.apiMinistriesGet(0, 100000, undefined, branchFilter),
			toastErrorMessage: 'There was an error retrieving the ministries for this community.',
			disableSuccessToast: true,
			disableLoading: true,
		})
		setMinistries(ministryQuery.data.data)
		setMinistryOptions(ministryQuery.data.data?.map(o => ({ label: `${o.ministryName} ${o.levelId ? `(${AccountLevel[o.levelId]})` : ''}`, value: `${o.ministryId}` })) || [])
		setMinistriesLoading(false)
	}

	const handleAddRegistrants = useCallback((values) => {
		setInitialValues(initialValues => ({...initialValues, ministryContacts: [...initialValues.ministryContacts, ...values.ministryContacts]}) )
		setActiveTab(AddToCourseFormTab["main"])
		setShowAdvancedFormCheckbox(true)
	}, [])

	const handleRemoveRegistrants = useCallback((values) => {
		setSelectedContacts(values)
		setActiveTab(AddToCourseFormTab["remove-contacts"])
		setShowAdvancedFormCheckbox(false)
	}, [])

	const contactsInitialValues = {
		branchId: '',
		ministryId: '',
		ministryContacts: []
	}

	if ( activeTab === AddToCourseFormTab["add-contacts"] ) return (
		<Formik 
			enableReinitialize 
			onSubmit={handleAddRegistrants} 
			initialValues={contactsInitialValues} 
			children={formikProps => (
				<>
					<FormikEffect
						formikProps={formikProps}
						onChange={async (prevValues, nextValues) => {
							if (!nextValues.branchId) setMinistryOptions([])
							if (nextValues.branchId && nextValues.branchId !== prevValues.branchId) {
								fetchMinistriesForBranchId(nextValues.branchId)
							}

							if (nextValues.ministryId !== prevValues.ministryId || nextValues.branchId !== prevValues.branchId) setContactOptions([])
							if (nextValues.ministryId && nextValues.ministryId !== prevValues.ministryId) {
								setContactsLoading(true)
								const contactsQuery = await makeHttpRequestWithUi({
									request: MinistryContactsApi.apiMinistriesIdContactsGet(parseInt(nextValues.ministryId)),
									disableSuccessToast: true,
									toastErrorMessage: 'There was an error retrieving the contacts for this ministry.',
									disableLoading: true,
								})
								setContactOptions(contactsQuery.data.map(o => ({ 
									label: `${o.firstName} ${o.lastName} (${ministries?.find(m => m.ministryId.toString() === nextValues.ministryId)?.ministryName})`, 
									value: { 
										name: `${o.firstName} ${o.lastName}`, 
										ministryName: ministries?.find(m => m.ministryId.toString() === nextValues.ministryId)?.ministryName, 
										contactId: o.ministryContactId 
									} as Contact
								})))
								setContactsLoading(false)
							}
						}}
					/>
					<Form>
						<>
							<div className="d-flex">
								<div style={{ flex: 1, marginRight: 10 }}>
									<FormikCommunitySelectField 
                                        field={{ name: 'branchId', label: "AD's Community", 
                                            //disabled: Boolean(eventToEdit?.status === GroupCoachingEventStatus.canceled) 
                                    	}} />
								</div>
								<div style={{ flex: 1 }}>
									{ministriesLoading ?
										<Loading size={LoadingPropsSizeEnum.medium} />
										:
										<SelectField fieldProps={{ name: 'ministryId', label: 'Ministry' }} options={ministryOptions} disabled={ministryOptions.length === 0} />
									}
								</div>
							</div>
							<div style={{ minHeight: 85 }}>
								{contactsLoading ?
									<Loading size={LoadingPropsSizeEnum.medium} />
									:
									<SelectField
										fieldProps={{ name: 'ministryContacts', label: 'Contacts' }}
										options={contactOptions}
										disabled={contactOptions.length === 0}
										multiple
									/>
								}
							</div>
						</>

						<div style={{ display: 'flex' }}>
							<button type='submit' style={{ width: 100 }} className='btn btn-primary'>Add</button>
							<button style={{ width: 100, marginLeft: 10 }} type='button' className='btn btn-secondary' onClick={() => {
								setActiveTab(AddToCourseFormTab["main"])
								setShowAdvancedFormCheckbox(true)
							}}>Cancel</button>
						</div>
					</Form>
				</>
			)} 
		/>
	)

	if ( activeTab === AddToCourseFormTab["remove-contacts"] ) return (
		<>
			{ selectedContacts.length 
				? <>
					<p>Are you sure you want to remove these contacts?</p>
					<div style={{ display: 'flex' }}>
						<button style={{ width: 100, marginRight: 10 }} type='button' className='btn btn-secondary' onClick={() => setActiveTab(AddToCourseFormTab["main"])}>Cancel</button>
						<button type='submit' style={{ width: 100 }} className='btn btn-primary' onClick={() => {
							setInitialValues(values => {
								if ( typeof selectedContacts[0] === 'number' ) {
									const newContacts = values.ministryContacts.filter(v => !(selectedContacts as (number[])).find(c => c === v.contactId))
			
									return {...values, ministryContacts: newContacts}
								} else {
									const newContacts = values.outsideContactEmails.filter(v => !(selectedContacts as (string[])).find(c => c === v))
			
									return {...values, outsideContactEmails: newContacts}
								}
							})
							setActiveTab(AddToCourseFormTab["main"])
							setShowAdvancedFormCheckbox(true)
						}}>Remove</button>
					</div>
				</>
				: <>
					<p>Please select at least one contact to remove.</p>
					<div style={{ display: 'flex' }}>
						<button style={{ width: 100 }} type='button' className='btn btn-secondary' onClick={() => {
							setActiveTab(AddToCourseFormTab["main"])
							setShowAdvancedFormCheckbox(true)	
						}}>Close</button>
					</div>
				</>
			}
			
		</>
	)

	if ( activeTab === AddToCourseFormTab["add-outside-contacts"] ) return (
		<Formik
			initialValues={{
				csvFile: '',
				pastedEmails: ''
			}}
			validationSchema={Yup.object({
				pastedEmails: Yup.mixed().test(
					'pastedEmails',
					'Please upload or paste a list of emails.',
					function (value) {
						if (!value) return false
						if ((value as string).trim().replaceAll(' ', '').split(',').some(o => !isValidEmail(o)) && (value as string).trim().replaceAll(' ', '').split('\n').some(o => !isValidEmail(o))) return false
						return true
					}
				)
			})}
			onSubmit={async (values) => {
				if (values.pastedEmails) {
					const results = parse<string>(values.pastedEmails.replaceAll(' ', ''))
					setInitialValues(initialValues => ({...initialValues, outsideContactEmails: [...initialValues.outsideContactEmails, ...results.data.flat().filter(o => o && !initialValues.outsideContactEmails.includes(o))]}))
					setActiveTab(AddToCourseFormTab["main"])
					setShowAdvancedFormCheckbox(true)
				}
			}}
			children={formikProps => (
				<Form style={{ height: '100%', }}>
					<TextareaField fieldProps={{ name: 'pastedEmails', label: 'Paste a delimitted list of emails' }} />

					<button type='submit' className='btn btn-primary mr-2'>Add Emails</button>
					<button type='button' className='btn btn-secondary' onClick={() => {
						setShowAdvancedFormCheckbox(true)
						setActiveTab(AddToCourseFormTab["main"])
					}}>Cancel</button>
				</Form>
			)}
		/>
	)

    return ( (courseOptions) ?
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={Yup.object({
                branchId: Yup.string()
					.required("Community is a required field"),
                courseId: Yup.string()
					.test(
						'courseId', 
						'Course is a required field', 
						function (value) {
							if ( this.parent.inviteType !== 'group' && !value ) return false
							return true
						}
					),
                ministryContacts: Yup.array().of(Yup.object()).nullable()
                    .test(
                        'ministryContacts',
                        'Required',
						function (value) {
							if ((!value || !value.length) && !this.parent.outsideContactEmails) return false
							return true
						}
                    ),
				outsideContactEmails: Yup.array().of(Yup.string()).nullable()
					.test(
						'outsideContactEmails',
						'Please add at least one Ministry Contact or Outside Contact.',
						function (value) {
							if ((!value || !value.length) && (!this.parent.ministryContacts || !this.parent.ministryContacts.length)) return false
							return true
						}
					),
				groupName: Yup.string().nullable(),
				inviteType: Yup.string().required(),
				groupCode: Yup.string().nullable(),
				groupId: Yup.number().nullable()
				
            })}
            onSubmit={async (values, actions) => {
                const results = await onSave(values)
                actions.resetForm()
                afterSave()
            }}
            children={formikProps => {
				if ( ! advanced && formikProps.values.inviteType === 'group' ) {
					formikProps.setFieldValue('inviteType', 'course')
				}
                return (
					<>
						<FormikEffect
							formikProps={formikProps}
							dependencies={['groupName', 'courseId', 'groupCode', 'branchId', 'inviteType']}
							onChange={async (prevValues, nextValues) => {
								const updatedValues = { ...formikProps.values }
								if ( ! nextValues.courseId || ! nextValues.branchId ) {
									updatedValues.groupName = ''
								} else {
									updatedValues.groupName = `AD | ${courses.find(c => c.id === nextValues.courseId)?.slug} | ${nextValues.groupCode} | ${branches.find(b => b.id === nextValues.branchId)?.slug}`
								}

								if ( prevValues.inviteType !== nextValues.inviteType ) {
									if ( nextValues.inviteType === 'group' && formikProps.values.courseId ) {
										updatedValues.courseId = ''
									} else if ( nextValues.inviteType !== 'group' && formikProps.values.groupCode ) {
										updatedValues.groupId = undefined
									}
								}

								formikProps.setValues(updatedValues)

								if ( nextValues.branchId !== prevValues.branchId ) {
									fetchGroups(parseInt(nextValues.branchId))
								}
							}}
						/>
						<Form>
							<div className="row">
								<div className="col">
									<FormikCommunitySelectField 
                                        field={{ name: 'branchId', label: "AD's Community", 
                                            //disabled: Boolean(eventToEdit?.status === GroupCoachingEventStatus.canceled) 
										}} />

									{ advanced && (
										<Radio 
											onChange={(event) => {
												formikProps.setValues(values => ({...values, inviteType: event.currentTarget.value}))
											}}
											value={formikProps.values.inviteType} 
											options={[{ value: 'course', label: 'Course Invite' }, { value: 'group', label: 'Existing Group Invite'}]}
										/>
									)}

									{ formikProps.values.inviteType !== 'group' && (
										<SelectField
											fieldProps={{ name: 'courseId', label: 'Course' }}
											options={courseOptions}
											disabled={formikProps.values.inviteType === 'group'}
										/>
									) }

									{ advanced && (
										<>
											{ formikProps.values.inviteType === 'group' ? <>
												<SelectField 
													fieldProps={{ name: 'groupId', label: 'Group' }}
													options={groupOptions}
												/>
											</> : <>
												<TextField 
													fieldProps={{ name: 'groupCode', label: 'Group Code (optional)', placeholder: 'Placeholder' }}
												/>
											</> }
										</>
									)}
								</div>

								<div className="col">
									<SelectList
										initialValues={formikProps.values.ministryContacts?.map(v => ({ value: v.contactId, label: `${v.name} (Ministry ${v.ministryName})` }))}
										onAddValue={() => {
											setInitialValues(formikProps.values)
											setActiveTab(AddToCourseFormTab["add-contacts"])
											setShowAdvancedFormCheckbox(false)
										}}
										onRemoveValue={(values) => {
											setInitialValues(formikProps.values)
											handleRemoveRegistrants(values)
											setShowAdvancedFormCheckbox(false)
										}}
										fieldProps={{ name: 'contacts', label: 'Selected Ministry Contacts'}} 
									/>

									<SelectList
										initialValues={formikProps.values.outsideContactEmails?.map(v => ({ value: v, label: v }))}
										onAddValue={() => {
											setInitialValues(formikProps.values)
											setActiveTab(AddToCourseFormTab["add-outside-contacts"])
											setShowAdvancedFormCheckbox(false)
										}}
										onRemoveValue={(values) => {
											setInitialValues(formikProps.values)
											handleRemoveRegistrants(values)
											setShowAdvancedFormCheckbox(false)
										}}
										fieldProps={{ name: 'outsideContactEmails', label: 'Selected Outside Contacts'}} 
									/>
								</div>
							</div>
							
							<div className="d-flex justify-content-center">
								<button type='submit' className='btn btn-primary mr-2'>Save</button>
								<button type='button' className='btn btn-secondary' data-dismiss='modal'>Cancel</button>
							</div>
						</Form>
					</>
                )
            }}
        /> : <LoadingOverlay size={LoadingPropsSizeEnum.medium} />
    )
}