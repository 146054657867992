import React, { useEffect, useContext } from 'react'
import { IDefaultProps } from './component-definitions'
import { AppActionContext, AppStateContext } from '../app-store-provider'
import { useMinistryInfoActions, IMinistryInfoActions } from '../stores/ministry-info-actions'
import { ministryInfoReducer } from '../stores/ministry-info-reducer'
import { IMinistryInfoState, MinistryInfoTab, isMinistryInfoTab } from '../stores/ministry-info-definitions'
import { Helmet } from 'react-helmet'
import { LoadingOverlay, LoadingPropsSizeEnum } from './loading'
import { MinistryInfoGeneral } from './ministry-info-general'
import { MinistryInfoProfile } from './ministry-info-profile'
import { MinistryInfoGrants } from './ministry-info-grants'
import { MinistryInfoEvents } from './ministry-info-events'
import { MinistryInfoEmailLog } from './ministry-info-email-log'
import { MinistryInfoNotes } from './ministry-info-notes'
import { showTab } from '../services/helpers'
import { ReactComponent as StarIcon } from '../assets/star.svg'
import { ReactComponent as StarFillIcon } from '../assets/star-fill.svg'
import { ReactComponent as BuildingIcon } from '../assets/building.svg'
import { registerLoadingTask, deregisterLoadingTask } from '../services/loading-service'
import { MinistryInfoFyData } from './ministry-info-fy-data'
import { usePrevious } from 'react-use'
import { MinistryInfoGsReport } from './ministry-info-gs-report'
import { MinistryInfoEventEvals } from './ministry-info-event-evals'
import { MinistryInfoCourses } from './ministry-info-courses'
import { Link } from '@reach/router'

export const MinistryInfoContext = React.createContext<{
	actions: IMinistryInfoActions
	state: IMinistryInfoState
} | null>(null)

interface IMinistryInfoProps extends IDefaultProps {
	ministryId?: string
}
export const MinistryInfo = (props: IMinistryInfoProps) => {
	const appActions = useContext(AppActionContext)!
	const appState = useContext(AppStateContext)!

	const ministryId = props.ministryId!

	const viewTab = (tabId: MinistryInfoTab) => {
		showTab(tabId)
		actions.setActiveTab(tabId)
	}

	const initialMinistryInfoState: IMinistryInfoState = {
		loading: false,
		activeTab: 'general-tab',
	}

	const [state, actions] = useMinistryInfoActions(ministryInfoReducer, initialMinistryInfoState)

	/* 
		Whenever the ministryId prop changes, go ahead and change the ministry ID in the MinistryInfoState.
		This will allow us to "navigate" to a new ministry URL without reloading the whole app. When we do, the ministryId prop will change.
		When the prop changes, we'll update the ministryId stored and used by MinistryInfoState to fetch all the data for the Ministry.
	*/
	useEffect(() => {
		if (ministryId) actions.setMinistryId(parseInt(ministryId))
		//eslint-disable-next-line
	}, [ministryId])

	/* 
		Whenever the ministryId stored in MinistryInfoState changes, go ahead and refresh the data for the Ministry and return to the General Tab.
	*/
	const previousMinistryId = usePrevious(state.ministryId)
	useEffect(() => {
		if (state.ministryId !== previousMinistryId) {
			actions.fetchMinistrySummary()
				.then(() => {
					// If there was a previous ministry ID and a new one and they aren't the same, go back to the general tab.
					if (previousMinistryId && state.ministryId && state.ministryId !== previousMinistryId) {
						viewTab('general-tab')
					}
					// Else, if there's a valid tab in the URL, navigate there.
					else {
						// Check if we're loading a URL for a specific tab and show that tab
						const hash = window.location.hash.replace('#', '') + '-tab'
						if (isMinistryInfoTab(hash)) viewTab(hash)
					}
				})
		}
		//eslint-disable-next-line
	}, [state.ministryId])

	return (
		<MinistryInfoContext.Provider value={{ actions, state }}>
			<div id='ministry-info'>
				{state.ministry ?
					<React.Fragment>
						<Helmet>
							<title>{state.ministry.ministryName}</title>
						</Helmet>
						<nav aria-label="breadcrumb">
							<ol className="breadcrumb">
								<li className="breadcrumb-item"><Link to={state.ministry.bArchived === true ? "/archived-ministries" : "/ministries"}>Ministries</Link></li>
								<li className="breadcrumb-item active" aria-current="page">Ministry Detail</li>
							</ol>
						</nav>
						<div className='m-2 d-flex align-items-center'>
							<BuildingIcon style={{ width: '25px', height: '25px' }} />
							<div className='d-flex'>
								<h3 className='ml-2'>{state.ministry.ministryName}
								{state.ministry.bActive === false ? <span className='badge badge-danger ml-2'>Deleted</span> : null}
								{state.ministry.bArchived === true ? <span className='badge badge-warning ml-2'>Archived</span> : null}</h3>
							</div>
							<div className='ml-2'>
								{appState.currentUserSessionState?.favorites?.ministries?.find(m => m.ministryId === state.ministry?.ministryId) ?
									<StarFillIcon
										style={{ cursor: 'pointer' }}
										className='text-warning' // Bootstrap's 'warning' color makes a good gold star color...
										onClick={async () => {
											const taskId = registerLoadingTask()
											if (state.ministry) await appActions.UserSessionActions.removeMinistryFromCurrentUserFavorites(state.ministry)
											deregisterLoadingTask(taskId)
										}}
									/>
									:
									<StarIcon
										style={{ cursor: 'pointer' }}
										onClick={async () => {
											const taskId = registerLoadingTask()
											if (state.ministry) await appActions.UserSessionActions.addMinistryToCurrentUserFavorites(state.ministry)
											deregisterLoadingTask(taskId)
										}}
									/>
								}
							</div>
						</div>

						<ul className='nav nav-tabs' role='tablist'>
							<li className={`nav-item`}>
								<a className={`nav-link ${state.activeTab === 'general-tab' ? 'active' : ''}`} id='general-tab' onClick={() => viewTab('general-tab')} href='#general' role='tab' aria-controls='general' aria-selected='true'>General</a>
							</li>
							{/* <li className={`nav-item`}>
								<a className={`nav-link ${state.activeTab === 'action-items-tab' ? 'active' : ''}`} id='action-items-tab' onClick={() => viewTab('action-items-tab')} href='#action-items' role='tab' aria-controls='action-items' aria-selected='false'>Action Items</a>
							</li> */}
							<li className={`nav-item`}>
								<a className={`nav-link ${state.activeTab === 'profile-tab' ? 'active' : ''}`} id='profile-tab' onClick={() => viewTab('profile-tab')} href='#profile' role='tab' aria-controls='profile' aria-selected='false'>Profile</a>
							</li>
							<li className={`nav-item`}>
								<a className={`nav-link ${state.activeTab === 'grants-tab' ? 'active' : ''}`} id='grants-tab' onClick={() => viewTab('grants-tab')} href='#grants' role='tab' aria-controls='grants' aria-selected='false'>Grants</a>
							</li>
							<li className={`nav-item`}>
								<a className={`nav-link ${state.activeTab === 'ty-data-tab' ? 'active' : ''}`} id='ty-data-tab' onClick={() => viewTab('ty-data-tab')} href='#ty-data' role='tab' aria-controls='ty-data' aria-selected='false'>FY Data</a>
							</li>
							<li className={`nav-item`}>
								<a className={`nav-link ${state.activeTab === 'events-tab' ? 'active' : ''}`} id='events-tab' onClick={() => viewTab('events-tab')} href='#events' role='tab' aria-controls='events' aria-selected='false'>Events</a>
							</li>
							<li className={`nav-item`}>
								<a className={`nav-link ${state.activeTab === 'notes-tab' ? 'active' : ''}`} id='notes-tab' onClick={() => viewTab('notes-tab')} href='#notes' role='tab' aria-controls='notes' aria-selected='false'>Notes</a>
							</li>
							<li className={`nav-item`}>
								<a className={`nav-link ${state.activeTab === 'gs-report-tab' ? 'active' : ''}`} id='gs-report-tab' onClick={() => viewTab('gs-report-tab')} href='#gs-report' role='tab' aria-controls='gs-report' aria-selected='false'>GS Report</a>
							</li>
							<li className={`nav-item`}>
								<a className={`nav-link ${state.activeTab === 'email-log-tab' ? 'active' : ''}`} id='email-log-tab' onClick={() => viewTab('email-log-tab')} href='#email-log' role='tab' aria-controls='email-log' aria-selected='false'>Email Log</a>
							</li>
							<li className={`nav-item`}>
								<a className={`nav-link ${state.activeTab === 'event-evals-tab' ? 'active' : ''}`} id='event-evals-tab' onClick={() => viewTab('event-evals-tab')} href='#event-evals' role='tab' aria-controls='event-evals' aria-selected='false'>Event Evals</a>
							</li>
							<li className={`nav-item`}>
								<a className={`nav-link ${state.activeTab === 'courses-tab' ? 'active' : ''}`} id='courses-tab' onClick={() => viewTab('courses-tab')} href='#courses' role='tab' aria-controls='courses' aria-selected='false'>Courses</a>
							</li>
						</ul>

						<div className='tab-content' style={{ overflow: 'auto' }}>
							<div className='tab-pane fade show active flex-fill' id='general' role='tabpanel' aria-labelledby='general-tab'>
								{state.activeTab === 'general-tab' ?
									<MinistryInfoGeneral />
									:
									null
								}
							</div>
							{/* <div className='tab-pane fade' id='action-items' role='tabpanel' aria-labelledby='action-items-tab'>
								Action Items
                            </div> */}
							<div style={state.activeTab === 'profile-tab' ? { height: '100%' } : {}} className='tab-pane fade d-flex flex-column flex-fill' id='profile' role='tabpanel' aria-labelledby='profile-tab'>
								{state.activeTab === 'profile-tab' ?
									<MinistryInfoProfile />
									:
									null
								}
							</div>
							<div style={state.activeTab === 'grants-tab' ? { height: '100%' } : {}} className='tab-pane fade' id='grants' role='tabpanel' aria-labelledby='grants-tab'>
								{state.activeTab === 'grants-tab' ?
									<MinistryInfoGrants />
									:
									null
								}
							</div>
							<div className='tab-pane fade' id='ty-data' role='tabpanel' aria-labelledby='ty-data-tab'>
								{state.activeTab === 'ty-data-tab' ?
									<MinistryInfoFyData />
									:
									null
								}
							</div>
							<div style={state.activeTab === 'events-tab' ? { height: '100%' } : {}} className='tab-pane fade' id='events' role='tabpanel' aria-labelledby='events-tab'>
								{state.activeTab === 'events-tab' ?
									<MinistryInfoEvents />
									:
									null
								}
							</div>
							<div style={state.activeTab === 'notes-tab' ? { height: '100%' } : {}} className='tab-pane fade' id='notes' role='tabpanel' aria-labelledby='notes-tab'>
								{state.activeTab === 'notes-tab' ?
									<MinistryInfoNotes />
									:
									null
								}
							</div>
							<div style={state.activeTab === 'gs-report-tab' ? { height: '100%' } : {}} className='tab-pane fade' id='gs-report' role='tabpanel' aria-labelledby='gs-report-tab'>
								{state.activeTab === 'gs-report-tab' ?
									<MinistryInfoGsReport />
									:
									null
								}
							</div>
							<div style={state.activeTab === 'email-log-tab' ? { height: '100%' } : {}} className='tab-pane fade' id='email-log' role='tabpanel' aria-labelledby='email-log-tab'>
								{state.activeTab === 'email-log-tab' ?
									<MinistryInfoEmailLog />
									:
									null
								}
							</div>
							<div style={state.activeTab === 'event-evals-tab' ? { height: '100%' } : {}} className='tab-pane fade' id='event-evals' role='tabpanel' aria-labelledby='event-evals-tab'>
								{state.activeTab === 'event-evals-tab' ?
									<MinistryInfoEventEvals />
									:
									null
								}
							</div>
							<div style={state.activeTab === 'courses-tab' ? { height: '100%' } : {}} className='tab-pane fade' id='courses' role='tabpanel' aria-labelledby='courses-tab'>
								{state.activeTab === 'courses-tab' ?
									<MinistryInfoCourses />
									:
									null
								}
							</div>
						</div>
					</React.Fragment>
					:
					null
				}
				{state.loading ? <LoadingOverlay size={LoadingPropsSizeEnum.medium} position='fixed' /> : null}
			</div>
		</MinistryInfoContext.Provider>
	)
}