import dayjs from "dayjs"
import { useContext, useState, useCallback, useEffect } from "react"
import { Helmet } from "react-helmet"
import { AppActionContext } from "../app-store-provider"
import { CalendarEventIcon } from "../assets"
import { IEventContentModelDocument, IPresentersDocument, IEventModelDocument } from "../open-api"
import { useHTTPRequestUiWrapper } from "../services/hooks"
import { IDefaultProps } from "./component-definitions"
import { EventForm, EventStatus } from './event-form'
import { LoadingOverlay } from "./loading"
import { Link } from "@reach/router"

interface IEventDetailState {
    selectedEventContent?: IEventContentModelDocument
    eventToEdit?: IEventModelDocument
    presenters: IPresentersDocument[]
}

interface IEventDetailProps extends IDefaultProps {
    eventId?: string
}

export const EventDetail = ({ eventId }: IEventDetailProps) => {
    const { PresentersApi, EventMgmtApi } = useContext(AppActionContext)!

    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    const [state, setState] = useState<IEventDetailState>({
        presenters: [],
    })

    const editEvent = useCallback(async (eventId: string) => {
        const { data: eventToEdit } = await makeHttpRequestWithUi({
            request: EventMgmtApi.apiEventMgmtEventEventIdGet(parseInt(eventId)),
            disableSuccessToast: true,
            toastErrorMessage: 'Encountered an error retrieving event to edit.',
        })

        const { data: selectedEventContent } = await makeHttpRequestWithUi({
            request: EventMgmtApi.apiEventMgmtEventContentEventContentIdGet(eventToEdit.eventContentId),
            disableSuccessToast: true,
            toastErrorMessage: 'Enountered an error retrieving event content record.',
        })

        setState(_state => ({ ..._state, eventToEdit, selectedEventContent }))
    }, [])

    useEffect(() => {
        makeHttpRequestWithUi({
            request: PresentersApi.apiPresentersGet(),
            disableSuccessToast: true,
            toastErrorMessage: 'There was an error retrieving the list of presenters.'
        })
            .then(results => {
                setState(_state => ({ ..._state, presenters: results.data.filter(o => o.bActive) }))
            })
    }, [])

    useEffect(() => {
        if (eventId) editEvent(eventId)
    }, [])

    const getPageTitle = () => {
        if (!state.eventToEdit) return 'Error: No event was found.'
        
        let result = '';
        if (state.eventToEdit.startDateTime) result += `${dayjs(state.eventToEdit.startDateTime?.toString()).format('M/D/YYYY')} - `
        if (state.eventToEdit.trainingArea) result += `${state.eventToEdit.trainingArea} - `
        if (state.eventToEdit.siteName) result += `${state.eventToEdit.siteName} - `
        result += `(${state.eventToEdit.eventId})`

        return result
    }

    if (!state.eventToEdit) return <LoadingOverlay />

    return (
        <>
            <Helmet>
                <title>{getPageTitle()}</title>
            </Helmet>

            <div className='d-flex flex-column' style={{ height: '100vh' }}>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/event-management">Event Management</Link></li>
                        <li className="breadcrumb-item"><Link to={`/event-content/${state.eventToEdit.eventContentId}`}>Event Content</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Event Detail</li>
                    </ol>
                </nav>
                <div className='m-2 d-flex align-items-center'>
                    <CalendarEventIcon style={{ width: '25px', height: '25px', }} />
                    <h3 className='ml-2'>
                        {getPageTitle()}
                        {state.eventToEdit.status === EventStatus.activated ? <span className='badge badge-success ml-2'>Activated</span> : null}
                        {state.eventToEdit.status === EventStatus.draft ? <span className='badge badge-info ml-2'>Draft</span> : null}
                        {state.eventToEdit.status === EventStatus.canceled ? <span className='badge badge-warning ml-2'>Canceled</span> : null}
                    </h3>
                </div>

                {state.presenters && state.selectedEventContent &&
                    <div className='m-2' style={{ display: 'flex', flex: 1, minHeight: 0, overflow: 'hidden' }}>
                        <EventForm
                            eventContent={state.selectedEventContent}
                            eventToEdit={state.eventToEdit}
                            setEventToEdit={eventToEdit => setState(_state => ({ ..._state, eventToEdit }))}
                            presenters={state.presenters}
                        />
                    </div>
                }
            </div>
        </>
    )
}