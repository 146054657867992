import React, { useContext, useEffect, useState } from 'react'
import { AppActionContext, AppStateContext } from '../app-store-provider'
import { CommunitiesByTrainingAreaModel } from '../models/community'
import { sortListBySorts, filterGridListItems, sortListByProperty } from '../services/helpers'
import { IAppState } from '../stores/app-definitions'
import { GridDataFetch, IGridListItem, IGridRowAction, IGridState, GridUserInteractionStateKey, IGridRowGroupActions, IGridAction } from '../stores/grid-definitions'
import { IDefaultProps } from './component-definitions'
import { ReactComponent as PencilIcon } from '../assets/pencil.svg'
import { SquareDeleteIcon } from './partials'
import { UserModel, UserPermissionLevel } from '../models/user'
import { useCommunityManagementDefaultColumns, useHTTPRequestUiWrapper, useModal } from '../services/hooks'
import { defaultGridState, useGrid } from '../stores/grid-actions'
import { gridReducer } from '../stores/grid-reducer'
import { Grid } from './grid'
import { Modal } from './modal'
import { IMiTrainingAreaSummaryModelDocument } from '../open-api'
import { TrainingAreaForm } from './training-area-form'
import { CommunityDetail } from './community-detail'
import { navigate } from '@reach/router'
import { Helmet } from 'react-helmet'
import { PeopleIcon, XSquareIcon } from '../assets'

interface ICommunitiesProps extends IDefaultProps { }
export const Communities = (props: ICommunitiesProps) => {

	const appActions = useContext(AppActionContext)!
	const appState = useContext(AppStateContext)!
	const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

	useEffect(() => {
		// Go ahead and refresh the list of community summaries any time this component is loaded for the first time.
		appActions.fetchCommunitySummaries()

		// Go ahead and refresh the list of users any time this component is loaded for the first time.
		appActions.fetchMiUsers(true)

		// eslint-disable-next-line
	}, [])

	const dataSource: GridDataFetch<IAppState> = async (queryState, _appState) => {
		const { communitySummaries, currentUser } = _appState

		if (!communitySummaries) return { count: 0, rows: [] }

		/* 
				Admin\handlers\branch.cfc lines 1828-1831
				Filter the list of communities based on whether the user is a super user or if the community is in the user's branch list

				Filter the list of communities based on whether they are active or not (admin\modules\userAdmin.js line 298)
				(c.branchTypeId === BranchTypeId.Public || c.branchTypeId === BranchTypeId.Affiliate || c.branchTypeId === BranchTypeId.Priavte) &&
		*/
		let _communities = [...sortListByProperty(communitySummaries, 'branchName')]

		if (queryState.sorts) sortListBySorts(_communities, queryState.sorts)
		let rows = _communities.map(CommunitiesByTrainingAreaModel.toGridListItem)
		if (queryState.filters) rows = filterGridListItems(rows, queryState.filters)

		return {
			count: _communities.length,
			rows,
		}
	}

	const [selectedRow, setSelectedRow] = useState<IGridListItem>()

	const [trainingAreaToEdit, setTrainingAreaToEdit] = useState<IMiTrainingAreaSummaryModelDocument>()
	const [trainingAreaFormModal, showHideTrainingAreaFormModal] = useModal()
	const editTrainingArea = async (trainingAreaRow: IGridListItem) => {
		setSelectedRow(trainingAreaRow)
		showHideTrainingAreaFormModal(true)

		const trainingAreaToEditQuery = await makeHttpRequestWithUi({
			request: appActions.MiTrainingAreasApi.apiMiTrainingAreasIdGet(parseInt(trainingAreaRow.id)),
			disableSuccessToast: true,
			toastErrorMessage: 'There was an error retrieving the training area to edit.'
		})

		setTrainingAreaToEdit(trainingAreaToEditQuery.data)
	}

	const rowActions: { [id: string]: IGridRowAction } = {
		editTrainingArea: {
			id: 'editTrainingArea',
			action: async (options) => {
				options.e.stopPropagation()
				editTrainingArea(options.row)
			},
			icon: <PencilIcon />,
			tooltipText: 'Edit Training Area & Sites'
		}
	}

	/* 
			Only users with permission level 3 or higher for App 1 can archive training areas
			Admin\modules\BranchAdmin\branchAdmin.js line 144
	*/
	const [deleteTrainingAreaModal, showHideDeleteTrainingAreaModal] = useModal()
	const [trainingAreaToDelete, setTrainingAreaToDelete] = useState<{ trainingAreaId: string | null, firstConfirm: boolean, secondConfirm: boolean, thirdConfirm: boolean }>({
		trainingAreaId: null,
		firstConfirm: true,
		secondConfirm: false,
		thirdConfirm: false,
	})
	if (UserModel.checkPermissionLevelForUser(1, UserPermissionLevel.Administrator, appState.currentUser)) {
		rowActions.deleteTrainingArea = {
			id: 'deleteTrainingArea',
			action: async (options) => {
				options.e.stopPropagation()
				setTrainingAreaToDelete({
					trainingAreaId: options.row.id,
					firstConfirm: true,
					secondConfirm: false,
					thirdConfirm: false,
				})
				showHideDeleteTrainingAreaModal(true)
			},
			icon: <SquareDeleteIcon />,
			tooltipText: 'Archive Training Area'
		}
	}


	const rowGroupActions: IGridRowGroupActions = { actions: [], nestedActions: []}

	if (UserModel.checkPermissionLevelForUser(1, UserPermissionLevel.Modify, appState.currentUser)) {
		rowGroupActions.actions?.push({
			id: 'editCommunity',
			action: async (options) => {
				navigate(`/communities/${options.rowGroupId}`)
			},
			icon: <PencilIcon />,
			title: 'Edit Community'
		})
	}

	const [deleteCommunityModal, showHideDeleteCommunityModal] = useModal()
	const [communityToDelete, setCommunityToDelete] = useState<{ branchId: string | null, firstConfirm: boolean, secondConfirm: boolean, thirdConfirm: boolean }>({
		branchId: null,
		firstConfirm: true,
		secondConfirm: false,
		thirdConfirm: false,
	})
	
	if (UserModel.userIsSuperUser(appState.currentUser)) {
		rowGroupActions.actions?.push({
			id: 'deleteCommunity',
			action: async (options) => {
				options.e.stopPropagation()
				setCommunityToDelete({
					branchId: options.rowGroupId,
					firstConfirm: true,
					secondConfirm: false,
					thirdConfirm: false,
				})
				showHideDeleteCommunityModal(true)
			},
			icon: <XSquareIcon />,
			title: 'Archive Community'
		})
	}

	const gridActions: IGridAction[] = []

    if (UserModel.userIsSuperUser(appState.currentUser)) {
        gridActions.push({
			id: 'newCommunity',
			label: 'New Community',
			onClick: () => showHideCommunityFormModal(true)
		})
    }


	const [communityFormModal, showHideCommunityFormModal] = useModal()

	const initialGridState: IGridState = {
		...defaultGridState,
		columns: useCommunityManagementDefaultColumns(),
		dataSource,
		usingLocalData: true,
		disabledPagination: true,
		userSessionStateKey: GridUserInteractionStateKey.Communities,
		gridActions: gridActions,
		rowActions,
		rowGroupActions,
		rowDoubleClicked: async (row) => {
			editTrainingArea(row)
		},
		respectGlobalCommunityFilter: true
	}

	const [state, actions] = useGrid(gridReducer, initialGridState, appState)

	useEffect(() => {
		actions.doFetch()

		// eslint-disable-next-line
	}, [appState.communitySummaries])


	return (
		<React.Fragment>
			<Helmet>
				<title>Communities</title>
			</Helmet>
			<div className='d-flex flex-column' style={{ height: '100vh' }}>
				<div className='m-2 d-flex align-items-center'>
					<PeopleIcon style={{ width: '25px', height: '25px' }} />
					<h3 className='ml-2'>Communities</h3>
				</div>
				<Grid actions={actions} state={state} />
			</div>

			<Modal
				{...communityFormModal}
				modalTitle='New Community'
				size='fullscreen'
				dismissible={false}
				_onModalHidden={() => {
					setSelectedRow(undefined)
				}}
			>
				{communityFormModal.show ?
					<CommunityDetail onCommunitySave={async (community) => {

						/* A community is passed back out only on new creation. */
						if (!!community) {
							appActions.fetchMiUsers(true) // Refresh users just in case a user was created alongside this community.
						}

						showHideCommunityFormModal(false)
						appActions.fetchCommunitySummaries()

						// Update the community list that is stored in browser local storage, or the user won't see the new community in their dropdown.
						appActions.refreshBranchesCache()
					}} />
					:
					null
				}
			</Modal>

			<Modal
				{...deleteCommunityModal}
				modalTitle='Archive Community Confirmation'
				footer={
					<React.Fragment>
						<button className='btn btn-secondary' onClick={() => showHideDeleteCommunityModal(false)}>Cancel</button>
						<button
							className={`btn ${communityToDelete.firstConfirm ? 'btn-warning' : 'btn-danger'}`}
							onClick={async () => {
								if (!communityToDelete.branchId) return
								if (communityToDelete.firstConfirm) setCommunityToDelete({ ...communityToDelete, firstConfirm: false, secondConfirm: true })
								if (communityToDelete.secondConfirm) setCommunityToDelete({ ...communityToDelete, firstConfirm: false, secondConfirm: false, thirdConfirm: true })
								if (communityToDelete.thirdConfirm) {
									await makeHttpRequestWithUi({
										request: appActions.MiBranchesApi.apiMiBranchesIdArchiveDelete(parseInt(communityToDelete.branchId)),
										toastSuccessMessage: 'Successfully archived the community.',
										toastErrorMessage: 'There was an error archiving the community.'
									})
									appActions.fetchCommunitySummaries()
									showHideDeleteCommunityModal(false)
								}
							}}
						>
							{communityToDelete.thirdConfirm ? 'DELETE' : 'Delete'}
						</button>
					</React.Fragment>
				}
				_onModalHidden={() => setCommunityToDelete({ branchId: null, firstConfirm: true, secondConfirm: false, thirdConfirm: false })}
			>
				{communityToDelete.firstConfirm ? 'Are you sure you want to archive this community?' : null}
				{communityToDelete.secondConfirm ? 'Ministries associated with this community will need to be modified. Are you sure you want to continue?' : null}
				{communityToDelete.thirdConfirm ?
					<div>
						<p className='text-danger'><b>Last chance! Are you certain?</b></p>
					</div>
					:
					null
				}
			</Modal>

			<Modal
				{...deleteTrainingAreaModal}
				modalTitle='Archive Training Area Confirmation'
				footer={
					<React.Fragment>
						<button className='btn btn-secondary' onClick={() => showHideDeleteTrainingAreaModal(false)}>Cancel</button>
						<button
							className={`btn ${trainingAreaToDelete.firstConfirm ? 'btn-warning' : 'btn-danger'}`}
							onClick={async () => {
								if (!trainingAreaToDelete.trainingAreaId) return
								if (trainingAreaToDelete.firstConfirm) setTrainingAreaToDelete({ ...trainingAreaToDelete, firstConfirm: false, secondConfirm: true })
								if (trainingAreaToDelete.secondConfirm) setTrainingAreaToDelete({ ...trainingAreaToDelete, firstConfirm: false, secondConfirm: false, thirdConfirm: true })
								if (trainingAreaToDelete.thirdConfirm) {
									await makeHttpRequestWithUi({
										request: appActions.MiTrainingAreasApi.apiMiTrainingAreasIdArchiveDelete(parseInt(trainingAreaToDelete.trainingAreaId)),
										toastSuccessMessage: 'Successfully archived the Training Area.',
										toastErrorMessage: 'There was an error archiving the Training Area.'
									})
									appActions.fetchCommunitySummaries()
									showHideDeleteTrainingAreaModal(false)
								}
							}}
						>
							{trainingAreaToDelete.thirdConfirm ? 'ARCHIVE' : 'Archive'}
						</button>
					</React.Fragment>
				}
				_onModalHidden={() => setTrainingAreaToDelete({ trainingAreaId: null, firstConfirm: true, secondConfirm: false, thirdConfirm: false })}
			>
				{trainingAreaToDelete.firstConfirm ? 'Are you sure you want to archive this training area?' : null}
				{trainingAreaToDelete.secondConfirm ? 'Chapters associated with this training area will need to be modified, Are you sure you want to continue?' : null}
				{trainingAreaToDelete.thirdConfirm ?
					<div className='text-danger'><b>Last chance, are you certain?</b></div>
					:
					null
				}
			</Modal>

			<Modal
				{...trainingAreaFormModal}
				modalTitle='Edit Training Area'
				size='fullscreen'
				_onModalHidden={() => {
					setSelectedRow(undefined)
					setTrainingAreaToEdit(undefined)
				}}
			>
				{trainingAreaFormModal.show && selectedRow?.rowGroup?.id && trainingAreaToEdit ?
					<TrainingAreaForm
						branchId={parseInt(selectedRow?.rowGroup?.id)}
						trainingAreaToEdit={trainingAreaToEdit}
						onSaveSucces={() => {
							setSelectedRow(undefined)
							showHideTrainingAreaFormModal(false)
							appActions.fetchCommunitySummaries()
						}}
					/>
					:
					null
				}
			</Modal>
		</React.Fragment>
	)
}