import moment from 'moment'
import { useLocalStorage } from 'react-use'
import { IBranchType, IBranchRegion } from '../models/community'
import { IMiBranchModelDocument, IZNteeCodesDocument, IZCountrysDocument, IMailTypeDocument, IInvolvementMultipliersModelDocument } from '../open-api'
import { useHTTPRequestUiWrapper } from '../services/hooks'
import { IMifApi } from './api-actions'
import { AppAction, AppActionType } from './app-reducer'

export interface ICachesActions {
	refreshAllCaches: () => Promise<void>
	refreshBranchesCache: () => Promise<void>
	clearAllCaches: () => Promise<void>
}

export const useCaches = (dispatch: React.Dispatch<AppAction>, apiActions: IMifApi): ICachesActions => {
	const makeHTTPRequestWithUi = useHTTPRequestUiWrapper()

	// Increment the version number to bust the cache and cause a refresh of all cached items
	const version = '1.0003'

	const [cachedBranches, setCachedBranches] = useLocalStorage<{ version: string, lastCached: Date, branches: IMiBranchModelDocument[] }>('branches')
	const refreshBranchesCache = async () => {
		const branchesQuery = await makeHTTPRequestWithUi({ request: apiActions.MiBranchesApi.apiMiBranchesGet(), disableSuccessToast: true, toastErrorMessage: 'There was an error retrieving the Communities list.' })
		setCachedBranches({
			version,
			lastCached: new Date(),
			branches: branchesQuery.data
		})
		dispatch({ type: AppActionType.setBranches, payload: branchesQuery.data })
	}

	const [cachedNteeCodes, setCachedNteeCodes] = useLocalStorage<{ version: string, lastCached: Date, nteeCodes: IZNteeCodesDocument[] }>('nteeCodes')
	const refreshNteeCodesCache = async () => {
		const nteeCodesQuery = await makeHTTPRequestWithUi({ request: apiActions.NteeCodesApi.apiNteeCodesGet(), disableSuccessToast: true, toastErrorMessage: 'There was an error retrieving the NTEE codes list.' })
		setCachedNteeCodes({
			version,
			lastCached: new Date(),
			nteeCodes: nteeCodesQuery.data
		})
		dispatch({ type: AppActionType.setNteeCodes, payload: nteeCodesQuery.data })
	}

	// const [cachedBranchTypes, setCachedBranchTypes] = useLocalStorage<{ version: string, lastCached: Date, branchTypes: IBranchType[] }>('branchTypes')
	// const refreshBranchTypesCache = async () => {
	// 	const branchTypesQuery = await makeHTTPRequestWithUi({ request: apiActions.MiBranchesApi.apiMiBranchesBranchTypesGet(), disableSuccessToast: true, toastErrorMessage: 'There was an error retrieving the community types list.' })
	// 	setCachedBranchTypes({
	// 		version,
	// 		lastCached: new Date(),
	// 		branchTypes: branchTypesQuery.data as IBranchType[]
	// 	})
	// 	dispatch({ type: AppActionType.setBranchTypes, payload: branchTypesQuery.data as IBranchType[] })
	// }

	const [cachedBranchRegions, setCachedBranchRegions] = useLocalStorage<{ version: string, lastCached: Date, branchRegions: IBranchRegion[] }>('branchRegions')
	const refreshBranchRegionsCache = async () => {
		const branchRegionsQuery = await makeHTTPRequestWithUi({ request: apiActions.MiBranchesApi.apiMiBranchesBranchRegionsGet(), disableSuccessToast: true, toastErrorMessage: 'There was an error retrieving the community types list.' })
		setCachedBranchRegions({
			version,
			lastCached: new Date(),
			branchRegions: branchRegionsQuery.data as IBranchRegion[]
		})
		dispatch({ type: AppActionType.setBranchRegions, payload: branchRegionsQuery.data as IBranchRegion[] })
	}

	const [cachedCountries, setCachedCountries] = useLocalStorage<{ version: string, lastCached: Date, countries: IZCountrysDocument[] }>('countries')
	const refreshCountriesCache = async () => {
		const countriesQuery = await makeHTTPRequestWithUi({ request: apiActions.CountriesApi.apiCountriesGet(), disableSuccessToast: true, toastErrorMessage: 'There was an error retrieving the countries list.' })
		setCachedCountries({
			version,
			lastCached: new Date(),
			countries: countriesQuery.data
		})
		dispatch({ type: AppActionType.setCountries, payload: countriesQuery.data })
	}

	const [cachedInvolvementMultipliers, setCachedInvolvementMultipliers] = useLocalStorage<{ version: string, lastCached: Date, involvementMultipliers: IInvolvementMultipliersModelDocument }>('involvementMultipliers')
	const refreshInvolvementMultipliersCache = async () => {
		const involvementMultipliersQuery = await makeHTTPRequestWithUi({ request: apiActions.InvolvementMultipliersApi.apiInvolvementMultipliersGet(), disableSuccessToast: true, toastErrorMessage: 'There was an error retrieving the Involvement Multipliers.' })
		setCachedInvolvementMultipliers({
			version,
			lastCached: new Date(),
			involvementMultipliers: involvementMultipliersQuery.data
		})
		dispatch({ type: AppActionType.setInvolvementMultipliers, payload: involvementMultipliersQuery.data })
	}

	const [cachedMailTypes, setCachedMailTypes] = useLocalStorage<{ version: string, lastCached: Date, mailTypes: IMailTypeDocument[] }>('mailTypes')
	const refreshMailTypesCache = async () => {
		const mailTypesQuery = await makeHTTPRequestWithUi({ request: apiActions.MailTypesApi.apiMailTypesGet(), disableSuccessToast: true, toastErrorMessage: 'There was an error retrieving the mail types list.' })
		setCachedMailTypes({
			version,
			lastCached: new Date(),
			mailTypes: mailTypesQuery.data
		})
		dispatch({ type: AppActionType.setMailTypes, payload: mailTypesQuery.data })
	}

	const refreshAllCaches = async () => {
		const promises: Promise<any>[] = []

		// Check if we need to refresh any cached constants
		// BRANCHES
		if (cachedBranches) {
			if (Math.abs(moment(cachedBranches.lastCached).diff(moment(), 'hours')) >= 24 || cachedBranches.version !== version) {
				promises.push(refreshBranchesCache())
			} else {
				console.log('retrieved branches from cache')
				dispatch({ type: AppActionType.setBranches, payload: cachedBranches.branches })
			}
		} else {
			promises.push(refreshBranchesCache())
		}

		// NTEE Codes
		if (cachedNteeCodes) {
			if (Math.abs(moment(cachedNteeCodes.lastCached).diff(moment(), 'months')) > 1 || cachedNteeCodes.version !== version) {
				promises.push(refreshNteeCodesCache())
			} else {
				console.log('retrieved ntee codes from cache')
				dispatch({ type: AppActionType.setNteeCodes, payload: cachedNteeCodes.nteeCodes })
			}
		} else {
			promises.push(refreshNteeCodesCache())
		}

		// // Branch types
		// if (cachedBranchTypes) {
		// 	if (Math.abs(moment(cachedBranchTypes.lastCached).diff(moment(), 'months')) > 1 || cachedBranchTypes.version !== version) {
		// 		promises.push(refreshBranchTypesCache())
		// 	} else {
		// 		console.log('retrieved branch types from cache')
		// 		dispatch({ type: AppActionType.setBranchTypes, payload: cachedBranchTypes.branchTypes })
		// 	}
		// } else {
		// 	promises.push(refreshBranchTypesCache())
		// }

		// Branch regions
		if (cachedBranchRegions) {
			if (Math.abs(moment(cachedBranchRegions.lastCached).diff(moment(), 'months')) > 1 || cachedBranchRegions.version !== version) {
				promises.push(refreshBranchRegionsCache())
			} else {
				console.log('retrieved branch regions from cache')
				dispatch({ type: AppActionType.setBranchRegions, payload: cachedBranchRegions.branchRegions })
			}
		} else {
			promises.push(refreshBranchRegionsCache())
		}

		// Countries
		if (cachedCountries) {
			if (Math.abs(moment(cachedCountries.lastCached).diff(moment(), 'months')) > 1 || cachedCountries.version !== version) {
				promises.push(refreshCountriesCache())
			} else {
				console.log('retrieved countries from cache')
				dispatch({ type: AppActionType.setCountries, payload: cachedCountries.countries })
			}
		} else {
			promises.push(refreshCountriesCache())
		}

		// Involvement Multipliers
		if (cachedInvolvementMultipliers) {
			if (Math.abs(moment(cachedInvolvementMultipliers.lastCached).diff(moment(), 'days')) > 1 || cachedInvolvementMultipliers.version !== version) {
				promises.push(refreshInvolvementMultipliersCache())
			} else {
				console.log('retrieved Involvement Multipliers from cache')
				dispatch({ type: AppActionType.setInvolvementMultipliers, payload: cachedInvolvementMultipliers.involvementMultipliers })
			}
		} else {
			promises.push(refreshInvolvementMultipliersCache())
		}

		// Mail Types
		if (cachedMailTypes) {
			if (Math.abs(moment(cachedMailTypes.lastCached).diff(moment(), 'months')) > 1 || cachedMailTypes.version !== version) {
				promises.push(refreshMailTypesCache())
			} else {
				console.log('retrieved mail types from cache')
				dispatch({ type: AppActionType.setMailTypes, payload: cachedMailTypes.mailTypes })
			}
		} else {
			promises.push(refreshMailTypesCache())
		}

		await Promise.all(promises)
	}

	const clearAllCaches = async () => {
		const promises: Promise<any>[] = []

		// BRANCHES
		promises.push(refreshBranchesCache())

		// NTEE Codes
		promises.push(refreshNteeCodesCache())

		// // Branch types
		// promises.push(refreshBranchTypesCache())
		
		// Branch regions
		promises.push(refreshBranchRegionsCache())

		// Countries
		promises.push(refreshCountriesCache())

		// Involvement Multipliers
		promises.push(refreshInvolvementMultipliersCache())

		// Mail Types
		promises.push(refreshMailTypesCache())

		await Promise.all(promises)
	}

	return {
		refreshAllCaches,
		refreshBranchesCache,
		clearAllCaches
	}
}