import dayjs from "dayjs"
import { Formik, Form, FieldArray } from "formik"
import moment from "moment"
import { Dispatch, SetStateAction, useContext, useEffect, useMemo, useRef, useState } from "react"
import { AppActionContext, AppStateContext } from "../app-store-provider"
import { IAddGroupCoachingModelDocument, IEventContentModelDocument, IEventSiteModelDocument, IGroupCoachingModelDocument, IGroupCoachingTopicResultDocument, IPresentersDocument } from "../open-api"
import { sortListByProperty, uuidv4 } from "../services/helpers"
import { useHTTPRequestUiWrapper, useModal } from "../services/hooks"
import { FormikEffect } from "./formik-effect"
import { CurrencyField, DatePickerField, TinyMceField, ISelectFieldOption, CheckboxField } from "./forms"
import * as Yup from 'yup'
import { AccountLevel, BRANCH_TIMEZONE_OPTIONS } from "../constants"
import { EventSiteSelectField } from './event-site-select-field'
import { IGroupCoachingEventStatus as GroupCoachingEventStatus, IGroupCoachingEventStatus } from "./group-coaching"
import { ClipboardIcon, XSquareIcon } from "../assets"
import { useCopyToClipboard } from "react-use"
import { FormikTextField } from './forms/formik-text-field'
import { FormikSelectField } from './forms/formik-select-field'
import { FormikCommunitySelectField } from './forms/formik-community-select-field'
import { FormikNumberField } from './forms/formik-number-field'
import { EventFormCancel } from "./event-form-cancel"
import { getMaxSeatsOptions } from "./event-content-step2"
import { EventAddToMyCalendar } from "./event-add-to-my-calendar"

enum GroupCoachingFormSummaryStatus {
    AddingEventContentAndEvent,
    AddingEvent,
    EditingEvent
}

interface IOAdditionalDateModel {
    id: string
    eventDate: string
    timeZone: string
    startTime: string
    endTime: string
}

const timeOptions: ISelectFieldOption[] = (() => {
    const options: ISelectFieldOption[] = []

    let startTime = dayjs().set('hour', 0).set('minute', 0).set('second', 0);
    const endTime = startTime.add(24, 'hour');

    let timeOut = 0 // Used to prevent infinite loops. Just to be safe.
    while(startTime < endTime && timeOut < 150){
        const timeStr = startTime.format('h:mm A')
        options.push({
            label: timeStr,
            value: timeStr,
        })

        startTime = startTime.add(15, 'minute');
        ++timeOut
    }
    return options
})()

interface IGroupCoachingFormSummaryProps {
    //setActiveTab: (tabId: string) => void
    //registrants: IGroupCoachingRegistrantDocument[]
    selectedTopic: IGroupCoachingTopicResultDocument
    selectedEventContent?: IEventContentModelDocument
    eventToEdit?: IGroupCoachingModelDocument
    setEventToEdit: Dispatch<SetStateAction<IGroupCoachingModelDocument | undefined>>
    afterSave?: () => Promise<void>
    presenters: IPresentersDocument[]
    //closeModal?: () => void
    sites: IEventSiteModelDocument[]
    setSites: Dispatch<SetStateAction<IEventSiteModelDocument[]>>
    duplicateEventContent?: boolean
}
export const GroupCoachingFormSummary = ({
    selectedEventContent,
    eventToEdit,
    setEventToEdit,
    selectedTopic,
    //setActiveTab,
    afterSave,
    presenters,
    //registrants,
    //closeModal,
    sites,
    setSites,
    duplicateEventContent
}: IGroupCoachingFormSummaryProps) => {
    const { GroupCoachingApi, addAlert, EventMgmtApi, fetchTrainingAreas } = useContext(AppActionContext)!
    const { currentUser, activeTrainingAreas, activeBranches, globalCommunityContext } = useContext(AppStateContext)!

    const editingStatus : GroupCoachingFormSummaryStatus = (() => {
        if (!eventToEdit && selectedEventContent) return GroupCoachingFormSummaryStatus.AddingEvent
        if (eventToEdit && !duplicateEventContent) return GroupCoachingFormSummaryStatus.EditingEvent
        if ((!eventToEdit && !selectedEventContent) || duplicateEventContent) return GroupCoachingFormSummaryStatus.AddingEventContentAndEvent

        throw new Error("No valid editing status found.")
    })()

    const disableECFields = editingStatus !== GroupCoachingFormSummaryStatus.AddingEventContentAndEvent
    const _isExistingSeries = Boolean(eventToEdit?.isSeries || selectedEventContent?.isSeries) && !duplicateEventContent
    const [availableAccountLevels, setAvailableAccountLevels] = useState<(string | AccountLevel)[]>([])
    const [addToCalendarModal, showHideAddToCalendarModal] = useModal()

    useEffect(() => {
		if (!activeTrainingAreas) fetchTrainingAreas()
	}, [])

    const [_, copy] = useCopyToClipboard()

    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    const readonly = useMemo(() => {
        if (eventToEdit?.status === IGroupCoachingEventStatus.canceled) return true
    }, [eventToEdit])

    const initialValues = useMemo(() => {
        const currentUserPresenterId = currentUser?.presenterId

        return {
            allowedAccountLevelIds: (eventToEdit?.allowedAccountLevelIds || selectedEventContent?.allowedAccountLevelIds || selectedTopic?.allowedAcctLevelsIds || []).map(o => o.toString()),
            title: eventToEdit?.title || selectedEventContent?.title || selectedTopic?.title || '',
            subtitle: eventToEdit?.subtitle || selectedEventContent?.subtitle || selectedTopic?.subtitle || '',

            presenterIds: ((duplicateEventContent ? null : eventToEdit?.presenterIds) || [...currentUserPresenterId ? [currentUserPresenterId] : []]).map(o => o.toString()),
            branchId: (duplicateEventContent ? null : eventToEdit?.branchId?.toString()) || globalCommunityContext?.branchId.toString() || '', // Group learning EC events must all have the same branchId.
            trainingAreaId: (duplicateEventContent ? null : eventToEdit?.trainingAreaId?.toString()) || '',
            siteId: (duplicateEventContent ? null : eventToEdit?.siteId?.toString()) || '',
            seatCount: (duplicateEventContent ? null : eventToEdit?.seatCount?.toString()) || selectedEventContent?.seatCount?.toString() || '',
            seatCost: (duplicateEventContent ? null : eventToEdit?.seatCost) || (_isExistingSeries ? selectedEventContent?.seatCost : null) || 0,
            maxSeatsPerOrg: (duplicateEventContent ? null : eventToEdit?.maxSeatsPerOrg?.toString()) || '',

            date: (duplicateEventContent ? null : eventToEdit?.startDateTime) || '',
            startTime: ((duplicateEventContent ? null : eventToEdit?.startDateTime) && dayjs(eventToEdit?.startDateTime).format('h:mm A')) || '',
            endTime: ((duplicateEventContent ? null : eventToEdit?.endDateTime) && dayjs(eventToEdit?.endDateTime).format('h:mm A')) || '',
            timeZone: (duplicateEventContent ? null : eventToEdit?.timeZone) || '',
            webinarUrl: (duplicateEventContent ? null :eventToEdit?.webinarUrl) || '',

            displayDate: duplicateEventContent ? '' : (eventToEdit?.displayDate || ''),
            invitationOnly: Boolean(eventToEdit?.invitationOnly) ? 'invitation' : 'any',
            bAllowPublicRegistration: eventToEdit ? Boolean(eventToEdit?.bAllowPublicRegistration) : false,

            shortDescription: eventToEdit?.shortDescription || selectedEventContent?.shortDescription || selectedTopic?.shortDescription || '',
            fullDescription: eventToEdit?.fullDescription || selectedEventContent?.fullDescription || selectedTopic?.fullDescription || '',
            privateEventDetails: eventToEdit?.privateEventDetails || '',
            publicEventDetails: eventToEdit?.publicEventDetails || '',
            isSeries: _isExistingSeries,
            activatePressed: false,
            additionalDates: [] as IOAdditionalDateModel[]
        }
    }, [eventToEdit, selectedTopic, presenters, currentUser])

    const accountLevelIds = Object.values(AccountLevel).filter(o => typeof o === 'number')
    
    useEffect(() => {
        if (selectedTopic.allowedAcctLevelsIds) {
            setAvailableAccountLevels(Object.values(AccountLevel).filter(o => typeof o === 'number' && selectedTopic.allowedAcctLevelsIds?.includes(o)))
        }
    }, [selectedTopic]);

    const [cancelEventModal, showHideCancelEventModal] = useModal()
    // const handleCancelEventPressed = useCallback(async () => {
    //     if (!eventToEdit) return
    //     if (registrants.length) {
    //         showHideCancelEventModal(true)
    //     } else {
    //         await EventMgmtApi.apiEventMgmtEventEventIdCancelPut(eventToEdit.eventId)
    //         afterSave && afterSave()
    //     }
    // }, [eventToEdit, registrants])

    const scrollToTopRef = useRef<HTMLFormElement>(null)

    const getDisabledInivtationOnlyTooltip = () => {
        if (_isExistingSeries) return "Because this is a series, this can only be updated via at the Event Content level."

        if (eventToEdit?.eventContentInvitationOnly) return "Because the Event Content is 'Invitation Only', this can only be updated at the Event Content level."

        return "Something went wrong...please alert Software Development."
    }

    return (
        selectedTopic && <>
            <Formik
                enableReinitialize
                validateOnMount={Boolean(eventToEdit)}
                initialValues={initialValues}
                validationSchema={Yup.object({
                    allowedAccountLevelIds: Yup.array().nullable().required('Must select at least one allowed account level.'),
                    title: Yup.string().required('Required').max(200, 'Title must be at most ${max} characters'),
                    subtitle: Yup.string().max(800, 'Subtitle must be at most ${max} characters'),
                    date: Yup.string().nullable().required('Required'),
                    timeZone: Yup.string().required('Required'),
                    startTime: Yup.string().required('Required')
                        .test(
                            'startTime',
                            'Start time must be before the end time.',
                            function (value) {
                                if (this.parent.endTime) {
                                    return dayjs(value, 'h:mm A').isBefore(dayjs(this.parent.endTime, 'h:mm A'))
                                } else {
                                    return true
                                }
                            }),
                    endTime: Yup.string().required('Required')
                        .test(
                            'endTime',
                            'End time must be after the start time.',
                            function (value) {
                                if (this.parent.startTime) {
                                    return dayjs(value, 'h:mm A').isAfter(dayjs(this.parent.startTime, 'h:mm A'))
                                } else {
                                    return true
                                }
                            }),
                    presenterIds: Yup.array().nullable().required('Required').min(1, 'Must select at least one presenter.'),
                    branchId: Yup.string().required('Required'),
                    trainingAreaId: Yup.string().required('Required'),
                    siteId: Yup.string().test(
                        'siteId',
                        sites.length > 0 ? 'Required' : 'No Group Learning sites in the selected training area.',
                        function (value) {
                            if (this.parent.activatePressed || eventToEdit?.status === IGroupCoachingEventStatus.activated) {
                                return !!value && value.length > 0
                            }
                            return true
                        }
                    ),
                    displayDate: Yup.string().nullable()
                        .test(
                            'displayDate',
                            'Display Date cannot be after the event date.',
                            function (displayDate) {
                                if (this.parent.date && displayDate) {
                                    return dayjs(displayDate).isSameOrBefore(dayjs(this.parent.date))
                                } else {
                                    return true
                                }
                            }
                        ),
                    /* inviteBlasts: Yup.array().of(inviteBlastSchema), */
                    seatCount: Yup.string()
                        .test(
                            'seatCount',
                            'Required',
                            function (value) {
                                if (this.parent.siteId) return !!value
                                return true
                            }
                        ),
                    shortDescription: Yup.string().required('Required').max(5000, 'Short Description must be at most ${max} characters'),
                    fullDescription: Yup.string().required('Required'),
                    privateEventDetails: Yup.string().max(800, 'Private Event Details must be at most ${max} characters. HTML source code is included in the character count. You may view the source code by clicking Tools > Source Code, on this fields toolbar.')
                })}
                onSubmit={async (values, actions) => {
                    const valuesToSave: IAddGroupCoachingModelDocument = {
                        ...values,
                        presenterIds: values.presenterIds.map(o => parseInt(o)),
                        allowedAccountLevelIds: values.allowedAccountLevelIds.map(o => parseInt(o)),
                        startDateTime: moment(`${moment(values.date).format('MM/DD/YYYY')} ${values.startTime}`).format('YYYY-MM-DDTHH:mm:00'),
                        endDateTime: moment(`${moment(values.date).format('MM/DD/YYYY')} ${values.endTime}`).format('YYYY-MM-DDTHH:mm:00'),
                        branchId: parseInt(values.branchId),
                        trainingAreaId: parseInt(values.trainingAreaId),
                        siteId: parseInt(values.siteId),
                        seatCount: parseInt(values.seatCount),
                        maxSeatsPerOrg: values.maxSeatsPerOrg ? parseInt(values.maxSeatsPerOrg) : null,
                        invitationOnly: values.invitationOnly === 'invitation',
                        isSeries: values.isSeries,
                        groupCoachingTopicId: selectedEventContent?.groupCoachingTopicId ?? 0,
                        timeZone: values.timeZone,
                        webinarUrl: values.webinarUrl,
                        additionalDates: values.additionalDates.map(x => {
                            console.log(x)
                            return {
                                startDateTime: moment(`${moment(x.eventDate).format('MM/DD/YYYY')} ${x.startTime}`).format('YYYY-MM-DDTHH:mm:00'),
                                endDateTime: moment(`${moment(x.eventDate).format('MM/DD/YYYY')} ${x.endTime}`).format('YYYY-MM-DDTHH:mm:00'),
                                timeZone: x.timeZone
                            }
                        })
                        /* reminderEmailSendDate: values.sendInviteReminder ? values.reminderEmailSendDate : null, */
                    }

                    /* if (eventToEdit && values.inviteBlasts.length) {
                        await makeHttpRequestWithUi({
                            request: MailBlastTaskApi.apiMailBlastTaskEventEventIdScheduleEventInviteBlastPost(eventToEdit.eventId, {
                                sendAt: values.inviteBlasts.filter(o => !o.mailBlastId).map(o => dayjs(`${o.sendDate} ${o.sendTime}`, 'MM/DD/YYYY h:mm A').format(SERVER_TIME_FORMAT))
                            }),
                            disableSuccessToast: true,
                            toastErrorMessage: `Encountered an error scheduling invite blast${values.inviteBlasts.length === 1 ? '' : 's'}.`
                        })
                    } */

                    if (values.activatePressed) {
                        if (!eventToEdit) return

                        await makeHttpRequestWithUi({
                            request: EventMgmtApi.apiEventMgmtEventEventIdPut(eventToEdit.eventId,
                                {
                                    ...eventToEdit,
                                    ...valuesToSave,
                                    isMultiDay: false,
                                    multiDayData: null,
                                    callInNumber: null,
                                }
                            ),
                            toastSuccessMessage: 'Successfully updated group learning event.',
                            toastErrorMessage: 'There was an error updating the group learning event.'
                        })

                        await makeHttpRequestWithUi({
                            request: GroupCoachingApi.apiGroupCoachingEventIdActivatePut(eventToEdit.eventId),
                            toastSuccessMessage: 'Event activated.',
                            toastErrorMessage: 'Encountered an error activating the event.',
                        })
                        setEventToEdit({...eventToEdit, status: IGroupCoachingEventStatus.activated}) /* Have to do this so that the status badge changes in the parent component. */
                        actions.setFieldValue('activatePressed', false)
                        if (scrollToTopRef.current) scrollToTopRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
                    } else {
                        if (editingStatus === GroupCoachingFormSummaryStatus.AddingEventContentAndEvent) {
                            const createQuery = await makeHttpRequestWithUi({
                                request: GroupCoachingApi.apiGroupCoachingPost({ ...valuesToSave, groupCoachingTopicId: selectedTopic.id }),
                                toastSuccessMessage: 'Successfully created group learning event.',
                                toastErrorMessage: 'There was an error creating the group learning event.',
                            })

                            setEventToEdit(createQuery.data)
                        } else if (editingStatus === GroupCoachingFormSummaryStatus.AddingEvent && selectedEventContent) {
                            /* Adding an event to an existing EC record. */
                            const {data: addedEvent} = await makeHttpRequestWithUi({
                                request: EventMgmtApi.apiEventMgmtEventPost(
                                    {
                                        ...valuesToSave,
                                        eventContentId: selectedEventContent.eventContentId,
                                        isMultiDay: false,
                                        multiDayData: null,
                                        callInNumber: null,
                                        groupCoachingTopicId: selectedEventContent.groupCoachingTopicId,
                                    }
                                ),
                                toastSuccessMessage: 'Successfully created group learning event.',
                                toastErrorMessage: 'There was an error creating the group learning event.',
                            })

                            const {data: groupCoachingRecord} = await makeHttpRequestWithUi({
                                request: GroupCoachingApi.apiGroupCoachingEventContentIdEventIdGet(addedEvent.eventContentId, addedEvent.eventId),
                                toastSuccessMessage: 'Successfully created group learning event.',
                                toastErrorMessage: 'There was an error creating the group learning event.',
                            })

                            setEventToEdit(groupCoachingRecord)
                        } else if (eventToEdit) {
                                await makeHttpRequestWithUi({
                                    request: EventMgmtApi.apiEventMgmtEventEventIdPut(eventToEdit.eventId,
                                        {
                                            ...eventToEdit,
                                            ...valuesToSave,
                                            isMultiDay: false,
                                            multiDayData: null,
                                            callInNumber: null
                                        }
                                    ),
                                    toastSuccessMessage: 'Successfully updated group learning event.',
                                    toastErrorMessage: 'There was an error updating the group learning event.'
                                })
                        } else {
                            throw new Error('No valid state was found.')
                        }
                    }

                    afterSave && afterSave()
                    /* fetchInviteBlasts() */
                }}
                children={formikProps => {
                    const { values, setFieldValue, submitForm, isValid, validateForm, setFieldError } = formikProps

                    const selectedBranch = activeBranches.find(o => o.branchId.toString() === values.branchId)
                    const selectedTrainingArea = activeTrainingAreas?.find(o => o.trainingAreaId.toString() === values.trainingAreaId)
                    const selectedSite = sites.find(o => o.siteId.toString() === values.siteId)

                    const calcAdditionalDate = () => {

                        if (formikProps.values.additionalDates.length === 0 && formikProps.values.date.length) {
                            console.log('here', formikProps.values.timeZone)
                            return { 
                                id: uuidv4(), 
                                eventDate: dayjs(formikProps.values.date).add(1, 'day').format('MM/DD/YYYY'), 
                                startTime: formikProps.values.startTime, 
                                endTime: formikProps.values.endTime,
                                timeZone: formikProps.values.timeZone
                            }
                        }
                        else if (formikProps.values.additionalDates.slice(-1)[0]?.eventDate?.length)
                        {
                            console.log('there')
                            return { 
                                id: uuidv4(), 
                                eventDate: dayjs(formikProps.values.additionalDates.slice(-1)[0]?.eventDate).add(1, 'day').format('MM/DD/YYYY'), 
                                startTime: formikProps.values.additionalDates.slice(-1)[0]?.startTime, 
                                endTime: formikProps.values.additionalDates.slice(-1)[0]?.endTime,
                                timeZone: formikProps.values.additionalDates.slice(-1)[0]?.timeZone
                            }
                        }

                        return { 
                            id: uuidv4(), 
                            eventDate: '', 
                            startTime: '', 
                            endTime: ''
                        }
                    }

                    return (
                        <Form style={{ padding: 10, }} ref={scrollToTopRef}>

                            {/* <OnSitesChange sites={sites} setFieldValue={setFieldValue} eventToEdit={eventToEdit} /> */}
                            <FormikEffect
                                formikProps={formikProps}
                                onChange={(prevValues, nextValues) => {
                                    if (nextValues.startTime !== prevValues.startTime) {
                                        formikProps.setFieldValue('endTime', dayjs(nextValues.startTime, 'h:mm A').add(1, 'hour').format('h:mm A'))
                                    }

                                    if (!nextValues.allowedAccountLevelIds) {
                                        formikProps.setFieldValue('bAllowPublicRegistration', false)
                                    } else if (!prevValues.allowedAccountLevelIds || (nextValues.allowedAccountLevelIds.length !== prevValues.allowedAccountLevelIds.length)) {
                                        if (nextValues.allowedAccountLevelIds.length !== accountLevelIds.length) formikProps.setFieldValue('bAllowPublicRegistration', false)
                                    }

                                    if (prevValues.siteId !== nextValues.siteId) {
                                        const prevSelectedSite = sites.find(o => o.siteId.toString() === prevValues.siteId)
                                        const nextSelectedSite = sites.find(o => o.siteId.toString() === nextValues.siteId)

                                        if (prevSelectedSite && nextSelectedSite && prevSelectedSite.maxSeats !== nextSelectedSite.maxSeats) {
                                            addAlert({
                                                id: uuidv4(),
                                                body: <div>Site selection changed. Max seat count adjusted to <b>{nextSelectedSite.maxSeats}</b>.</div>,
                                            })
                                        }
                                    }

                                    if (prevValues.branchId !== nextValues.branchId) {

                                        const prevSelectedBranch = activeBranches.find(o => o.branchId.toString() === prevValues.branchId)
                                        const nextSelectedBranch = activeBranches.find(o => o.branchId.toString() === nextValues.branchId)

                                        setFieldValue('trainingAreaId', '')
                                        setFieldValue('siteId', '')

                                        if (!prevSelectedBranch && nextSelectedBranch ) {
                                            /* First time branch is selected */
                                            setFieldValue('timeZone', nextSelectedBranch.timezone)
                                        } else if (prevSelectedBranch && nextSelectedBranch && prevSelectedBranch.timezone !== nextSelectedBranch.timezone) {
                                            /* Subsequent selections */
                                            setFieldValue('timeZone', nextSelectedBranch.timezone)
                                            if (formikProps.values.additionalDates) {
                                                formikProps.values.additionalDates.forEach(x => x.timeZone = nextSelectedBranch.timezone || '')
                                            }
                                            addAlert({
                                                id: uuidv4(),
                                                body: <div>Community selection changed. Timezone adjusted to <b>{nextSelectedBranch.timezone}</b>.</div>,
                                            })
                                        }
                                    }

                                    if (prevValues.trainingAreaId !== nextValues.trainingAreaId) {
                                        setFieldValue('siteId', '')
                                        setFieldValue('webinarUrl', '')
                                    }
                                }}
                            />

                            <div style={{ display: 'flex' }}>
                                <div style={{ flex: 1, marginRight: 5 }}>
                                    <b>Topic</b>
                                    <div className='mb-2'>{selectedTopic.title}</div>

                                    <FormikSelectField
                                        multiple
                                        field={{ name: 'allowedAccountLevelIds', label: 'Account Levels', disabled: disableECFields }}
                                        options={availableAccountLevels.map(o => ({ label: AccountLevel[(o as number)], value: o.toString() }))}
                                    />

                                    <FormikTextField field={{ name: 'title', label: 'Title', disabled: disableECFields }} />
                                    <FormikTextField field={{ name: 'subtitle', label: 'Subtitle', disabled: disableECFields }} />
                                </div>
                                <div style={{ flex: 2, marginLeft: 30 }}>
                                    {eventToEdit?.publicEventUrl && editingStatus !== GroupCoachingFormSummaryStatus.AddingEventContentAndEvent &&
                                        <>
                                            <b>Registration Page URL: </b>
                                            <div className='mb-2' style={{ display: 'flex', alignItems: 'center' }}>
                                                <a target='_blank' href={eventToEdit.publicEventUrl}>{eventToEdit.publicEventUrl}</a>
                                                <ClipboardIcon
                                                    style={{ cursor: 'pointer', minWidth: 16 }}
                                                    className='primary-color-hover ml-2'
                                                    onClick={() => {
                                                        eventToEdit.publicEventUrl && copy(eventToEdit.publicEventUrl)
                                                        alert('Copied!')
                                                    }}
                                                />
                                            </div>
                                        </>
                                    }
                                    {!!selectedTrainingArea?.bVirtual && editingStatus === GroupCoachingFormSummaryStatus.EditingEvent && <div style={{ flex: 1 }} className='mr-2'>
                                        <FormikTextField field={{ name: 'webinarUrl', label: 'Virtual Meeting URL', disabled: readonly }} labelTooltip="Zoom/Teams URL that attendees with will use to join your event remotely. This URL will only be included in the 'Add this event to your calendar' as the location. It is not used anywhere else. We also recommend including the link in your Private Event Details." />
                                    </div>}
                                    {/* 
                                        -- TB 20230713 - To improve performance, no longer pulling registrants for the summary page,
                                        -- so no longer displaying the RegistrantsSummary component. Let's see if any of our end 
                                        -- users care.
                                        {registrants.length > 0 &&
                                        <>
                                            <b>Registrants</b>
                                            <RegistrantsSummary registrants={registrants} setActiveTab={setActiveTab} />
                                        </>
                                    } */}
                                </div>
                            </div>

                            {editingStatus === GroupCoachingFormSummaryStatus.AddingEventContentAndEvent &&
                                <TinyMceField 
                                    disableContainingPTags 
                                    /*disabled={readonly}  */
                                    fieldProps={{ name: 'shortDescription', label: 'Short Description', labeltooltip: 'Displays in the Registration Notification email.' }} />
                            }
                            {editingStatus === GroupCoachingFormSummaryStatus.AddingEventContentAndEvent &&
                                <TinyMceField 
                                    /*disabled={readonly }  */
                                    fieldProps={{ name: 'fullDescription', label: 'Full Description', labeltooltip: 'Displays in the Invitation email and on the Event Details page.' }} />
                            }
                            
                            <CheckboxField 
                                disabled={readonly || !(editingStatus === GroupCoachingFormSummaryStatus.AddingEventContentAndEvent)}
                                fieldProps={{ name: 'isSeries', label: <>This is a <strong>series.</strong></>, labeltooltip: 'A series is a grouping of multi-part events (e.g., Part 1, Part 2, etc.). Registrants are automatically enrolled for the entire series, not just a single event.' }} />

                            <hr className='my-4' />

                            <div style={{ display: 'flex' }}>
                                <div style={{ flex: 1 }} className='mr-2'>
                                    <FormikSelectField
                                        multiple
                                        field={{ name: 'presenterIds', label: 'Presenter(s)', placeholder: 'Select presenter(s)...', 
                                        disabled: readonly }}
                                        options={sortListByProperty(presenters, 'firstName').map(o => ({ value: `${o.presenterId}`, label: `${o.firstName || ''} ${o.lastName || ''}` }))}
                                    />
                                </div>

                                <div style={{ flex: 1 }} className='mr-2'>
                                    {/* GL events should all be in the same community, so only allow this to change if user is super user. */}
                                    <FormikCommunitySelectField field={{ name: 'branchId', label: 'Community', disabled: readonly || !currentUser?.bSuperUser, labelTooltip: 'All events should belong to the same community. Only editable by Super Users.' }} />
                                </div>

                                <div style={{ flex: 1 }} className='mr-2'>
                                    <FormikSelectField
                                        field={{ name: 'trainingAreaId', label: 'Training Area', disabled: readonly }}
                                        options={activeTrainingAreas?.filter(o => o.branchId.toString() === values.branchId).map(o => ({ label: `${o.trainingAreaAbbr}`, value: `${o.trainingAreaId}` }))}
                                    />
                                </div>

                                <div style={{ flex: 1 }}>
                                    <EventSiteSelectField
                                        limitToGroupCoaching
                                        field={{
                                            name: 'siteId',
                                            label: 'Site',
                                            disabled: readonly,
                                            labelTooltip: 'If preferred site is not listed, go to Community Management to add or adjust for Group Learning.'
                                        }}
                                        trainingAreaId={values.trainingAreaId.length ? parseInt(values.trainingAreaId) : undefined}
                                        setSites={setSites}
                                    />
                                </div>
                            </div>

                            <hr className='my-4' />

                            <div style={{ display: 'flex' }}>
                                <div style={{ flex: 1, marginRight: 10 }}>
                                    <DatePickerField
                                        disabled={readonly}
                                        fieldProps={{
                                            name: 'date',
                                            label: ((editingStatus === GroupCoachingFormSummaryStatus.AddingEventContentAndEvent) && !!formikProps.values.isSeries) ? 'First Appointment Date' : 'Appointment Date',
                                            placeholder: 'Select a date...',
                                        }}
                                        disableDatepickerThrottle
                                    />
                                </div>
                                <div style={{ flex: 1, marginRight: 10 }}>
                                    <FormikSelectField field={{ name: 'timeZone', label: 'Time Zone' }} options={BRANCH_TIMEZONE_OPTIONS} />
                                </div>
                                <div style={{ flex: 1, marginRight: 10 }}>
                                    <FormikSelectField
                                        field={{ name: 'startTime', label: 'Start Time', disabled: readonly }}
                                        options={timeOptions}
                                    />
                                </div>
                                <div style={{ flex: 1 }}>
                                    <FormikSelectField
                                        field={{ name: 'endTime', label: 'End Time', disabled: readonly }}
                                        options={timeOptions}
                                    />
                                </div>
                            </div>

                            {editingStatus === GroupCoachingFormSummaryStatus.AddingEventContentAndEvent && <FieldArray 
                                name='additionalDates'
                                children={arrayHelpers =>
                                    <div>
                                        {formikProps.values.additionalDates.map((_, index) => 
                                            <div key={index} style={{ display: 'flex' }}>
                                                <div style={{ flex: 1, marginRight: 10 }}>
                                                    <DatePickerField
                                                        disabled={readonly}
                                                        fieldProps={{
                                                            name: `additionalDates.${index}.eventDate`,
                                                            label: `Appointment Date ${index+2}`,
                                                            placeholder: 'Select a date...',
                                                        }}
                                                        disableDatepickerThrottle
                                                    />
                                                </div>
                                                <div style={{ flex: 1, marginRight: 10 }}>
                                                    <FormikSelectField field={{ name: `additionalDates.${index}.timeZone`, label: 'Time Zone' }} options={BRANCH_TIMEZONE_OPTIONS} />
                                                </div>
                                                <div style={{ flex: 1, marginRight: 10 }}>
                                                    <FormikSelectField
                                                        field={{ name: `additionalDates.${index}.startTime`, label: 'Start Time', disabled: readonly }}
                                                        options={timeOptions}
                                                    />
                                                </div>
                                                <div style={{ flex: 1 }}>
                                                    <FormikSelectField
                                                        field={{ name: `additionalDates.${index}.endTime`, label: 'End Time', disabled: readonly }}
                                                        options={timeOptions}
                                                    />
                                                </div>
                                                <XSquareIcon className='error-color-hover cursor-pointer ml-2' style={{position: 'relative', top: '35px'}} onClick={() => arrayHelpers.remove(index)} />
                                            </div> 
                                        )}
                                        <button
                                            className='btn btn-primary btn-sm'
                                            type='button'
                                            onClick={() => arrayHelpers.push(calcAdditionalDate())}
                                            disabled={readonly}
                                        >
                                            Add Day
                                        </button>
                                    </div>
                                }
                            />}

                            <hr className='my-3' />

                            <div style={{ display: 'flex' }}>
                                <div style={{ flex: 1 }} className='mr-4'>
                                    <DatePickerField
                                        disabled={readonly}
                                        fieldProps={{
                                            name: 'displayDate',
                                            label: 'Display Date (optional)',
                                            placeholder: 'Select a date...',
                                            labeltooltip: "Use to hide this event until the specified date.<br />If specified, event will not be visible from the Ministry Portal and won't display in the newsletter until this date is reached."
                                        }}
                                        disableDatepickerThrottle
                                    />
                                </div>
                                <div style={{ flex: 1 }} className='mr-4'>
                                    <FormikNumberField
                                        field={{
                                            name: 'seatCount',
                                            label: `Seat Count ${(selectedSite?.maxSeats !== null && selectedSite?.maxSeats !== undefined) || selectedTopic.maxSeatCount !== null ? `(${selectedSite ? `site max ${selectedSite?.maxSeats?.toLocaleString()}` : ''}${selectedSite?.maxSeats !== null && selectedSite?.maxSeats !== undefined && selectedTopic.maxSeatCount !== null ? ', ' : ''}${selectedTopic.maxSeatCount !== null ? `topic max ${selectedTopic.maxSeatCount.toLocaleString()}` : ''})` : ''}`,
                                            disabled: Boolean(!selectedSite || _isExistingSeries || readonly),
                                            labelTooltip: _isExistingSeries ? 'Edit at the Event Content level.' : "'site max' = The suggested maximum seat count at the selected site.<br />'topic max' = The suggested maximum number of attendees for the selected topic.<br />Both are suggestions. You may specify any seat count."
                                        }}
                                    />
                                </div>
                                <div style={{ flex: 1 }} className='mr-4'>
                                    <CurrencyField 
                                        disabled={Boolean(!selectedSite || _isExistingSeries || readonly)}
                                        fieldProps={{ 
                                            name: 'seatCost', 
                                            label: 'Seat Cost', 
                                            labeltooltip: _isExistingSeries ? 'Edit at the Event Content level.' : '' }} />
                                </div>
                                <div style={{ flex: 1 }}>
                                    <FormikSelectField
                                        field={{
                                            name: 'maxSeatsPerOrg', 
                                            label: 'Max Seats Per Org', 
                                            disabled: Boolean(!selectedSite || _isExistingSeries || readonly),
                                            labelTooltip: _isExistingSeries ? 'Edit at the Event Content level.' : 'Would you like to limit the number of seats that a single org can reserve for this event?'}}
                                        options={getMaxSeatsOptions()}
                                    />
                                </div>
                            </div>

                            <div style={{ display: 'flex' }}>
                                <div style={{ flex: 1 }} className='mr-4'>
                                { 
                                        /* 20230802 TB - invitationOnly can be set upon first creation. However, if this turns out to be a series OR the EventContent record's invitationOnly value == TRUE,
                                         invitationOnly must be controlled via the EventContent UI form, since all sibling events must have the same invitationOnly. */
                                        (!!eventToEdit?.eventContentInvitationOnly || _isExistingSeries) ?
                                            <FormikTextField field={{ name: 'fooInvitationOnly', label: 'Which MI Ministries can register?', disabled: true }} labelTooltip={getDisabledInivtationOnlyTooltip()} />
                                            :
                                            <FormikSelectField
                                                field={{ name: 'invitationOnly', label: 'Which MI Ministries can register?', disabled: (readonly || _isExistingSeries), labelTooltip: "If 'Invitation Only', this event will only be accessible to those whom you send the 'Registration Page URL' to." }}
                                                options={[
                                                    { value: 'any', label: 'Any MI Ministry' },
                                                    { value: 'invitation', label: 'By Invitation Only' }
                                                ]}
                                            />
                                    }
                                </div>
                                <div style={{ flex: 1 }} className='mr-4'>
                                    {editingStatus === GroupCoachingFormSummaryStatus.AddingEventContentAndEvent && selectedTopic.guestsAllowed && availableAccountLevels.length === accountLevelIds.length &&
                                        <CheckboxField
                                            disabled={readonly || Boolean((values.allowedAccountLevelIds || []).length !== accountLevelIds.length)}
                                            fieldProps={{ name: 'bAllowPublicRegistration', label: 'Allow Public Registration (Guests)', labeltooltip: "Allows users with no Ministry Portal account to register for the event. You must allow all account levels to allow public registrations." }}
                                        />
                                    }
                                </div>
                                <div style={{ flex: 2 }} className='mr-4'>

                                </div>
                            </div>

                            <TinyMceField disabled={readonly} fieldProps={{ 
                                name: 'publicEventDetails', 
                                label: 'Public Event Details',
                                labeltooltip: "Visible to anyone looking at the event's details." }} />
                            <TinyMceField disabled={readonly} fieldProps={{ 
                                name: 'privateEventDetails', 
                                label: 'Private Event Details', 
                                labeltooltip: "Only shared with users after they register. Useful for sharing video conferencing information (e.g., Zoom link)." }} />

                            <div className='mt-4' style={{ display: 'flex' }}>
                                {(() => {
                                    if (duplicateEventContent || !eventToEdit) return <button className='btn btn-primary mr-2' type='submit' style={{ minWidth: 100 }}>Save</button>

                                    switch (eventToEdit.status) {
                                        case GroupCoachingEventStatus.activated:
                                            return (
                                                <>
                                                    <button className='btn btn-primary mr-2' type='submit' style={{ minWidth: 100 }}>Save</button>
                                                    <button
                                                        className='btn btn-warning mr-2'
                                                        style={{ minWidth: 100 }}
                                                        onClick={() => showHideCancelEventModal(true)}
                                                        type='button'
                                                        disabled={readonly}
                                                    >
                                                        Cancel Event
                                                    </button>
                                                    <button
                                                        className='btn btn-secondary mr-2'
                                                        style={{ minWidth: 100 }}
                                                        onClick={() => showHideAddToCalendarModal(true)}
                                                        type='button'
                                                        disabled={readonly}
                                                    >
                                                        Add To My Calendar
                                                    </button>
                                                    <EventAddToMyCalendar 
                                                        modalProps={addToCalendarModal}
                                                        eventContentTitle={eventToEdit.title}
                                                        eventId={eventToEdit.eventId}
                                                        startTime={dayjs(eventToEdit.startDateTime?.toString()).format('MM/DD/YYYY hh:mm a')}
                                                        timezone={eventToEdit.timezoneGeneric || ''} />
                                                </>
                                            )
                                        case GroupCoachingEventStatus.canceled:
                                            return <></>
                                            /* return <button className='btn btn-secondary mr-2' style={{ minWidth: 100 }} onClick={closeModal}>Close</button> */
                                        case GroupCoachingEventStatus.deleted:
                                            return null
                                        case GroupCoachingEventStatus.draft:
                                            return (
                                                <>
                                                    <button className='btn btn-primary mr-2' type='submit' style={{ minWidth: 100 }}>Save</button>
                                                    <button
                                                        className='btn btn-primary mr-2'
                                                        style={{ minWidth: 100 }}
                                                        type='button'
                                                        onClick={() => {
                                                            setFieldValue('activatePressed', true)

                                                            /* Hack to wait for new value to be applied
                                                             Pending https://github.com/jaredpalmer/formik/issues/529 */
                                                            setTimeout(() => {
                                                                submitForm()
                                                            })
                                                        }}
                                                    >
                                                        Activate Event
                                                    </button>
                                                </>
                                            )
                                    }
                                })()}
                            </div>
                        </Form>
                    )
                }}
            />
            {eventToEdit && eventToEdit.status === GroupCoachingEventStatus.activated &&
                <EventFormCancel 
                modalProps={cancelEventModal}
                eventToEdit={eventToEdit}
                setEventToEdit={setEventToEdit} />
            }
        </>
    )
}

// interface IOnSitesChangeProps {
//     setFieldValue: (field: string, value: any) => void
//     sites: IEventSiteModelDocument[]
//     eventToEdit?: IGroupCoachingModelDocument
// }
// const OnSitesChange = ({ sites, setFieldValue, eventToEdit }: IOnSitesChangeProps) => {
//     useEffect(() => {
//         // Hack to make sure maxSeatOffset setting is triggered after an event save
//         setTimeout(() => {
//             setFieldValue('maxSeatOffset',
//                 (() => {
//                     const eventSite = sites.find(o => o.siteId.toString() === eventToEdit?.siteId?.toString())
//                     if (eventSite && eventToEdit && eventSite.maxSeats !== null && eventToEdit.maxSeatOffset !== null) {
//                         return (eventToEdit.maxSeatOffset + eventSite.maxSeats).toString()
//                     }

//                     return ''
//                 })()
//             )
//         }, 100)
//     }, [sites, eventToEdit])
//     return null
// }

// const CancelEventModal = ({ modalProps, eventContentId, afterSave }: { modalProps: ReturnType<typeof useModal>[0], eventContentId: number, afterSave?: () => void }) => {
//     const { EventMgmtApi } = useContext(AppActionContext)!

//     return (
//         <Formik
//             initialValues={{
//                 sendCancellationEmail: false,
//                 emailContent: '',
//             }}
//             onSubmit={async (values) => {
//                 await EventMgmtApi.apiEventMgmtEventEventIdCancelPut(eventContentId)
//                 if (values.sendCancellationEmail) {
//                     await EventMgmtApi.apiEventMgmtEventEventIdSendCancellationNotificationsPost(eventContentId, { reasonMessage: values.emailContent })
//                 }
//                 afterSave && afterSave()
//                 modalProps.closeModal()
//             }}
//         >
//             {formikProps => (
//                 <Modal
//                     {...modalProps}
//                     modalTitle='Cancel Event'
//                     footer={<button className='btn btn-danger' onClick={formikProps.submitForm}>Cancel Event</button>}
//                 >
//                     <Form>
//                         <CheckboxField fieldProps={{ name: 'sendCancellationEmail', label: 'Send a cancellation email to all registrants?' }} />
//                         {formikProps.values.sendCancellationEmail && <TinyMceField fieldProps={{ name: 'emailContent', label: 'Reason Message (optional)' }} />}
//                     </Form>
//                 </Modal>
//             )}
//         </Formik >
//     )
// }

// TB 20230713 - To improve performance, no longer pulling registrants for the summary page,
// so no longer displaying the RegistrantsSummary component. Let's see if any of our end 
// users notice.
/* const RegistrantsSummary = ({ registrants, setActiveTab }: { registrants: IGroupCoachingRegistrantDocument[], setActiveTab: (tabId: string) => void }) => {
    return (
        <div style={{ backgroundColor: 'rgb(248, 249, 250)', padding: '5px', marginTop: '1px' }}>
            <div className='m-2'>
                {sortListByProperty(registrants.filter(o => o.bActive).map(o => ({ ...o, regDate: o.regDate ? new Date(o.regDate).getTime() : null })), 'regDate', 'DESC').slice(0, 10).map(registrant =>
                    <div key={registrant.seminarAttendeeId}>
                        <Link target='_blank' to={registrant.ministryId ? `/ministry-info/${registrant.ministryId}` : `/prospects/${registrant.prospectId}`}>{registrant.organizationName}</Link>
                        <span> - </span>
                        {registrant.fullName} {registrant.email && <> - <a href={`mailto:${registrant.email}`}>{registrant.email}</a></>}{registrant.businessPhone && ` - ${registrant.businessPhone}`}
                    </div>
                )}
            </div>

            {registrants.length > 10 && <a href='#' className='m-2' onClick={() => setActiveTab('registrants')}>More...</a>}
        </div>
    )
} */