import React, { useEffect } from 'react'
import { IDefaultProps } from './component-definitions'
import { Helmet } from 'react-helmet'
import { LoadingOverlay, LoadingPropsSizeEnum } from './loading'
import { showTab } from '../services/helpers'
import { ReactComponent as PersonIcon } from '../assets/person.svg'
import { ContactInfoTab, IContactInfoState } from '../stores/contact-info-definitions'
import { IContactInfoActions, useContactInfoActions } from '../stores/contact-info-actions'
import { contactInfoReducer } from '../stores/contact-info-reducer'
import { ContactInfoGeneral } from './contact-info-general'
import { ContactInfoCourses } from './contact-info-courses'
import { Link } from '@reach/router'

interface IContactInfoProps extends IDefaultProps {
	contactId?: string
}

export const ContactInfoContext = React.createContext<{
	actions: IContactInfoActions
	state: IContactInfoState
} | null>(null)

export const ContactInfo = (props: IContactInfoProps) => {
	const contactId = props.contactId!

	const viewTab = (tabId: ContactInfoTab) => {
		showTab(tabId)
		actions.setActiveTab(tabId)
	}

	const initialContactInfoState: IContactInfoState = {
		loading: false,
		activeTab: 'general-tab',
	}

	const [state, actions] = useContactInfoActions(contactInfoReducer, initialContactInfoState)

	/* 
		Whenever the contactId prop changes, go ahead and change the contact ID in the ContactInfoState.
		This will allow us to "navigate" to a new contact URL without reloading the whole app. When we do, the contactId prop will change.
		When the prop changes, we'll update the contactId stored and used by ContactInfoState to fetch all the data for the Contact.
	*/
	useEffect(() => {
		if (!contactId) return
		
		actions.setContactId(parseInt(contactId))
		actions.fetchContact(parseInt(contactId))
		actions.fetchContactCourses(parseInt(contactId))
		//eslint-disable-next-line
	}, [contactId])

	useEffect(() => {
		if (!state.contact) return

		actions.fetchMinistry()
	}, [state.contact])

	return (
		<ContactInfoContext.Provider value={{ actions, state }}>
			<div id='contact-info'>
				{state.contact ?
					<React.Fragment>
						<Helmet>
							<title>{state.contact.fullName}</title>
						</Helmet>
						<nav aria-label="breadcrumb">
							<ol className="breadcrumb">
								<li className="breadcrumb-item"><Link to={`/ministry-info/${state.contact.ministryId}`}>Ministry</Link></li>
								<li className="breadcrumb-item"><Link to={`${state.ministry?.bArchived === true ? `/archived-contacts` : `/contacts`}?ministryId=${state.contact.ministryId}`}>Contacts</Link></li>
								<li className="breadcrumb-item active" aria-current="page">Contact Detail</li>
							</ol>
						</nav>
						<div className='m-2 d-flex align-items-center'>
							<PersonIcon style={{ width: '25px', height: '25px' }} />
							<div className='d-flex'>
								<h3 className='ml-2'>{state.contact.fullName} ({state.ministry?.ministryName})
								{state.ministry?.bArchived === true ? <span className='badge badge-warning ml-2'>Archived</span> : null}</h3>
							</div>
						</div>

						<ul className='nav nav-tabs' role='tablist'>
							<li className={`nav-item`}>
								<a className={`nav-link ${state.activeTab === 'general-tab' ? 'active' : ''}`} id='general-tab' onClick={() => viewTab('general-tab')} href='#general' role='tab' aria-controls='general' aria-selected='true'>General</a>
							</li>
							<li className={`nav-item`}>
								<a className={`nav-link ${state.activeTab === 'courses-tab' ? 'active' : ''}`} id='courses-tab' onClick={() => viewTab('courses-tab')} href='#courses' role='tab' aria-controls='courses' aria-selected='false'>Courses</a>
							</li>
						</ul>

						<div className='tab-content' style={{ overflow: 'auto' }}>
							<div className='tab-pane fade show active flex-fill' id='general' role='tabpanel' aria-labelledby='general-tab'>
								{state.activeTab === 'general-tab' ?
									<ContactInfoGeneral />
									:
									null
								}
							</div>
							<div style={state.activeTab === 'courses-tab' ? { height: '100%' } : {}} className='tab-pane fade' id='courses' role='tabpanel' aria-labelledby='courses-tab'>
								{state.activeTab === 'courses-tab' ?
									<ContactInfoCourses />
									:
									null
								}
							</div>
						</div>
					</React.Fragment>
					:
					null
				}
				{state.loading ? <LoadingOverlay size={LoadingPropsSizeEnum.medium} position='fixed' /> : null}
			</div>
		</ContactInfoContext.Provider>
	)
}