import React, { useContext, useEffect, useState } from 'react'
import { AppActionContext, AppStateContext } from '../app-store-provider'
import { UserModel, UserPermissionLevel } from '../models/user'
import { ITab, Tabs } from './tabs'
import { IMiBranchModelDocument } from '../open-api'
import { CommunityProperties } from './community-properties'
import { CommunityGoals } from './community-goals'
import { CommunityTrainingAreas } from './community-training-areas'
import { IDefaultProps } from './component-definitions'
import { Loading } from './loading'
import { useHTTPRequestUiWrapper } from '../services/hooks'
import { ReactComponent as PeopleIcon } from '../assets/people.svg'
import { Helmet } from 'react-helmet'
import { Link } from '@reach/router'

interface ICommunityFormProps extends IDefaultProps {
    onCommunitySave?: (community?: IMiBranchModelDocument) => Promise<void>
    communityId?: string
}
export const CommunityDetail = (props: ICommunityFormProps) => {
    const { onCommunitySave, communityId } = props

    const appState = useContext(AppStateContext)!
    const appActions = useContext(AppActionContext)!
    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    const [communityToEdit, setCommunityToEdit] = useState<IMiBranchModelDocument>()

    const fetchCommunity = async () => {
        if (!!communityId) {
            const communityQuery = await makeHttpRequestWithUi({
                request: appActions.MiBranchesApi.apiMiBranchesIdGet(parseInt(communityId)),
                disableSuccessToast: true,
                toastErrorMessage: 'There was an error retrieving the community.'
            })

            setCommunityToEdit(communityQuery.data)
        }
    }

    useEffect(() => {
        fetchCommunity()

        //eslint-disable-next-line
    }, [communityId])


    // If we are creating a community, show only the CommunityFormProperties tab and include the onCommunitySave callback.
    if (!communityId) return <CommunityProperties onCommunitySave={onCommunitySave} />

    // If we are editing a community, return loading until the community has been fetched.
    if (!communityToEdit) return <Loading />

    const tabs: ITab<string>[] = [
        {
            id: 'community-form-properties-tab',
            title: 'Community Properties',
            content: <CommunityProperties communityToEdit={communityToEdit} onCommunitySave={() => fetchCommunity()} />
        },
        {
            id: 'community-details-training-areas-tab',
            title: 'Training Areas',
            content: <CommunityTrainingAreas community={communityToEdit} />
        }
    ]

    // Admin\modules\BranchAdmin\grantGoals.js line 153
    if (UserModel.checkPermissionLevelForUser(12, UserPermissionLevel.Administrator, appState.currentUser)) {
        tabs.push({
            id: 'community-form-goals-tab',
            title: 'Community Goals',
            content: <CommunityGoals community={communityToEdit} />
        })
    }

    return <div className='bg-light' style={{ height: '100vh', position: 'relative', display: 'flex', flexDirection: 'column' }}>
        <Helmet>
            <title>{communityToEdit.branchAbbr} - {communityToEdit.branchName}</title>
        </Helmet>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to='/communities'>Communities</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Community Detail</li>
            </ol>
        </nav>
        <div className='p-2 d-flex align-items-center'>
            <PeopleIcon style={{ width: '25px', height: '25px' }} />
            <h3 className='ml-2'>{communityToEdit.branchAbbr} - {communityToEdit.branchName}</h3>
            {/* <div className='ml-2'>
					{appState.currentUserSessionState?.favorites?.prospects?.find(p => p.prospectId === prospect.prospectId) ?
						<StarFillIcon
							style={{ cursor: 'pointer' }}
							className='text-warning' // Bootstrap's 'warning' color makes a good gold star color...
							onClick={async () => {
								const taskId = registerLoadingTask()
								await appActions.UserSessionActions.removeProspectFromCurrentUserFavorites(prospect)
								deregisterLoadingTask(taskId)
							}}
						/>
						:
						<StarIcon
							style={{ cursor: 'pointer' }}
							onClick={async () => {
								const taskId = registerLoadingTask()
								await appActions.UserSessionActions.addProspectToCurrentUserFavorites(prospect)
								deregisterLoadingTask(taskId)
							}}
						/>
					}
				</div> */}
        </div>
        <Tabs tabs={tabs} keepTabContentInDomWhenNotActive={true} />
    </div>
}