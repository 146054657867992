import { Form, Formik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import { AppActionContext, AppStateContext } from '../app-store-provider'
import { IAppPermissionModelDocument, IAppPermissionsModelDocument, IMiAppsDocument, IMiUserModelDocument, IMiUserPostModelDocument, IMiUserPutModelDocument, IMiUsersDocument } from '../open-api'
import { AppPermissionFieldRow, CheckboxField, CommunitySelectField, EmailField, PasswordField, SelectField, TextField, ToggleField } from './forms'
import * as Yup from 'yup'
import { userPermissionLevelSelectOptions, userReportPermissionLevelSelectOptions } from '../models/user'
import { useHTTPRequestUiWrapper } from '../services/hooks'
import { Tabs } from './tabs'
import { Loading } from './loading'
import { FormikCountrySelectField } from './forms/formik-country-select-field'
import { FormikStateSelectField } from './forms/formik-state-select-field'
import { CANADA_COUNTRY_ID, UNITED_STATES_COUNTRY_ID } from '../constants'
//import { Tooltip } from 'chart.js'

interface IUserFormProps {
    userToEdit?: IMiUserModelDocument
    fromNewBranch?: (userContactForBranch: IMiUserPostModelDocument) => void
    onSaveSuccess?: (newUser?: IMiUsersDocument | null) => Promise<void>
    appPermissions: IMiAppsDocument[]
}
export const UserForm = (props: IUserFormProps) => {
    const { userToEdit, fromNewBranch, onSaveSuccess, appPermissions } = props

    const appActions = useContext(AppActionContext)!
    const appState = useContext(AppStateContext)!
    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    const [adminAppPermissions, setAdminAppPermissions] = useState<IAppPermissionModelDocument[] | null>(null)

    useEffect(() => {
        if (!appState.presenters) appActions.fetchPresenters()

        if (!!userToEdit) {
            makeHttpRequestWithUi({
                request: appActions.MiUser2AppApi.apiUsersIdMiUser2AppGet(userToEdit.userId),
                disableSuccessToast: true,
                toastErrorMessage: 'There was an error retrieving the permissions for this user.',
            })
                .then(results => setAdminAppPermissions(results.data.appPermissions))
        }
        // eslint-disable-next-line
    }, [])

    if (!!userToEdit && !adminAppPermissions) return <Loading />

    const defaultPermissions: { [key: string]: any } = {}

    const initialValues = {
        username: userToEdit?.username || '',
        password: userToEdit?.password || '',
        firstName: userToEdit?.firstName || '',
        lastName: userToEdit?.lastName || '',
        branchId: userToEdit?.branchId?.toString() || '',
        branchList: userToEdit?.branchList?.map(o => o.toString()) || '',
        permissionId: userToEdit?.permissionId.toString() || '',
        email: userToEdit?.email || '',
        phone: userToEdit?.phone || '',
        faxNumber: userToEdit?.faxNumber || '',
        bSuperUser: userToEdit ? userToEdit.bSuperUser : false,
        googleUsername: userToEdit?.googleUsername || '',
        googlePassword: userToEdit?.googlePassword || '',
        reportPermissionId: userToEdit?.reportPermissionId?.toString() || '',
        bDisabled: userToEdit ? userToEdit.bDisabled : false,
        exchangeServer: userToEdit?.exchangeServer || '',
        exchangePassword: userToEdit?.exchangePassword || '',
        exchangeUsername: userToEdit?.exchangeUsername || '',
        presenterId: userToEdit?.presenterId?.toString() || '',
        bCallMinistries: userToEdit ? userToEdit.bCallMinistries : false,
        consultingPhone: userToEdit?.consultingPhone || '',
        bConsultingUseExchange: userToEdit ? userToEdit.bConsultingUseExchange : true,
        regionTitle: userToEdit?.regionTitle || '',
        address: userToEdit?.address || '',
        address2: userToEdit?.address2 || '',
        city: userToEdit?.city || '',
        postalCode: userToEdit?.postalCode || '',
        stateId: userToEdit?.stateId?.toString() || '',
        countryId: userToEdit?.countryId?.toString()  || UNITED_STATES_COUNTRY_ID.toString(),
        stateOther: userToEdit?.stateOther || '',
        bShowScorecard: userToEdit ? userToEdit.bShowScorecard : false,
        bCanImpersonateGrowthTrack: userToEdit ? userToEdit.bCanImpersonateGrowthTrack : false,
        bHandlesCoaching: userToEdit ? userToEdit.bHandlesCoaching : true,
        bHandlesConsulting: userToEdit ? userToEdit.bHandlesConsulting : true,
        permissions: defaultPermissions,
        subjectId: userToEdit?.subjectId || '',
        requestEmailChangeUrl: userToEdit?.requestEmailChangeUrl || '',
    }

    const validationSchema = Yup.object({
        exchangeUsername: Yup.string().required('Required'),
        //password: Yup.string().required('Required'),
        firstName: Yup.string().required('Required'),
        lastName: Yup.string().required('Required'),
        branchId: Yup.lazy(value => {
            if (!!fromNewBranch) {
                return Yup.string()
            } else {
                return Yup.string().required('Required')
            }
        }),
        permissionId: Yup.string().required('Required'),
        email: Yup.string().required('Required').email('Please enter a valid email address.'),
        reportPermissionId: Yup.string().required('Required'),
    })

    const getFormNameForAppPermission = (appPermission: IMiAppsDocument) => `app-permission-${appPermission.appId}`
    const getIdForAppPermissionFromFormName = (appPermissionFormName: string): number => parseInt(appPermissionFormName.split('-')[2])


    /* Set up permissions for editing a user */
    if (!!userToEdit && !!adminAppPermissions) {
        appPermissions.forEach(appPermission => {
            const userPermission = adminAppPermissions?.find(o => o.appId === appPermission.appId)
            initialValues.permissions[getFormNameForAppPermission(appPermission)] = userPermission && userPermission.permissionId !== null ? userPermission.permissionId.toString() : 'default'
        })
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values, actions) => {

                const userDocument: IMiUserPostModelDocument = {
                    username: values.exchangeUsername, // Filling this in with exchangeUsername as temp solution to filling this required field.
                    password: values.password,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    branchId: parseInt(values.branchId),
                    branchList: Array.isArray(values.branchList) ? values.branchList.map(o => parseInt(o)) : null,
                    permissionId: parseInt(values.permissionId),
                    email: values.email,
                    phone: values.phone.length ? values.phone : null,
                    faxNumber: values.faxNumber.length ? values.faxNumber : null,
                    bSuperUser: values.bSuperUser,
                    googleUsername: values.googleUsername.length ? values.googleUsername : null,
                    googlePassword: values.googlePassword.length ? values.googlePassword : null,
                    reportPermissionId: parseInt(values.reportPermissionId),
                    bDisabled: values.bDisabled,
                    exchangeServer: values.exchangeServer.length ? values.exchangeServer : null,
                    exchangePassword: values.exchangePassword.length ? values.exchangePassword : null,
                    exchangeUsername: values.exchangeUsername.length ? values.exchangeUsername : null,
                    presenterId: values.presenterId.length ? parseInt(values.presenterId) : null,
                    bCallMinistries: values.bCallMinistries,
                    consultingPhone: values.consultingPhone.length ? values.consultingPhone : null,
                    bConsultingUseExchange: values.bConsultingUseExchange,
                    regionTitle: values.regionTitle.length ? values.regionTitle : null,
                    address: values.address.length ? values.address : null,
                    address2: values.address2.length ? values.address2 : null,
                    city: values.city.length ? values.city : null,
                    postalCode: values.postalCode.length ? values.postalCode : null,
                    stateId: values.stateId ? parseInt(values.stateId) : null,
                    countryId: values.countryId ? parseInt(values.countryId) : null,
                    stateOther: values.stateOther.length ? values.stateOther : null,
                    bShowScorecard: values.bShowScorecard,
                    bHandlesCoaching: values.bHandlesCoaching,
                    bHandlesConsulting: values.bHandlesConsulting,
                    bBranchHost: false,
                    bCanImpersonateGrowthTrack: values.bCanImpersonateGrowthTrack,
                    
                }

                let newUser: IMiUsersDocument | null = null

                if (!!userToEdit) {
                    const editedUser: IMiUserPutModelDocument = { ...userDocument, userId: userToEdit.userId }

                    const editedUserPermissions: IAppPermissionsModelDocument = {
                        userId: editedUser.userId,
                        // @ts-ignore
                        appPermissions: Object.keys(values.permissions).map(permissionFormName => ({ appId: getIdForAppPermissionFromFormName(permissionFormName), permissionId: values.permissions[permissionFormName] === 'default' ? null : parseInt(values.permissions[permissionFormName]) }))
                    }

                    const promises: Promise<any>[] = [
                        makeHttpRequestWithUi({
                            request: appActions.MiUsersApi.apiMiUsersIdPut(userToEdit.userId, editedUser),
                            toastSuccessMessage: 'Successfully updated user.',
                            toastErrorMessage: 'There was an error updating the user.',
                        }),
                        makeHttpRequestWithUi({
                            request: appActions.MiUser2AppApi.apiMiusersIdAppPermissionsPut(editedUser.userId, editedUserPermissions),
                            toastSuccessMessage: `Successfully updated user's permissions.`,
                            toastErrorMessage: `There was an error updating the user's permissions.`,
                        })
                    ]

                    await Promise.all(promises)
                } else if (!!fromNewBranch) {
                    fromNewBranch({ ...userDocument })
                } else {
                    newUser = (await makeHttpRequestWithUi({
                        request: appActions.MiUsersApi.apiMiUsersPost(userDocument),
                        toastSuccessMessage: 'Successfully created new user.',
                        toastErrorMessage: 'There was an error creating the user.',
                    })).data
                }

                if (!userToEdit && newUser) {
                    alert("You're not done! You must contact Software Development to request that they manually setup this user's SSO account. Until then, this user will be unable to impersonate ministry leaders.")
                }

                if (onSaveSuccess) await onSaveSuccess(newUser)
            }}
        >
            {formikProps => {

                const generalTab = <div className='d-flex'>
                    {/* Left Column */}
                    <div className='d-flex flex-column mr-2' style={{ flex: 1 }}>
                        <div className='d-flex'>
                            <div className='mr-2' style={{ flex: 1 }}><TextField fieldProps={{ name: 'firstName', label: 'First Name' }} /></div>
                            <div style={{ flex: 1 }}><TextField fieldProps={{ name: 'lastName', label: 'Last Name' }} /></div>
                        </div>

                        {!initialValues?.subjectId && (
                            <EmailField fieldProps={{ name: 'email', label: 'Email (must match users SSO credentials to auto connect)', labeltooltip: 'This is the email address they will use to log into the GrowthTrack.' }} />
                        )}

                        {initialValues?.subjectId && (
                            <div>
                                <TextField fieldProps={{ name: 'email', label: 'Email (must match users SSO credentials to auto connect)', labeltooltip: 'This is the email address they will use to log into the GrowthTrack.' }} disabled={true} />
                                <a type='button' className='btn btn-primary w-50 mb-3' href={`${initialValues?.requestEmailChangeUrl}`} target='_blank'>Request change of email</a>
                            </div>
                        )}

                        
                        <div className='d-flex'>
                            <div className='mr-2' style={{ flex: 1 }}><TextField fieldProps={{ name: 'phone', label: 'Phone Number', labeltooltip: `This number displays on the website "Contact Us" page.` }} /></div>
                            <div className='mr-2' style={{ minWidth: 196 }}><TextField fieldProps={{ name: 'consultingPhone', label: 'Consulting Phone Number', labeltooltip: `This number will only display on consulting confirmation emails.` }} /></div>
                            <div style={{ flex: 1 }}><TextField fieldProps={{ name: 'faxNumber', label: 'Fax Number' }} /></div>
                        </div>

                        <TextField fieldProps={{ name: 'regionTitle', label: 'Region Title', labeltooltip: `This will be displayed on the public website above the contact information on the chapter listing. Example Region Titles: 'Orange County Area','Los Angeles Area'` }} />

                        <FormikCountrySelectField field={{ name: 'countryId', label: 'Country', placeholder: 'Select a country' }} />

                        <div className='d-flex'>
                            <div className='mr-2' style={{ flex: 1 }}><TextField fieldProps={{ name: 'address', label: 'Address' }} /></div>
                            <div style={{ flex: 1 }}><TextField fieldProps={{ name: 'address2', label: 'Address Line 2' }} /></div>
                        </div>

                        <div className='d-flex'>
                            <div className='mr-2' style={{ flex: 1 }} ><TextField fieldProps={{ name: 'city', label: 'City' }} /></div>
                            {/* <div className='mr-2' style={{ flex: 1 }} ><StateSelectField fieldProps={{ name: 'stateId', label: 'State', placeholder: 'Select a State...' }} /></div> */}
                            <div className='mr-2' style={{ flex: 1 }} >
                                {(formikProps.values.countryId == UNITED_STATES_COUNTRY_ID.toString() || formikProps.values.countryId == CANADA_COUNTRY_ID.toString()) ? 
                                <FormikStateSelectField 
                                field={{ name: 'stateId', label: 'State', placeholder: 'Select a state', disabled: !formikProps.values.countryId }}
                                countryId={parseInt(formikProps.values.countryId)} />
                                : 
                                <TextField 
                                    fieldProps={{ name: 'stateOther', label: 'State/Province' }}  
                                    disabled={!formikProps.values.countryId} /> }
                            </div>
                            <div style={{ flex: 1 }} ><TextField fieldProps={{ name: 'postalCode', label: 'Zip' }} /></div>
                        </div>

                        {/*
                        <div className='d-flex'>
                            <div className='mr-2' style={{ flex: 1 }}><TextField fieldProps={{ name: 'username', label: 'Username', autoComplete: 'new-password', labeltooltip: 'Should be identical to email address.' }} /></div>
                            <div style={{ flex: 1 }}>
                                <TextField fieldProps={{ name: 'password', label: 'Password (MI Admin only)', autoComplete: 'new-password' }} hideInput={true} />
                            </div>
                        </div>
                        */}

                        <ToggleField trueText='Exchange' falseText='Google' fieldProps={{ name: 'bConsultingUseExchange', label: 'Consulting Application (MI Admin Only)' }} hideInput={true} disabled={true} />
                        
                        {formikProps.values.bConsultingUseExchange ?
                            <div className='d-flex'>
                                {/*<div className='mr-2' style={{ flex: 1 }}><TextField fieldProps={{ name: 'exchangeServer', label: 'Exchange Server (MI Admin Only)' }} disabled={true} /></div>*/}
                                <div className='mr-2' style={{ flex: 1 }}><TextField fieldProps={{ name: 'exchangeUsername', label: 'Exchange Username', labeltooltip: 'Should be identical to user\'s email address. Must match their Microsoft credentials.' }} /></div>
                                {/*<div style={{ flex: 1 }}>
                                    <TextField fieldProps={{ name: 'exchangePassword', label: 'Exchange Password (MI Admin Only)', autoComplete: 'new-password' }} disabled={true} hideInput={true} />
                                </div>*/}
                            </div>
                            :
                            <div className='d-flex'>
                                <div className='mr-2' style={{ flex: 1 }}><TextField fieldProps={{ name: 'googleUsername', label: 'Google Username' }} /></div>
                                <div style={{ flex: 1 }}>
                                    {appState.currentUser?.bSuperUser ?
                                        <TextField fieldProps={{ name: 'googlePassword', label: 'Google Password' }} />
                                        :
                                        <PasswordField fieldProps={{ name: 'googlePassword', label: 'Google Password' }} />
                                    }
                                </div>
                            </div>
                        }
                        <CheckboxField fieldProps={{ name: 'bCallMinistries', label: 'I prefer to call ministries for consulting sessions' }} />
                        <CheckboxField fieldProps={{ name: 'bHandlesCoaching', label: 'Handles Coaching Appts' }} />
                        <CheckboxField fieldProps={{ name: 'bHandlesConsulting', label: 'Handles Consulting Appts' }} />
                    </div>

                    {/* Right Column */}
                    {appState.currentUser?.bSuperUser ?
                        <div className='d-flex flex-column ml-2' style={{ flex: 1 }}>
                            {!!fromNewBranch ?
                                null
                                :
                                <React.Fragment>
                                    <CommunitySelectField fieldProps={{ name: 'branchId', label: 'Primary Community', placeholder: 'Select a Community...' }} />
                                    <CommunitySelectField fieldProps={{ name: 'branchList', label: 'Community Access' }} multiple={true} />
                                </React.Fragment>
                            }

                            <SelectField fieldProps={{ name: 'permissionId', label: 'Default Permission (MI Admin)', placeholder: 'Select a Permission...', labelTooltip: 'For the most part, this is ignored by GrowthTrack.' }} options={userPermissionLevelSelectOptions} />
                            <SelectField disabled={!appState.currentUser.bSuperUser} fieldProps={{ name: 'reportPermissionId', label: 'Default Report Permission (MI Admin Only)', placeholder: 'Select a Permission...' }} options={userReportPermissionLevelSelectOptions} />

                            {!!fromNewBranch || !appState.presenters ?
                                null
                                :
                                <SelectField fieldProps={{ name: 'presenterId', label: 'Presenter Record', placeholder: 'Select a Presenter...' }} options={appState.presenters.filter(o => !!o.firstName && !!o.lastName).map(o => ({ value: `${o.presenterId}`, label: `${o.firstName} ${o.lastName}${!!o.title ? `, ${o.title}` : null}` }))} />
                            }

                            {appState.currentUser.bSuperUser ?
                                <React.Fragment>
                                    <CheckboxField fieldProps={{ name: 'bSuperUser', label: 'Super User' }} />
                                    <CheckboxField fieldProps={{ name: 'bDisabled', label: 'Account Disabled' }} />
                                    <CheckboxField fieldProps={{ name: 'bShowScorecard', label: 'Show on Score Card' }} />
                                    <CheckboxField fieldProps={{ name: 'bCanImpersonateGrowthTrack', label: 'Can Impersonate GrowthTrack Users', labeltooltip: `When this is checked GrowthTrack will allow this user to impersonate other GrowthTrack users (Note: they also need permission to impersonate users at the SSO). In general it is recommended to keep this unchecked. If you change this it is recommended for the user to log out and log back in.` }} />
                                </React.Fragment>
                                :
                                null
                            }
                        </div>
                        :
                        null
                    }
                </div>

                const permissionsTab = !!appState.appPermissions && !!userToEdit ?
                    <div className='mt-2'>
                        <p>This is an artifact of MI Admin and, for the most part, is being ignored by the GrowthTrack.</p>
                        <table>
                            <colgroup>
                                <col style={{ width: 150 }}></col>
                                <col style={{ width: 100 }}></col>
                                <col style={{ width: 100 }}></col>
                                <col style={{ width: 100 }}></col>
                                <col style={{ width: 100 }}></col>
                                <col style={{ width: 100 }}></col>
                            </colgroup>
                            <thead>
                                <tr style={{ fontWeight: 700 }}>
                                    <th>Application</th>
                                    <th>Default</th>
                                    <th>No Access</th>
                                    <th>Read</th>
                                    <th>Modify</th>
                                    <th>Admin</th>
                                </tr>
                            </thead>
                            <tbody>
                                {appState.appPermissions.map(appPermission => <AppPermissionFieldRow key={appPermission.appId} fieldProps={{ name: `permissions.${getFormNameForAppPermission(appPermission)}`, label: appPermission.appName }} />)}
                            </tbody>
                        </table>
                    </div>
                    :
                    <Loading />

                return (
                    <Form className='d-flex flex-column' style={{ height: '100%' }}>
                        {!!userToEdit ?
                            <Tabs
                                tabs={[
                                    {
                                        id: 'user-form-general-tab',
                                        title: 'General',
                                        content: generalTab
                                    },
                                    {
                                        id: 'user-form-permissions-tab',
                                        title: 'Permissions',
                                        content: permissionsTab
                                    }
                                ]}
                                keepTabContentInDomWhenNotActive={true}
                            />
                            :
                            generalTab
                        }

                        <div className='d-flex justify-content-center'>
                            <button type='submit' className='btn btn-primary'>Submit</button>
                        </div>

                    </Form>
                )
            }}
        </Formik>
    )
}