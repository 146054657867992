import { StringDictionary, IAppState } from './app-definitions'
import { factory as userFactory, IUserDocument } from '../models/user'
import { IMinistryUpdateDocument } from '../models/ministry'
import { ITagDocument, factory as tagFactory, TagModel, TagType } from '../models/tag'
import { ITag2DataDocument, factory as tagJobOjectFactory, TagJoinObjectModel } from '../models/tag-join-object'
import { updateDocToJsonPatch } from '../services/helpers'
import { AppAction, AppActionType } from './app-reducer'
import { TrainingAreaModel, factory as trainingAreaFactory } from '../models/training-area'
import { MinistryContactModel } from '../models/ministry-contact'
import { MinistryGeneralChartsDataModel } from '../models/ministry-general-charts-data'
import { IMinistrySummaryResponseModelDocument, IOpenApi, getOpenApi } from '../open-api'
import { BASE_PATH } from '../open-api/base'
import { useAuth} from "react-oidc-context";
import { useGetAuthToken } from '../services/hooks'
import { IDP_CLIENT_ID, IDP_AUTHORITY, IDP_REDIRECT_URI } from '../constants'
import { get } from 'jquery'

// TODO: this file needs to be destroyed and all it's usages converted over to the OpenAPI generated API scaffolding

// private variables
// let _authToken: string = ''
// let _decodedAuthToken: StringDictionary<any> = {}

export interface IQueryState {
	page: number
	itemsPerPage: number
	filters?: IFilter[]
	sorts?: ISort[]
}

export interface IMifApiQueryFilterByEventParams {
	registeredFor?: number[]
	onlyIfRegisteredForAll?: boolean
	onlyIfAttended?: boolean
	notRegisteredFor?: number[]
	registeredForConsulting?: string[]
	notRegisteredForConsulting?: string[]
	onlyIfRegisteredForAllConsulting?: boolean
	onlyIfAttendedConsulting?: boolean
}

export interface IMifApiQueryParams {
	Page: number
	Start: number
	Limit: number
	Sort?: string
	Filter?: string
	FilterByEvent?: IMifApiQueryFilterByEventParams | string
}

export type FilterOperator = 'like' | 'in' | 'lt' | 'gt' | 'eq' | '==' | 'not-like' | 'filter-by-event'
export type FilterValues = string | number | boolean | string[]
export interface IEventFilter {
	eventsRegisteredFor?: {
		eventIds: number[]

		/* 
			If set to false, the filter will filter to ministries that registered/attended ANY selected event. 
			If set to true, the filter will filter to ministries that registered/attended ALL selected events.
		*/
		registeredForEverySelectedEvent: boolean

		// Optional setting to limit list to not only ministries that registered, but also attended the selected events.
		onlyIncludeMinistriesThatAttended?: boolean
	}
	eventsNotRegisteredFor?: {
		eventIds: number[]
	}

	consultingRegisteredFor?: {
		// Array of strings, one per year, that include the beggining and ending consulting quarters being filtered by.
		consultingQuarters: string[]

		// Same as eventsRegisteredFor.registeredForEverySelectedEvent
		registeredForEverySelectedQuarter: boolean

		// Same as eventsRegisteredFor.onlyIncludeMinistriesThatAttended
		onlyIncludeMinistriesThatAttended?: boolean
	}
	consultingNotRegisteredFor?: {
		consultingQuarters: string[]
	}
}
export interface IFilter {
	id: string
	property: string
	operator: FilterOperator
	value?: FilterValues
	enabled?: boolean
	hidden?: boolean
	isDateFilter?: boolean

	// This filter (so far) is specific only to the GET /api/ministries endpoint
	filterByEventOptions?: IEventFilter
}

export type FilterType = IStringFilter | INumericFilter | IBooleanFilter | IArrayFilter | IDateFilter
export interface IStringFilter extends IFilter {
	value?: string
}
export interface IDateFilter extends IFilter {
	value?: string
	isDateFilter: boolean
}
export const isDateListFilterType = (object: any): object is IDateFilter => {
	return object && typeof object === 'object' && 'isDateFilter' in object && object.isDateFilter === true
}
export interface INumericFilter extends IFilter {
	value?: number
}
export interface IBooleanFilter extends IFilter {
	value?: boolean
}
export interface IArrayFilter extends IFilter {
	value?: string[]
}

export type SortDirections = 'ASC' | 'DESC'

export interface ISort {
	property: string
	direction: SortDirections
}

// export interface IFetchMinistriesResponsePayload {
// 	data: IMinistry[]
// 	totalCount: number
// }


export interface IMifApi extends IOpenApi {
	testApi: () => any
	logout: () => Promise<void>
	fetchUser: (id?: number) => Promise<IFetchResponse<IUserDocument>>
	// fetchMinistries: (queryState: IQueryState) => Promise<IFetchResponse<IFetchMinistriesResponsePayload>>
	// fetchMinistry: (ministryId: number) => Promise<IFetchResponse<MinistryModel>>
	fetchMinistrySummary: (ministryId: number) => Promise<IFetchResponse<IMinistrySummaryResponseModelDocument>>
	fetchTagsSummary: () => Promise<IFetchResponse<TagModel[]>>
	fetchTagsByType: (type: TagType) => Promise<IFetchResponse<TagModel[]>>
	fetchMinistryTagJoinObjects: (ministryId: number) => Promise<IFetchResponse<ITag2DataDocument[]>>
	addMinistryToTag: (tagId: number, ministryId: number) => Promise<IFetchResponse<ITag2DataDocument>>
	addMinistryContactToTag: (tagId: number, ministryContactId: number) => Promise<IFetchResponse<ITag2DataDocument>>
	removeMinistryFromTag: (tagId: number, ministryId: number) => Promise<IFetchResponse<null>>
	removeMinistryContactFromTag: (tagId: number, ministryContactId: number) => Promise<IFetchResponse<null>>
	createTag: (tagName: string, tableKey: TagType) => Promise<IFetchResponse<ITagDocument>>
	deleteTag: (tagId: number) => Promise<IFetchResponse<void>>
	changeAccountLevel: (ministryId: number, levelId: number) => Promise<IFetchResponse<null>>
	updateMinistry: (ministryId: number, ministryUpdate: IMinistryUpdateDocument) => Promise<IFetchResponse<null>>
	fetchTrainingAreas: () => Promise<IFetchResponse<TrainingAreaModel[]>>
	archiveMinistry: (ministryId: number, archiveReason: string) => Promise<IFetchResponse<null>>
	unarchiveMinistry: (ministryId: number) => Promise<IFetchResponse<null>>
	toggleMinistryBReportMiIncome: (ministryId: number) => Promise<IFetchResponse<null>>
	fetchMinistryContact: (ministryContactId: number) => Promise<IFetchResponse<MinistryContactModel>>
	fetchMinistryContactsForMinistry: (ministryId: number) => Promise<IFetchResponse<MinistryContactModel[]>>
	fetchMinistryGeneralChartsData: (ministryId: number) => Promise<IFetchResponse<MinistryGeneralChartsDataModel>>
	fetchStates: () => Promise<void>
}

export const useApiActions = (dispatch: React.Dispatch<AppAction>, state: IAppState): IMifApi => {

	const auth = useAuth()
	//console.log('AuthToken: ' + auth.user?.access_token.slice(-5));
	const getAuthToken = useGetAuthToken()

	const addEndpoint = useAddEndpoint(getAuthToken)

	const openApi = getOpenApi(getAuthToken)

	// Test API connection
	const testApi = addEndpoint({ url: '/api/values' })

	// User endpoints
	const fetchUserEndpoint = addEndpoint<IUserDocument>({ url: '/api/miusers/{id}', authorize: true })

	// Ministry Endpoints
	// const fetchMinistries = addEndpoint<IFetchMinistriesResponsePayload>({ url: '/api/ministries', authorize: true })
	// const fetchMinistry = addEndpoint<MinistryModel>({ url: '/api/ministries', authorize: true })
	const fetchMinistrySummary = addEndpoint<IMinistrySummaryResponseModelDocument>({ url: '/api/ministries/{ministryId}/summary', authorize: true })
	const fetchTagsSummary = addEndpoint<TagModel[]>({ url: '/api/tags/getsummary', authorize: true })
	// const fetchTagsByType = addEndpoint<TagModel[]>({ url: '/api/tags', authorize: true })
	const fetchMinistryTagJoinObjects = addEndpoint<TagJoinObjectModel[]>({ url: '/api/ministries/{id}/tags', authorize: true })
	const addMinistryToTag = addEndpoint<ITag2DataDocument>({ url: '/api/tag2data', authorize: true })
	const addMinistryContactToTag = addEndpoint<ITag2DataDocument>({ url: '/api/tag2data', authorize: true })
	const removeMinistryFromTag = addEndpoint<null>({ url: '/api/tag2data', authorize: true, method: 'DELETE' })
	const removeMinistryContactFromTag = addEndpoint<null>({ url: '/api/tag2data', authorize: true, method: 'DELETE' })
	const createTag = addEndpoint<ITagDocument>({ url: '/api/tags', authorize: true, method: 'POST' })
	const deleteTag = addEndpoint<void>({ url: '/api/tags/{tagId}', authorize: true, method: 'DELETE' })
	const changeAccountLevel = addEndpoint<null>({ url: '/api/ministries/{ministryId}/changeaccountlevel/{levelId}', authorize: true, method: 'POST' })
	const updateMinistry = addEndpoint<null>({ url: '/api/ministries/{ministryId}', authorize: true, method: 'PATCH' })
	const updateContact = addEndpoint<null>({ url: '/api/MinistryContacts/{contactId}', authorize: true, method: 'PUT' })
	const fetchTrainingAreas = addEndpoint<TrainingAreaModel[]>({ url: '/api/mitrainingareas', authorize: true })
	const archiveMinistry = addEndpoint<null>({ url: '/api/ministries/{ministryId}/archive', authorize: true, method: 'POST' })
	const unarchiveMinistry = addEndpoint<null>({ url: '/api/ministries/{ministryId}/unarchive', authorize: true, method: 'POST' })
	const toggleMinistryBReportMiIncome = addEndpoint<null>({ url: '/api/ministries/{ministryId}/togglebreportmiincome', authorize: true, method: 'PUT' })
	const fetchMinistryContact = addEndpoint<MinistryContactModel>({ url: '/api/ministrycontacts/ministryContactId', authorize: true })
	const fetchMinistryContactsForMinistry = addEndpoint<MinistryContactModel[]>({ url: '/api/ministries/{ministryId}/contacts', authorize: true })
	const fetchMinistryGeneralChartsData = addEndpoint<MinistryGeneralChartsDataModel>({ url: '/api/ministries/{ministryId}/generalchartsdata', authorize: true })


	const api = {
		...openApi,
		testApi: () => {
			const doFetch = testApi

			const promise = doFetch()

			return promise
		},
		logout: async () => {
			auth.signoutRedirect({
				post_logout_redirect_uri: IDP_REDIRECT_URI})
			dispatch({ type: AppActionType.logout })
		},
		fetchUser: (id?: number) => {
			const doFetch = fetchUserEndpoint
			// id = id || (_decodedAuthToken && Number(_decodedAuthToken.id))
			const promise = doFetch({ params: { id } })

			promise.then(result => {
				result.content = result.content && userFactory(result.content)

				return result
			})

			return promise
		},
		// fetchMinistries: (queryState: IQueryState) => {
		// 	const doFetch = fetchMinistries

		// 	const query: IMifApiQueryParams = {
		// 		Page: 0,
		// 		Start: (queryState.page - 1) * queryState.itemsPerPage,
		// 		Limit: queryState.itemsPerPage,
		// 	}

		// 	if (queryState.sorts && queryState.sorts.length) {
		// 		query.Sort = JSON.stringify(queryState.sorts)
		// 	}

		// 	const filters = [...queryState.filters?.filter(f => f.enabled && !isNullOrUndefined(f.value)) || []]

		// 	// Special case for the ministries query: check to see if there is an events filter
		// 	const eventFilter = queryState.filters?.find(f => f.operator === 'filter-by-event')
		// 	if (eventFilter?.enabled && eventFilter.filterByEventOptions) {
		// 		const _eventFilter = { ...eventFilter }

		// 		// We have a valid, enabled event filter - need to add its configuration to the query params.
		// 		const { eventsRegisteredFor, eventsNotRegisteredFor, consultingRegisteredFor, consultingNotRegisteredFor } = _eventFilter.filterByEventOptions!

		// 		const eventFilterQueryParams: IMifApiQueryFilterByEventParams = {}

		// 		if (eventsRegisteredFor) {
		// 			eventFilterQueryParams.registeredFor = eventsRegisteredFor.eventIds
		// 			eventFilterQueryParams.onlyIfRegisteredForAll = eventsRegisteredFor.registeredForEverySelectedEvent
		// 			if (eventsRegisteredFor.onlyIncludeMinistriesThatAttended !== undefined) eventFilterQueryParams.onlyIfAttended = eventsRegisteredFor.onlyIncludeMinistriesThatAttended
		// 		}

		// 		if (eventsNotRegisteredFor) eventFilterQueryParams.notRegisteredFor = eventsNotRegisteredFor.eventIds

		// 		if (consultingRegisteredFor) {
		// 			eventFilterQueryParams.registeredForConsulting = consultingRegisteredFor.consultingQuarters
		// 			eventFilterQueryParams.onlyIfRegisteredForAllConsulting = consultingRegisteredFor.registeredForEverySelectedQuarter
		// 			if (consultingRegisteredFor.onlyIncludeMinistriesThatAttended !== undefined) eventFilterQueryParams.onlyIfAttended = consultingRegisteredFor.onlyIncludeMinistriesThatAttended
		// 		}

		// 		if (consultingNotRegisteredFor) eventFilterQueryParams.notRegisteredForConsulting = consultingNotRegisteredFor.consultingQuarters

		// 		query.FilterByEvent = JSON.stringify(eventFilterQueryParams)
		// 	}

		// 	/*
		// 		This filter gets passed as separate options to the endpoint.

		// 		The endpoint's 'filter' param does not support this filter -
		// 		it's stored in the same interface as other filters on the frontend to facilitate easy of integration with grid queries based on the QueryState interface.

		// 		We need to not include the events filter in the general filters list.
		// 	*/
		// 	const _eventFiltersToRemove = filters.filter(f => f.operator === 'filter-by-event')
		// 	_eventFiltersToRemove.forEach(f => {
		// 		const idx = filters.indexOf(f)
		// 		filters.splice(idx, 1)
		// 	})

		// 	if (filters && filters.length) {
		// 		query.Filter = JSON.stringify(filters)
		// 	}

		// 	const promise = doFetch({ query })

		// 	promise.then(result => {
		// 		return result.content.data = result.content.data ? result.content.data.map(o => ministryFactory(o)) : []
		// 	})

		// 	return promise
		// },
		// fetchMinistry: (ministryId: number) => {
		// 	const doFetch = fetchMinistry

		// 	const query: IMifApiQueryParams = {
		// 		Page: 0,
		// 		Start: 0,
		// 		Limit: 1,
		// 	}

		// 	query.Filter = JSON.stringify([{ 'operator': 'eq', 'value': ministryId, 'property': 'ministryId' }])

		// 	const promise = doFetch({ query })

		// 	promise.then(result => {
		// 		const content = result.content as unknown as IFetchMinistriesResponsePayload
		// 		if (content.data.length === 1) {
		// 			return result.content = content.data.map(o => ministryFactory(o))[0]
		// 		} else {
		// 			throw new Error(`Unexpected number of ministries returned when attempting to fetch a single ministry. Ministry ID: ${ministryId}`)
		// 		}
		// 	})

		// 	return promise
		// },
		fetchMinistrySummary: (ministryId: number) => {
			const doFetch = fetchMinistrySummary

			const params = {
				ministryId
			}

			const promise = doFetch({ params })

			return promise
		},
		fetchTagsByType: (type: TagType) => {
			if (state.tags) {
				return wrapFetch(state.tags.filter(tag => tag.tableKey === type))
			} else {
				const doFetch = fetchTagsSummary

				const promise = doFetch({ query: state.globalCommunityContext ? { contextBranchId: state.globalCommunityContext.branchId } : undefined })

				promise.then(result => {
					const tags = result.content.map(o => tagFactory(o))
					dispatch({ type: AppActionType.setTags, payload: tags })
					return result.content = tags.filter(tag => tag.tableKey === type)
				})

				return promise
			}
		},
		fetchTagsSummary: () => {
			const doFetch = fetchTagsSummary

			const promise = doFetch({
				query: state.globalCommunityContext ? { BranchId: state.globalCommunityContext.branchId } : undefined,
			})

			promise.then(result => {
				const tags = result.content.map(o => tagFactory(o))
				dispatch({ type: AppActionType.setTags, payload: tags })
				return result.content = tags
			})

			return promise
		},
		fetchMinistryTagJoinObjects: (ministryId: number) => {
			const doFetch = fetchMinistryTagJoinObjects

			const query = {
				branchId: state.globalCommunityContext ? state.globalCommunityContext.branchId : ''
			}

			const params = {
				id: ministryId
			}

			const promise = doFetch({ params, query })

			promise.then(result => {
				return result.content = result.content ? result.content.map(o => tagJobOjectFactory(o)) : []
			})

			return promise
		},
		addMinistryToTag: (tagId: number, ministryId: number) => {
			const doFetch = addMinistryToTag

			const data = { tagId, data: ministryId }

			const promise = doFetch({ data })

			promise.then(result => {
				return result.content
			})

			return promise
		},
		addMinistryContactToTag: (tagId: number, ministryContactId: number) => {
			const doFetch = addMinistryContactToTag

			const data = { tagId, data: ministryContactId }

			const promise = doFetch({ data })

			promise.then(result => {
				return result.content
			})

			return promise
		},
		removeMinistryFromTag: (tagId: number, ministryId: number) => {
			const doFetch = removeMinistryFromTag

			const query = { TagId: tagId, Data: ministryId }

			const promise = doFetch({ query })

			promise.then(result => {
				return result.content
			})

			return promise
		},
		removeMinistryContactFromTag: (tagId: number, ministryContactId: number) => {
			const doFetch = removeMinistryContactFromTag

			const query = { TagId: tagId, Data: ministryContactId }

			const promise = doFetch({ query })

			promise.then(result => {
				return result.content
			})

			return promise
		},
		createTag: (tagName: string, tableKey: string) => {
			const doFetch = createTag

			const data: { [key: string]: any } = { tag: tagName, tableKey }

			if (state.globalCommunityContext) data['branchId'] = state.globalCommunityContext.branchId

			const promise = doFetch({ data })

			promise.then(result => {
				return result.content = tagFactory(result.content)
			})

			return promise
		},
		deleteTag: (tagId: number) => {
			const doFetch = deleteTag

			const params = { tagId }

			const promise = doFetch({ params })

			return promise
		},
		changeAccountLevel: (ministryId: number, levelId: number) => {
			const doFetch = changeAccountLevel

			const params = { ministryId, levelId }

			const promise = doFetch({ params })

			return promise
		},
		updateMinistry: (ministryId: number, ministryUpdate: IMinistryUpdateDocument) => {
			const doFetch = updateMinistry

			const data = updateDocToJsonPatch(ministryUpdate)
			const params = { ministryId: ministryId }

			const promise = doFetch({ data, params })

			return promise
		},
		fetchTrainingAreas: () => {
			if (state.trainingAreas) {
				return wrapFetch(state.trainingAreas)
			} else {
				const doFetch = fetchTrainingAreas

				const promise = doFetch()

				promise.then(result => {
					const trainingAreas = result.content.map(o => trainingAreaFactory(o))
					dispatch({ type: AppActionType.setTrainingAreas, payload: trainingAreas })
					return result.content = trainingAreas
				})

				return promise
			}
		},
		fetchStates: async () => {
			const states = await openApi.StatesApi.apiStatesGet()
			dispatch({ type: AppActionType.setStates, payload: states.data })
		},
		archiveMinistry: (ministryId: number, archiveReason: string) => {
			const doFetch = archiveMinistry

			const data = { archiveReason }
			const params = { ministryId: ministryId }

			const promise = doFetch({ data, params })

			return promise
		},
		unarchiveMinistry: (ministryId: number) => {
			const doFetch = unarchiveMinistry

			const params = { ministryId: ministryId }

			const promise = doFetch({ params })

			return promise
		},
		toggleMinistryBReportMiIncome: (ministryId: number) => {
			const doFetch = toggleMinistryBReportMiIncome

			const params = { ministryId: ministryId }

			const promise = doFetch({ params })

			return promise
		},
		fetchMinistryContact: (ministryContactId: number) => {
			const doFetch = fetchMinistryContact

			const params = { ministryContactId: ministryContactId }

			const promise = doFetch({ params })

			return promise
		},
		fetchMinistryContactsForMinistry: (ministryId: number) => {
			const doFetch = fetchMinistryContactsForMinistry

			const params = { ministryId: ministryId }

			const promise = doFetch({ params })

			return promise
		},
		fetchMinistryGeneralChartsData: (ministryId: number) => {
			const doFetch = fetchMinistryGeneralChartsData

			const params = { ministryId: ministryId }

			const promise = doFetch({ params })

			return promise
		},
	}

	return api
}

async function wrapFetch<TContent>(content: TContent): Promise<IFetchResponse<TContent>> {
	return { status: 200, content, statusText: 'Success' }
}

const useAddEndpoint = (getAuthToken:any) => {
	//const auth = useAuth()
	//const getAuthToken = useGetAuthToken(auth.user)
	//const getAuthToken = useGetAuthToken()

	function addEndpoint<TContent>(baseRequest: ApiRequest) {
		return buildfetch<TContent>(baseRequest)
	}

	// IFetchResponse < TContent >
	function buildfetch<TContent>(request: ApiRequest) {
		const fetch = async (args?: FetchArgs) => {
			return await _doFetch<TContent>({ ...request, ...args })
		}

		return fetch
	}

	const _inflight: StringDictionary<any> = {}
	async function _doFetch<TResponsePayload>(request: ApiRequest): Promise<IFetchResponse<TResponsePayload>> {
		const signature = _buildRequestSignature(request)
		let responsePromise = _inflight[signature] as Promise<IFetchResponse<TResponsePayload>>

		if (!responsePromise) {
			const headers = await _getHeaders(!!request.authorize)
			const url = _fillParams(request.url, request.params)
			const query = _buildQuery(request.query)

			const fetchUrl = BASE_PATH + url + query

			_inflight[signature] = responsePromise = fetch(fetchUrl, {
				method: request.method || (request.data ? 'POST' : 'GET'),
				body: request.data && JSON.stringify(request.data),
				headers,
			})
				.then(async response => {
					if (response.ok) {
						let json = {}

						try {
							json = response.status === 204 ? {} : await response.json()
						} catch (e) { }

						// const refreshToken = response.headers.get('token-refresh')

						// if (refreshToken) {
						// 	_setAuthToken(refreshToken)
						// }
						return { status: response.status, content: json }
					} else {
						try {
							const error = await response.json()

							return { status: response.status, error, content: {} }

						} catch (err) {
							return { status: response.status, error: { type: "", title: "Fetch Response Error" }, content: {} }
						}
					}
				})
				.catch(response => {
					const error = { status: -1, error: { type: "", title: "Fetch Error" } }
					console.log(response)

					return error
				})
				.finally(() => {
					_inflight[signature] = undefined
				}) as Promise<IFetchResponse<TResponsePayload>>
		}

		return responsePromise
	}

	function _buildRequestSignature(apiRequest: ApiRequest) {
		let result = apiRequest.url

		apiRequest.method = apiRequest.method || (apiRequest.data ? 'POST' : 'GET')
		apiRequest.authorize = typeof apiRequest.authorize === 'undefined' ? true : apiRequest.authorize

		result += apiRequest.method
		result += apiRequest.authorize.toString()

		if (apiRequest.params) {
			result += JSON.stringify(apiRequest.params)
		}

		if (apiRequest.query) {
			result += JSON.stringify(apiRequest.query)
		}

		if (apiRequest.data) {
			result += JSON.stringify(apiRequest.data)
		}

		return result
	}

	function _fillParams(url: string, params?: StringDictionary<any>) {
		let result = url

		if (params) {
			for (const key in params) {
				const val = params[key].toString()

				result = result.replace(`{${key}}`, val)
			}
		}

		return result
	}

	function _buildQuery(query?: StringDictionary<any>) {
		let parts = []

		if (query) {
			for (const key in query) {
				const val = encodeURIComponent(query[key].toString())

				parts.push(`${key}=${val}`)
			}
		}

		return parts.length ? `?${parts.join('&')}` : ''
	}

	const _getHeaders = async (authorize: boolean) => {
		const headers: StringDictionary<string> = {
			'Content-Type': 'application/json',
		}

		if (authorize) {
			try {
				const authToken = await getAuthToken()
				if (authToken) {
					headers.Authorization = `Bearer ${authToken}`
				}
			} catch (e) {
				// console.log(e)
			}
		}

		return headers
	}

	return addEndpoint
}




interface IFetchError {
	type?: string,
	title: string,
	errors: []
}

export interface IFetchResponse<TContent> {
	status: number,
	error?: IFetchError,
	content: TContent
	statusText: string
}

interface FetchArgs {
	data?: StringDictionary<any>,
	params?: StringDictionary<any>,
	query?: StringDictionary<any>
};

interface ApiRequest extends FetchArgs {
	url: string,
	method?: string,
	authorize?: boolean
};

