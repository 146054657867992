import { useState } from "react"
import { Popup } from "./popup"
import { ChevronDownIcon } from "../assets"
import { FormikProps } from "formik"
import { FormikTextField } from "./forms/formik-text-field"
import { CheckboxField } from "./forms"
import { FormikSelectField } from "./forms/formik-select-field"
import { ORGANIZATION_SERVE } from "../constants"
import clsx from "clsx"

interface IMapMorePopperProps {
    formikProps: FormikProps<any>
}

export const MapMorePopper = (props: IMapMorePopperProps) => {
    const { formikProps } = props
    const [open, setIsOpen] = useState(false)
    // const [popperState, setPopperState] = useState<any>()

  //   useEffect(() => {
  //     if (formikProps.includeActive)
  // }, [formikProps.values])

    return (
      <Popup
        trigger={
          <button
            className={
              'btn btn-outline-secondary' +
              clsx(formikProps.values.invMin > 0 || formikProps.values.invMax > 0 || formikProps.values.organizationServeId?.length > 0 ? ' active' : ' ')
            }
            type='button'
          >
           More <ChevronDownIcon />
          </button>
        }
        onToggle={setIsOpen}  
        popupOptions={{
          placement: 'bottom-start',
          modifiers: [
              {
                  name: 'offset',
                  options: {
                      offset: [0, 5]
                  }
              }
          ]
        }}
      >
        <div
            className="p-3"
          style={{
            backgroundColor: 'white',
            border: '1px solid gray',
            borderRadius: '.25rem',
            width: '400px',
            overflow: 'auto',
            //maxHeight: '300px',
            overflowX: 'hidden'
          }}
        >
          <div className='d-flex flex-row'>
            <div style={{ flex: 1 }}>
              <CheckboxField fieldProps={{ name: 'active', label: 'Active' }} />
              <CheckboxField fieldProps={{ name: 'archived', label: 'Archived' }} />
              {/* <CheckboxField fieldProps={{ name: 'orphaned', label: 'Orphaned' }} /> */}
            </div>
            <div style={{ flex: 1 }}>
              <CheckboxField fieldProps={{ name: 'teaching', label: 'Teaching' }} />
              <CheckboxField fieldProps={{ name: 'coaching', label: 'Coaching' }} />
              <CheckboxField fieldProps={{ name: 'consulting', label: 'Consulting' }} />
            </div>
          </div>
          <div className='d-flex flex-row border-top'>
          <div style={{ flex: 1 }}>
              <FormikTextField
                field={{
                  name: 'invMin',
                  label: 'Involvement Score',
                  placeholder: 'No min',
                }}
              />
            </div>
            <div className="pl-2 pr-2"> <span style={{position: 'relative', top: '25px'}}>-</span> </div>
            <div style={{ flex: 1 }}>
            <FormikTextField
                field={{
                  name: 'invMax',
                  label: '',
                  placeholder: 'No max',
                }}
              />
            </div>
          </div>
          <div className='d-flex flex-row border-top'>
          <div style={{ flex: 1 }}>
              <FormikSelectField
                field={{
                  name: 'organizationServeId',
                  label: 'Sector',
                }}
                multiple={true}
                options={ORGANIZATION_SERVE}
              />
            </div>
          </div>
        </div>
      </Popup>
    )
}