import { useContext, useMemo } from 'react'
import { ReactComponent as CalendarIcon } from '../assets/calendar.svg'
import { ReactComponent as BriefcaseIcon } from '../assets/briefcase.svg'
import { ReactComponent as HandshakeRegularIcon } from '../assets/handshake-regular.svg'
import { ReactComponent as BuildingIcon } from '../assets/building.svg'
import { ReactComponent as TagIcon } from '../assets/tag.svg'
import { ReactComponent as StarIcon } from '../assets/star.svg'
import { ReactComponent as GearIcon } from '../assets/gear.svg'
import { ReactComponent as ProspectsIcon } from '../assets/bullseye.svg'
import { ReactComponent as PersonIcon } from '../assets/person.svg'
import { ReactComponent as PeopleIcon } from '../assets/people.svg'
import { ReactComponent as NewspaperIcon } from '../assets/newspaper.svg'
import { ReactComponent as EaselIcon } from '../assets/easel.svg'
import { ReactComponent as EnvelopeIcon } from '../assets/envelope.svg'
import { ReactComponent as InboxesIcon } from '../assets/inboxes.svg'
import { ReactComponent as MailboxIcon } from '../assets/mailbox.svg'
import { ReactComponent as BellIcon } from '../assets/bell.svg'
import { ReactComponent as PersonX } from '../assets/person-x.svg'
import logo from '../assets/mi-logo-white.png'
import { CommunitySelector } from './community-selector'
import { AppStateContext, AppActionContext } from '../app-store-provider'
import { Link } from '@reach/router'
import { MainNavItem } from './main-nav-item'
import { Path } from './dashboard'
import { MainNavItemExpandable } from './main-nav-item-expandable'
import { ArchiveIcon, CalendarEventIcon, CallChatIcon, ClipboardCheckIcon, GraphUpArrowIcon, GraphUpIcon, HouseIcon, CurrencyDollarIcon, PiggyBankIcon, FileMedicalIcon, Mortorboard, GeoAltIcon } from '../assets'
import { GlobalSearch } from './global-search'
import { BRANCH_TYPES, BranchType } from '../constants'

export enum ManNavExpandable {
	'events' = 'events',
	'mail' = 'mail',
	'favorites' = 'favorites',
	'ministries' = 'ministries',
	'reports' = 'reports',
	'granting' = 'granting',
	'ministryContacts' = 'ministryContacts',
	'courses' = 'courses'
}

export const MainNav = () => {

	const appState = useContext(AppStateContext)!
	const appActions = useContext(AppActionContext)!

	const favorites = useMemo(() => {
		return {
			children: appState.currentUserSessionState?.favorites?.ministries?.map(ministry =>
				<Link to={`/ministry-info/${ministry.ministryId}`} key={ministry.ministryId} className='nav-row nav-row-link text-body pl-3 font-size-small'><BuildingIcon className='mr-1' style={{ minWidth: '16px' }} /> {ministry.ministryName}</Link>
			)
		}
	}, [appState.currentUserSessionState])

	return (
		<div id='main-nav'>
			<div>
				<div className='header-image'>
					<Link to='/'><img src={logo} alt='mission increase logo' /></Link>
				</div>

				<div className='nav-row m-2' style={{ justifyContent: 'center' }}>
					<GlobalSearch />
				</div>

				<div className='nav-row ml-2 mr-2 mb-2' style={{ justifyContent: 'center' }}>
					<CommunitySelector style={{ width: '100%' }} disabled={appState.globalCommunityContextDisabled} communities={appState.activeBranches.filter(x => x.branchTypeId === BranchType.Public)} onCommunitySelected={(community) => appActions.setGlobalCommunityContext(community)} selectedCommunity={appState.globalCommunityContext} />
				</div>

				<div style={{ overflowY: 'auto' }}>
					<MainNavItem icon={<HouseIcon />} title='Dashboard' path={Path.home} />

					<MainNavItemExpandable
						mainNavKey={ManNavExpandable.ministries}
						icon={<BuildingIcon />}
						title='Ministries'
						content={{
							items: [
								{ path: Path.ministries, icon: <BuildingIcon />, title: 'Active' },
								{ path: Path['archived-ministries'], icon: <ArchiveIcon />, title: 'Archived' },
								{ path: Path.prospects, icon: <ProspectsIcon />, title: 'Prospects' },
							]
						}}
					/>

					<MainNavItemExpandable
						mainNavKey={ManNavExpandable.ministryContacts}
						icon={<PeopleIcon />}
						title='Ministry Contacts'
						content={{
							items: [
								{ path: Path.contacts, icon: <PersonIcon />, title: 'Active' },
								{ path: Path['archived-contacts'], icon: <ArchiveIcon />, title: 'Archived' },
							]
						}}
					/>

					<MainNavItemExpandable
						mainNavKey={ManNavExpandable.events}
						icon={<CalendarIcon />}
						title='Events'
						content={{
							items: [
								// { path: Path.events, icon: <Signpost2Icon />, title: 'Event Management' },
								{ path: Path['event-management'], icon: <CalendarEventIcon />, title: 'Event Management' },
								// { path: Path['group-learning'], icon: <Signpost2Icon />, title: 'Group Learning' },
								{ path: Path.eventnews, icon: <NewspaperIcon />, title: 'Newsletter' },
								{ path: Path.presenters, icon: <EaselIcon />, title: 'Presenters' },
								{ path: Path['attendee-evals'], icon: <ClipboardCheckIcon />, title: 'Attendee Evals' },
								{ path: Path['trainer-evals'], icon: <ClipboardCheckIcon />, title: 'Trainer Evals' },
								{ path: Path['order-management'], icon: <CurrencyDollarIcon />, title: 'Orders' },
							]
						}}
					/>

					<MainNavItem icon={<BriefcaseIcon />} title='Coaching/Consulting' path={Path.consulting} />
					<MainNavItem icon={<CallChatIcon />} title='Call Lists' path={Path['call-lists']} />
					
					<MainNavItemExpandable
						mainNavKey={ManNavExpandable.courses}
						icon={<Mortorboard />}
						title='Courses'
						content={{
							items: [
								{ path: Path['course-enrollment'], icon: <Mortorboard />, title: 'Course Enrollment' },
								{ path: Path['manual-course-assignment'], icon: <Mortorboard />, title: 'Direct Enrollment' },
							]
						}}
					/>
					
					<MainNavItemExpandable
						mainNavKey={ManNavExpandable.mail}
						icon={<EnvelopeIcon />}
						title='Mail'
						content={{
							items: [
								{ path: Path["custom-blast"], icon: <MailboxIcon />, title: 'Custom Blast' },
								{ path: Path["mail-log"], icon: <InboxesIcon />, title: 'Mail Log' },
								{ path: Path['mail-notifications'], icon: <BellIcon />, title: 'Email Notifications' },
								{ path: Path['unsubscribe-list'], icon: <PersonX />, title: 'Unsubscribe List' },
							]
						}}
					/>
					<MainNavItemExpandable
						mainNavKey={ManNavExpandable.granting}
						icon={<PiggyBankIcon />}
						title='Granting'
						content={{
							items: [
								{ path: Path.grants, icon: <CurrencyDollarIcon />, title: 'Grants' },
								{ path: Path["grant-requests"], icon: <FileMedicalIcon />, title: 'Grant Requests' },
							]
						}}
					/>

					<MainNavItemExpandable
						mainNavKey={ManNavExpandable.reports}
						icon={<GraphUpArrowIcon />}
						title='Reports'
						content={{
							items: [
								{ path: Path["dashboard-report"], icon: <GraphUpIcon />, title: 'Dashboard' },
								{ path: Path["foundation-search"], icon: <GraphUpIcon />, title: 'Foundation Search' },
								{ path: Path["guide-star-search"], icon: <GraphUpIcon />, title: 'GuideStar Search' },
								{ path: Path["leverage-summary-report"], icon: <GraphUpIcon />, title: 'Leverage Summary' },
								{ path: Path["nonprofit-map"], icon: <GeoAltIcon />, title: 'Nonprofit Map' }
							]
						}}
					/>

					<MainNavItem icon={<HandshakeRegularIcon />} title='Givers' path={Path.givers} />
					<MainNavItem icon={<TagIcon />} title='Tags' path={Path.tags} />
					<MainNavItem icon={<PersonIcon />} title='Users' path={Path.users} />
					<MainNavItem icon={<PeopleIcon />} title='Communities' path={Path.communities} />

					<MainNavItemExpandable
						mainNavKey={ManNavExpandable.favorites}
						icon={<StarIcon />}
						title='Favorites'
						content={favorites}
					/>
				</div>

				<div style={{ flex: 1 }}></div>

				<div className={`primary-color-hover cursor-pointer p-2 ${appState.mainNav.selectedItem === Path.settings ? 'text-primary' : ''}`} onClick={() => appActions.mainNavItemPressed(Path.settings)}>
					<GearIcon style={{ marginBottom: '2px' }} />
				</div>
			</div>
		</div>
	)
}