import { Path } from '../components/dashboard'
import { ModalSize } from '../components/modal'
import { IBranchRegion, IBranchType } from '../models/community'
import { TagModel } from '../models/tag'
import { TrainingAreaModel } from '../models/training-area'
import { IContactEditOptionsModelDocument, IInvolvementMultipliersModelDocument, IMailTypeDocument, IMiAppsDocument, IMiBranchModelDocument, IMiBranchSummaryDocument, IMiTrainingAreasModelDocument, IMiUserModelDocument, IMiUserSummaryDocument, IMeAuthModelDocument, IPresentersDocument, IZCountrysDocument, IZNteeCodesDocument, IZStatesDocument } from '../open-api'
import { IGridUserInteractionState } from './grid-definitions'

export type StringDictionary<TValue> = { [key: string]: TValue }

export enum FetchActionType {
	init = 'init',
	success = 'success',
	failure = 'failure'
}

export interface IApiActionState<TValue> {
	isFetching?: boolean
	isError?: boolean
	value?: TValue
}

export interface IAppState {
	bootstrapped?: boolean

	currentUser?: IMiUserModelDocument
	currentUserSessionState?: IUserSessionState // This is split out from the user itself to facilitate ease of access and type enforcement

    currentAuth?: IMeAuthModelDocument

	trainingAreas?: TrainingAreaModel[]
	activeTrainingAreas?: TrainingAreaModel[]

	contactEditOptions?: IContactEditOptionsModelDocument

	presenters?: IPresentersDocument[]

	mainNav: {
		selectedItem: Path
	}

	/* 
		We store toasts in the global state. 
		
		There is a <Toasts /> component that lives at the top level of the app. 
		That component will display any toasts that are added to global state (and handle "dismissing" toasts).
	*/
	toasts?: IToast[]

	/* 
		Similarly to toasts, alerts are stored in global state and presented by an <Alerts /> container component at the top level of the app.
		The <Alerts /> component handles displaying and dismissing alerts from the global state.
	*/
	alerts?: IAlert[]

	/*
		globalCommunityContext is the branch/chapter/community that a user is scoped to
		Things like the Ministries table and Tags lists will be scoped down to items directly connected to this branch
		The intent is for the average user (e.g. an area director) to be able to use MI Admin in a scoped state 
		where they don't have to constantly filter through other community's info.
	*/
	globalCommunityContext?: IMiBranchModelDocument | null
	globalCommunityContextDisabled?: boolean

	/* 
		We store the global search term in app state to make it available to the GlobalSearchResults
		component at /search (which enables us to place a search input anywhere, but display the results elsewhere).	
	*/
	globalSearchTerm?: string

	/* 
		The tags stored at the App level are filtered by the selected community.
	*/
	tags?: TagModel[]

	/* 
		We store the list of all users here since it could be needed by the Call List form in the Ministry Management grid at the top level of the application.
	*/
	users?: IMiUserModelDocument[]

	/* 
		The endpoint for Users Management returns a different version of User data. 
		Like Tags, we store this data in the global app store (but not filtered by the selected community)
	*/
	userSummaries?: IMiUserSummaryDocument[]

	/* 
		The list of all active branches is stored in global app state and hydrated from cache during bootstrap
	*/
	activeBranches: IMiBranchModelDocument[]

	// /* 
	// 	The list of all branch types stored in global app state and hydrated from cache during bootstrap
	// */
	// branchTypes: IBranchType[]

	/* 
		The list of all branch regions stored in global app state and hydrated from cache during bootstrap
	*/
	branchRegions: IBranchRegion[]

	/* 
		The list of all country options stored in global app state and hydrated from cache during bootstrap
	*/
	countries: IZCountrysDocument[]
	
	/* 
		The involvement multiplier values stored in global app state and hydrated from cache during bootstrap
	*/
	involvementMultipliers: IInvolvementMultipliersModelDocument

	/* 
		The list of all community summaries. 
	*/
	communitySummaries?: IMiBranchSummaryDocument[]

	/* 
		The list of all NTEE Codes.
	*/
	nteeCodes: IZNteeCodesDocument[]

	/* 
		Server side list of all State selection options.
	*/
	states?: IZStatesDocument[]

	/* 
		Server side list of all permissions types
	*/
	appPermissions?: IMiAppsDocument[]

	/* 
		List of email notification types for each community.
	*/
	mailTypes?: IMailTypeDocument[]

}

// https://docs.microsoft.com/en-us/aspnet/core/web-api/jsonpatch?view=aspnetcore-3.1#json-patch
export interface IJsonPatch {
	op:
	'add' | 		// Add a property or array element. For existing property: set value.
	'remove' | 		// Remove a property or array element.
	'replace' | 	// Same as remove followed by add at same location.
	'move' | 		// Same as remove from source followed by add to destination using value from source.
	'copy' | 		// Same as add to destination using value from source.
	'test' 			// Return success status code if value at path = provided value.

	path: string
	value: string | boolean | null | number
}

export interface IUserSessionState {
	favorites?: {
		ministries?: {
			ministryId: number
			ministryName: string
		}[]
		prospects?: {
			prospectId: number
			prospectName: string
		}[]
	}

	/*
		Menu state
		We preserve the menu tree state so users who prefer certain menus to be open will always have those menus open when they return to the application.
	*/
	expandedSubMenus?: {
		[key: string]: boolean
	}

	/* 
		Any grid can register it's user interaction state here.
		This way, when a grid is visited by a user, if that user has used it before, their previous interaction state is loaded.
		This gives users the ability to automaticaly "save" their grid configurations.
	*/
	gridUserInteractionStates?: {
		[key: string]: IGridUserInteractionState | undefined | null
	}

	/*
		Persist a user's global community context dropdown selection.
	*/
	globalCommunityContextBranchId?: number | null

	/* 
		User application preferences
	*/
	preferences?: IUserSessionStatePreferences
}

export interface IUserSessionStatePreferences {
	slimTables?: boolean
}

export interface IToast {
	id: string
	title: string
	timestamp: Date
	body: string | JSX.Element
	autoDismissTimeOut?: number
	notDimissible?: boolean
	icon?: JSX.Element
}

export interface IAlert {
	id: string
	body: string | JSX.Element

	size?: ModalSize
	title?: string
	footer?: JSX.Element
}

export const initialAppState: IAppState = {
	mainNav: { selectedItem: Path.ministries },
	activeBranches: [],
	nteeCodes: [],
	//branchTypes: [],
	branchRegions: [],
	countries: [],
	involvementMultipliers: { consultingMultiplier: 0, grantPost2007Multiplier: 0, grantPre2007Multiplier: 0, groupLearningMultiplier: 0, roundtableMultiplier: 0, seminarMultiplier: 0, smallGroupMultiplier: 0, workshopMultiplier: 0, maConsultingMultiplier: 0, webinarMultiplier: 0 },
}
