import { Form, Formik } from "formik"
import moment from "moment"
import { useContext } from "react"
import { AppActionContext, AppStateContext } from "../app-store-provider"
import { sortListByProperty, uuidv4 } from "../services/helpers"
import { useHTTPRequestUiWrapper } from "../services/hooks"
import { IConsultingSessionFormProps } from "./consulting-appointment-form"
import { DatePickerField, ISelectFieldOption, SelectField, TextField, TinyMceField } from "./forms"
import * as Yup from 'yup'
import { ConsultingAppointmentFormDisabledText } from './consulting-appointment-form-disabled-text'
import dayjs from "dayjs"
import { FormikEffect } from "./formik-effect"
import { IConsultingApptRegistrantDocument } from "../open-api"
import { IMinistryRegistrantsSummaryProps, MinistryRegistrantsSummary } from './ministry-registrants-summary'
import { FormikTextAreaField } from './forms/formik-text-area-field'
import { FormikSelectField } from "./forms/formik-select-field"
import { AppointmentType, BRANCH_TIMEZONE_OPTIONS } from "../constants"
import { FormikTextField } from "./forms/formik-text-field"

const timeOptions: ISelectFieldOption[] = (() => {
    const options: ISelectFieldOption[] = []

    let startTime = dayjs().set('hour', 0).set('minute', 0).set('second', 0);
    const endTime = startTime.add(24, 'hour');

    let timeOut = 0 // Used to prevent infinite loops. Just to be safe.
    while(startTime < endTime && timeOut < 150){
        const timeStr = startTime.format('h:mm A')
        options.push({
            label: timeStr,
            value: timeStr,
        })

        startTime = startTime.add(15, 'minute');
        ++timeOut
    }
    return options
})()

interface IConulstingAppointmentFormDetails extends IConsultingSessionFormProps {
    onCancel?: () => void
    setActiveTab: (tabId: string) => void
    registrants: IConsultingApptRegistrantDocument[]
}

export const ConulstingAppointmentFormDetails = ({
    appointmentToEdit,
    areaDirectors,
    meetingTypes,
    appointmentTypes,
    consultingTopics,
    maConsultingTopics,
    trainingTopics,
    onCancel,
    setAppointmentToEdit,
    afterSave,
    setActiveTab,
    registrants,
}: IConulstingAppointmentFormDetails) => {

    const appActions = useContext(AppActionContext)!
    const { globalCommunityContext } = useContext(AppStateContext)!

    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    const initialValues = {
        presenterId: appointmentToEdit?.presenterId?.toString() || '',
        branchId: 0, // Leave this as zero. We'll update it server side.
        date: appointmentToEdit?.startDateTime || '',
        startTime: (appointmentToEdit?.startDateTime && moment(appointmentToEdit.startDateTime).format('h:mm A')) || '',
        endTime: (appointmentToEdit?.endDateTime && moment(appointmentToEdit.endDateTime).format('h:mm A')) || '',
        meetingTypeId: appointmentToEdit?.meetingTypeId?.toString() || '',
        appointmentTypeId: appointmentToEdit?.appointmentTypeId?.toString() || '',
        consultingTopicsIds: appointmentToEdit?.consultingTopicsIds?.map(o => o.toString()) || [],
        maConsultingTopicsIds: appointmentToEdit?.maConsultingTopicsIds?.map(o => o.toString()) || [],
        strategicCategoryIds: appointmentToEdit?.strategicCategoryIds?.map(o => o.toString()) || [],
        coachingCategoryOther: appointmentToEdit?.coachingCategoryOther || '',
        strategicConsultingCategoryOther: appointmentToEdit?.strategicConsultingCategoryOther || '',
        maConsultingCategoryOther: appointmentToEdit?.maConsultingCategoryOther || '',
        noteFromMinistry: appointmentToEdit?.noteFromMinistry || '',
        eventDetails: appointmentToEdit?.eventDetails || '',
        timeZone: appointmentToEdit?.timeZone || '',
        webinarUrl: appointmentToEdit?.webinarUrl || ''
    }

    const readOnly = appointmentToEdit && !appointmentToEdit.isEditable

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={Yup.object({
                presenterId: Yup.string().required('Required'),
                date: Yup.string().required('Required'),
                timeZone: Yup.string().required('Required'),
                startTime: Yup.string().required('Required')
                    .test(
                        'startTime',
                        'Start time must be before the end time.',
                        function (value) {
                            if (this.parent.endTime) {
                                return dayjs(value, 'h:mm A').isBefore(dayjs(this.parent.endTime, 'h:mm A'))
                            } else {
                                return true
                            }
                        }),
                endTime: Yup.string().required('Required')
                    .test(
                        'endTime',
                        'End time must be after the start time.',
                        function (value) {
                            if (this.parent.startTime) {
                                return dayjs(value, 'h:mm A').isAfter(dayjs(this.parent.startTime, 'h:mm A'))
                            } else {
                                return true
                            }
                        }),
                meetingTypeId: Yup.string().required('Required'),
                appointmentTypeId: Yup.string().required('Required'),
                eventDetails: Yup.string().max(800, 'Cannot be more than 800 characters.'),
            })}
            onSubmit={async (values) => {
                const valuesToSave = {
                    ...values,
                    presenterId: parseInt(values.presenterId),
                    branchId: values.branchId,
                    meetingTypeId: parseInt(values.meetingTypeId),
                    appointmentTypeId: parseInt(values.appointmentTypeId),
                    strategicCategoryIds: values.strategicCategoryIds?.map(o => parseInt(o)) || [],
                    consultingTopicsIds: values.consultingTopicsIds?.map(o => parseInt(o)) || [],
                    maConsultingTopicsIds: values.maConsultingTopicsIds?.map(o => parseInt(o)) || [],
                    startDateTime: moment(`${moment(values.date).format('MM/DD/YYYY')} ${values.startTime}`).format('YYYY-MM-DDTHH:mm:00'),
                    endDateTime: moment(`${moment(values.date).format('MM/DD/YYYY')} ${values.endTime}`).format('YYYY-MM-DDTHH:mm:00'),
                    timeZone: values.timeZone,
                }

                if (appointmentToEdit) {
                    await makeHttpRequestWithUi({
                        request: appActions.ConsultingApi.apiConsultingConsultingEventGroupIdPut(appointmentToEdit.consultingEventGroupId,
                            {
                                ...appointmentToEdit,
                                ...valuesToSave
                            }
                        ),
                        toastSuccessMessage: 'Successfully updated consulting appointment.',
                        toastErrorMessage: 'There was an error updating the consulting appointment.'
                    })
                } else {
                    const createConsultingAppointmentQuery = await makeHttpRequestWithUi({
                        request: appActions.ConsultingApi.apiConsultingPost(valuesToSave),
                        toastSuccessMessage: 'Successfully created appointment.',
                        toastErrorMessage: 'There was an error creating the consulting appointment.',
                    })

                    setAppointmentToEdit(createConsultingAppointmentQuery.data)

                    appActions.addAlert({
                        id: uuidv4(),
                        title: "Don't forget!",
                        body: "Do you need to delete any corresponding 'Consulting Available' slots on your Outlook Calendar?",
                    })

                    setActiveTab('registrants')
                }

                afterSave && afterSave()
            }}
            children={formikProps => {
                const { values } = formikProps
                
                return (
                    <Form style={{ paddingTop: 10, }}>
                        {readOnly && <ConsultingAppointmentFormDisabledText />}

                        <FormikEffect
                            formikProps={formikProps}
                            onChange={(prevValues, nextValues) => {
                                if (nextValues.startTime !== prevValues.startTime) {
                                    formikProps.setFieldValue('endTime', dayjs(nextValues.startTime, 'h:mm A').add(1, 'hour').format('h:mm A'))
                                }
                                if (nextValues.presenterId !== prevValues.presenterId) {
                                    // Presenter will only change when appointment is created. We'll default the branchId to the presenter's (AD's),
                                    // but we'll update it to the registrants on the back end once they are added.
                                    const areaDirector = areaDirectors.find(ad => ad.presenterId.toString() === values.presenterId.toString())
                                    formikProps.setFieldValue('branchId', areaDirector?.branchId || 0)
                                    formikProps.setFieldValue('timeZone',areaDirector?.timezone || '')
                                }
                            }}
                        />

                        <div style={{ padding: '10px 10px 0px 10px', border: '1px solid #dee2e6', borderRadius: 10, marginBottom: 10 }}>
                            <div style={{ display: 'flex' }}>
                                <div style={{ flex: 1, marginRight: 5 }}>
                                    <SelectField
                                        fieldProps={{ name: 'presenterId', label: 'Area Director', placeholder: 'Select an area director...' }}
                                        options={sortListByProperty(areaDirectors, 'firstName').map(o => ({ value: `${o.presenterId}`, label: `${o.firstName || ''} ${o.lastName || ''}` }))}
                                        disabled={!!appointmentToEdit || readOnly}
                                    />
                                </div>
                                <div style={{ flex: 2, marginLeft: 30, marginBottom: 10, display: registrants.length > 0 ? 'initial' : 'none' }}>
                                    Registrants
                                    {/* <div style={{ display: 'flex', alignItems: 'center' }}>
                                        Registrants<PencilIcon className='primary-color-hover' style={{ cursor: 'pointer', marginLeft: 5 }} onClick={() => setActiveTab('registrants')} />
                                    </div> */}
                                    <RegistrantsSummary registrants={registrants} setActiveTab={setActiveTab} />
                                </div>
                            </div>
                        </div>

                        {/* 20220617 TB - Don't allow user to edit date/time values. There are biz rules behind the scenes that require the appointment to be
                            "deleted" and recreated from scratch to work (e.g., managing the events in Outlook).
                         */}
                        <div style={{ padding: '10px 10px 0px 10px', border: '1px solid #dee2e6', borderRadius: 10, marginBottom: 10 }}>
                            <div style={{ display: 'flex' }}>
                                <div style={{ flex: 1, marginRight: 10 }}>
                                    <DatePickerField disabled={!!appointmentToEdit || readOnly} fieldProps={{ name: 'date', label: 'Appointment Date', placeholder: 'Select a date...' }} />
                                </div>
                                <div style={{ flex: 1, marginRight: 10 }}>
                                    <FormikSelectField  field={{ name: 'timeZone', label: 'Time Zone', disabled: (!!appointmentToEdit || readOnly) }} options={BRANCH_TIMEZONE_OPTIONS} />
                                </div>
                                <div style={{ flex: 1, marginRight: 10 }}>
                                    <SelectField
                                        fieldProps={{ name: 'startTime', label: 'Start Time'}}
                                        options={timeOptions}
                                        disabled={!!appointmentToEdit || readOnly}
                                    />
                                </div>
                                <div style={{ flex: 1 }}>
                                    <SelectField
                                        fieldProps={{ name: 'endTime', label: 'End Time' }}
                                        options={timeOptions}
                                        disabled={!!appointmentToEdit || readOnly}
                                    />
                                </div>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <div style={{ flex: 1, fontSize: '11pt', marginBottom: '5px'}}>
                                    *To change the date/time you must delete this appointment and create a new one. 
                                </div>
                            </div>
                        </div>
                        <div style={{ padding: '10px 10px 0px 10px', border: '1px solid #dee2e6', borderRadius: 10, marginBottom: 10 }}>
                            <h6>Settings</h6>
                            <div style={{ display: 'flex' }}>
                                <div style={{ flex: 1, marginRight: 5 }}>
                                    <FormikSelectField
                                        field={{ name: 'meetingTypeId', label: 'Meeting Type', placeholder: 'Select a meeting type...', disabled: readOnly }}
                                        options={meetingTypes?.map(o => ({ label: `${o.name}`, value: `${o.id}`, isDisabled: o.name === "Conference Call" }))}
                                    />
                                </div>
                                <div style={{ flex: 1, marginLeft: 5 }}>
                                    {/* See https://missionincrease.atlassian.net/browse/MA20-2165 */}
                                    {values.appointmentTypeId === '4' ?
                                        <>
                                            <b>Appointment Type</b>
                                            <div>Book Club</div>
                                        </>
                                        :
                                        <SelectField
                                            fieldProps={{ name: 'appointmentTypeId', label: 'Appointment Type' }}
                                            options={appointmentTypes?.map(o => ({ label: `${o.name}`, value: `${o.id}` }))}
                                            disabled={readOnly}
                                        />
                                    }

                                </div>
                            </div>
                            {/*
                            20241119 TB - Began to add this field, but realized it will require some more work to get working. Leaving code here for when we have more time. 
                            <div style={{ display: 'flex' }}>
                                <div style={{ flex: 1, marginRight: 5 }}>
                                    <FormikTextField field={{ name: 'webinarUrl', label: 'Virtual Meeting URL', disabled: readOnly }} labelTooltip="Zoom/Teams URL that attendees with will use to join your event remotely. This URL will only be included in the 'Add this event to your calendar' as the location. It is not used anywhere else. We also recommend including the link in your Private Event Details." />
                                </div>
                            </div> */}
                            <div style={{ display: 'flex' }}>
                                <div style={{ flex: 1, marginRight: 5 }}>
                                    {values.appointmentTypeId === AppointmentType.Coaching.toString() &&
                                        <SelectField
                                            fieldProps={{ name: 'consultingTopicsIds', label: 'Coaching Topics' }}
                                            options={consultingTopics?.map(o => ({ label: `${o.name}`, value: `${o.id}` }))}
                                            multiple
                                            menuPlacement='top'
                                            disabled={readOnly}
                                        />
                                    }

                                    {values.appointmentTypeId === AppointmentType.Consulting.toString() &&
                                        <SelectField
                                            fieldProps={{ name: 'strategicCategoryIds', label: 'Consulting Topics' }}
                                            options={trainingTopics?.map(o => ({ label: `${o.name}`, value: `${o.id}` }))}
                                            multiple
                                            menuPlacement='top'
                                            disabled={readOnly}
                                        />
                                    }

                                    {values.appointmentTypeId === AppointmentType.MaConsulting.toString() &&
                                        <SelectField
                                            fieldProps={{ name: 'maConsultingTopicsIds', label: 'MA Consulting Topics' }}
                                            options={maConsultingTopics?.map(o => ({ label: `${o.name}`, value: `${o.id}` }))}
                                            multiple
                                            menuPlacement='top'
                                            disabled={readOnly}
                                        />
                                    }
                                </div>
                                
                                {/* Show 'Other' field if the 'other' option is selected */}
                                {values.appointmentTypeId === AppointmentType.Coaching.toString() && values.consultingTopicsIds && values.consultingTopicsIds.includes('25') &&
                                    <div style={{ flex: 1, marginLeft: 5 }}>
                                        <TextField fieldProps={{ name: 'coachingCategoryOther', label: 'Other' }} disabled={readOnly} />
                                    </div>
                                }
                                {values.appointmentTypeId === AppointmentType.Consulting.toString() && values.strategicCategoryIds && values.strategicCategoryIds.includes('7') &&
                                    <div style={{ flex: 1, marginLeft: 5 }}>
                                        <TextField fieldProps={{ name: 'strategicConsultingCategoryOther', label: 'Other' }} disabled={readOnly} />
                                    </div>
                                }
                                {values.appointmentTypeId === AppointmentType.MaConsulting.toString() && values.maConsultingTopicsIds && values.maConsultingTopicsIds.includes('10') &&
                                    <div style={{ flex: 1, marginLeft: 5 }}>
                                        <TextField fieldProps={{ name: 'maConsultingCategoryOther', label: 'Other' }} disabled={readOnly} />
                                    </div>
                                }
                            </div>
                            {/* 20240626 TB - I believe 
                             {values.meetingTypeId === '2' &&
                                <div style={{ marginBottom: 10 }}>
                                    <h6>Conference Info</h6>
                                    {areaDirector && areaDirector?.conferenceNumber ?
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>Dial in</td>
                                                    <td>{areaDirector?.conferenceNumber}</td>
                                                </tr>
                                                <tr>
                                                    <td>Presenter pin</td>
                                                    <td>{areaDirector?.presenterPin}</td>
                                                </tr>
                                                <tr>
                                                    <td>Participant pin</td>
                                                    <td>{areaDirector?.participantPin}</td>
                                                </tr>
                                                <tr>
                                                    <td>Presenter ID</td>
                                                    <td>{areaDirector?.presenterId}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        :
                                        <div>No conference info for this area director.</div>
                                    }
                                </div>
                            } */}
                        </div>
                        <div style={{ padding: '10px 10px 0px 10px', border: '1px solid #dee2e6', borderRadius: 10, marginBottom: 10 }}>
                            <h6>Notes</h6>
                            <div style={{ display: 'flex' }}>
                                <div style={{ flex: 1, }}>
                                    <TinyMceField
                                        fieldProps={{
                                            name: 'eventDetails',
                                            label: 'Notes to Ministry',
                                            labeltooltip: 'Notes entered by the Area Director. These notes will appear in the reminder email.'
                                        }}
                                        disabled={readOnly}
                                    />
                                </div>
                                {appointmentToEdit &&
                                    <div style={{ flex: 1, marginLeft: 10 }}>
                                        <FormikTextAreaField field={{ name: 'noteFromMinistry', label: 'Notes from Ministry', disabled: true }} />
                                    </div>
                                }
                            </div>
                        </div>

                        <div style={{ display: 'flex' }}>
                            {onCancel && <button style={{ width: 100 }} type='button' onClick={onCancel} className='btn btn-secondary'>Cancel</button>}
                            <button className='btn btn-primary' disabled={readOnly} style={{ width: 100, marginLeft: 10 }}>Save</button>
                        </div>
                    </Form>
                )
            }}
        />
    )
}

const RegistrantsSummary = ({ registrants }: { registrants: IConsultingApptRegistrantDocument[], setActiveTab: (tabId: string) => void }) => {
    const registrantsByMinistry = registrants.reduce<IMinistryRegistrantsSummaryProps[]>((collection, registrant) => {
        const existingMinistry = collection.find(o => o.ministryId === registrant.ministryId)
        if (!existingMinistry) {
            collection.push({
                ministryName: registrant.ministryName || '',
                ministryId: registrant.ministryId,
                registrants: [{
                    id: registrant.ministryContactId?.toString() || '',
                    firstName: registrant.firstName,
                    lastName: registrant.lastName,
                    email: registrant.email,
                    businessPhone: registrant.businessPhone,
                }]
            })
        } else {
            existingMinistry.registrants.push({
                id: registrant.ministryContactId?.toString() || '',
                firstName: registrant.firstName,
                lastName: registrant.lastName,
                email: registrant.email,
                businessPhone: registrant.businessPhone,
            })
        }
        return collection
    }, [])

    return (
        <div style={{ backgroundColor: 'rgb(248, 249, 250)', padding: '5px', marginTop: '1px' }}>
            {registrantsByMinistry.map(o => <MinistryRegistrantsSummary {...o} key={o.ministryId} />)}
        </div>
    )
}

